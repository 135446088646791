import * as React from 'react';
import { AppBar, Box, Toolbar, Typography, CardMedia, Stack, LinearProgress } from '@mui/material';

import { Tabs, Tab, Divider } from '@mui/material';
import { Menu, MenuItem, List, ListItemIcon, ListItemText } from '@mui/material';
import { IconButton, Button, Drawer } from '@mui/material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, Link, useHistory } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AppContext from '../AppContext';
import { AuthRequiredComponent } from './Auth'
import { logout as sapiLogout } from '../api/ServerAPI';
import { logout as gameLogout } from '../api/KnuctGameServerAPI';

import { Geolocation } from '@capacitor/geolocation';
import { Device } from '@capacitor/device';
import { telemetry } from '../api/ServerAPI';
import io from 'socket.io-client';
import AnswerCall from "../pages/AnswerCall";
import OnlineOfflineStatus from './OnlineOfflineStatus';
import P2PReceivedFiles from './P2PReceivedFiles';

import { mypeer } from '../utils/peer';
import { socket } from '../utils/socket';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CallIcon from '@mui/icons-material/Call';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AppsIcon from '@mui/icons-material/Apps';

import { ReactComponent as KnuctLogo } from '../knuctlogo.svg'
import { ReactComponent as KunctIcon } from '../knuctIcon.svg'

const menus = [
    // {l:'label',t:'to',i:<icon/>}
    { l: 'Dashboard', t: '/dashboard', i: <DashboardOutlinedIcon /> },
    { l: 'Transaction', t: '/transaction', i: <CompareArrowsIcon /> },
    { l: 'Contacts', t: '/contacts', i: <ContactPageOutlinedIcon /> },
    { l: 'Chat & Call', t: '/KnuctTalk', i: <ChatOutlinedIcon /> },
]

const drawerMenus = [
    { l: 'Terms & conditions', t: '/', i: <ArticleOutlinedIcon /> },
    { l: 'Help', t: '/', i: <HelpOutlineOutlinedIcon /> },
    { l: 'About', t: '/', i: <InfoOutlinedIcon /> },
    { divider: true },
    { l: 'Contact', t: '/contacts', i: <ContactPageIcon /> },
    { l: 'Chat & Call', t: '/KnuctTalk', i: <ChatOutlinedIcon /> },
    { divider: true },
    { l: 'Stock App', t: '/stockapp', i: <AppsIcon /> },
    { divider: true },
    { l: 'Transaction History', t: '/transaction/history', i: <HistoryIcon /> },
    { l: 'Tokens', t: '/transaction/tokens', i: <TokenOutlinedIcon /> },
]

const socketTelemetry = io(process.env.REACT_APP_TELEMETRY_URL, {
    extraHeaders: {
        Authorization: `Basic ${process.env.REACT_APP_TELEMETRY_AUTH}`
    }
});

export default function NavBar({ type = 'menu', ...rProps }) {
    const theme = useTheme()
    const history = useHistory()
    const [appState, appStateDispatch] = React.useContext(AppContext)
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorSettingsMenu, setAnchorSettingsMenu] = React.useState(null);

    const [locationData, setLocationData] = React.useState({})
    const [deviceId, setDeviceId] = React.useState("")
    const [deviceInfo, setDeviceInfo] = React.useState("")

    React.useEffect(() => {
        Device.getInfo().then(response => {
            // console.log("deviceInfo:", response)
            setDeviceInfo(response)
        }).catch(error => console.log("getDeviceInfoError:", error))

        Device.getId().then(response => {
            // console.log("deviceId:", response)
            setDeviceId({ uuid: response?.identifier })
        }).catch(error => console.log("getDeviceIDError:", error))
    }, [])

    React.useEffect(() => {
        if (appState?.did && socket && socketTelemetry && deviceId && deviceInfo) {
            console.log("going to watch location...")
            Geolocation.watchPosition({ enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }, (response, error) => {
                if (!error && response) {
                    // console.log("coordinates: ", response)
                    const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = response.coords

                    const data = {
                        ...(appState.did && { did: appState.did }),
                        ...(mypeer?._id && { peerId: mypeer?._id }),
                        accuracy: accuracy,
                        altitude: altitude,
                        altitudeAccuracy: altitudeAccuracy,
                        heading: heading,
                        latitude: latitude,
                        longitude: longitude,
                        speed: speed,
                        timestamp: new Date(response.timestamp),
                        ...(deviceId && { deviceId: deviceId }),
                        ...(deviceInfo && { deviceInfo: deviceInfo })
                    }

                    setLocationData(ps => ({ ...ps, ...data }))
                    // console.log("liveLocation:", data)
                    socketTelemetry.emit("liveLocation", data)
                    socket.emit("liveLocation", data)
                }
            })
        }

    }, [appState.did, deviceId, deviceInfo])

    React.useEffect(() => {
        if (appState.did) {
            telemetry({ data: locationData })
                // .then(res => console.log(res))
                .catch(err => console.log(err))
        } else {
            console.log("UNAUTHORIZED TO SEND TELEMETRY!")
        }
    }, [locationData, appState.did])

    const settingsMenuClick = (e) => {
        setAnchorSettingsMenu(e.currentTarget);
    };

    const closeSettingsMenu = () => {
        setAnchorSettingsMenu(null);
    };

    const toggleThemeClick = () => {
        appStateDispatch({ type: 'toggleTheme' })
        closeSettingsMenu()
    }

    const logoutBtnClick = () => {
        gameLogout()
            .then(() => { })
            .catch((err) => {
                console.log('Game logout error', err)
            })
        sapiLogout()
            .then(() => { })
            .catch((err) => {
                console.log('logout error', err)
            })
        appStateDispatch({ type: 'logout' })
        history.push('/')
    }

    return (
        <Box className='navbar-wrapper'>
            <AppBar position="static" color='inherit' className="navbar shadow" sx={{ bgcolor: 'background.default', backgroundImage: 'none' }}>
                <Toolbar>
                    {/* spacer */}
                    <Box sx={{ flexGrow: { xs: 1, sm: 0 } }} />

                    {/* title */}

                    {/* <Typography variant="h5" color="inherit" component="div" sx={{flexShrink:0}}>
                        <KnuctLogo style={{width: '1.5em', height:'1.5em',verticalAlign:'bottom', marginRight:'0.3em'}}/>
                        {process.env.REACT_APP_NAME} Wallet
                    </Typography> */}
                    <MainLogo />

                    {/* spacer */}
                    <Box sx={{ flexGrow: { sx: 0, sm: 0.6 } }} />

                    {/* Navigation menu */}
                    {type === 'menu' && !smScreen && <TopNavMenu />}

                    {/* spacer */}
                    <Box sx={{ flexGrow: 1 }} />

                    <OnlineOfflineStatus />
                    <AuthRequiredComponent>
                        <Button size='small' onClick={logoutBtnClick}
                            sx={{ color: 'text.secondary', mx: { xs: 0, md: 1 }, textTransform: 'none', borderRadius: 5 }}>
                            Logout
                        </Button>
                        <AnswerCall />
                        <P2PReceivedFiles />
                    </AuthRequiredComponent>
                    <IconButton sx={{ display: 'none' }}>
                        <SmsOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={settingsMenuClick} sx={{ display: 'none' }}>
                        <NotificationsOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={settingsMenuClick}>
                        <SettingsIcon />
                    </IconButton>
                    <SettingsMenu anchor={anchorSettingsMenu} handleClose={closeSettingsMenu} toggleTheme={toggleThemeClick} />


                </Toolbar>
            </AppBar>
            {type === 'menu' && smScreen && <BottomNavMenu />}
        </Box>
    );
}

const logoAnimationStyle = {
    background: "linear-gradient(-45deg, #00F5FF, #FCE700, #FF6D28, #EA047E)",
    backgroundSize: "200% 200%",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    animation: "gradient 30s ease infinite",
    "@keyframes gradient": {
        "0%": {
            backgroundPosition: "0% 50%",
        },
        "50%": {
            backgroundPosition: "100% 50%"
        },
        "100%": {
            backgroundPosition: "0% 50%"
        }
    }
}

function MainLogo() {
    // console.log("LOGO-LOADED!")

    return (
        <Stack>
            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} color={"inherit"} sx={{ textDecoration: "none" }}
                component={Link} to="/"
            >
                <CardMedia sx={{ mr: 1 }}>
                    <KunctIcon style={{ width: '2em', height: '2em', verticalAlign: 'center' }} />
                </CardMedia>
                <Stack spacing={-1} justifyContent={"center"} alignItems={"flex-start"} direction={"column"}>
                    <Typography variant='caption' fontFamily={"JetBrains Mono"} sx={{ fontWeight: "bold" }}>KNUCT</Typography>
                    <Typography variant='subtitle1' fontFamily={"JetBrains Mono"} sx={{ fontWeight: "bold" }}>WEB-WALLET</Typography>
                </Stack>
            </Stack>
            <LinearProgress sx={{ ...logoAnimationStyle, borderRadius: 2, height: 1.5 }} variant="determinate" value={-1} />
        </Stack>
    )
}

function TopNavMenu() {
    const location = useLocation()

    return (
        <NavTabs value={getPageIfInMenu(location.pathname)} variant="scrollable"
            aria-label="nav tabs example"
        >
            {
                menus.map((item, index) => {
                    let v = getPageFormLoc(item.t);
                    return (
                        <Tab key={index} component={Link} label={item.l} to={item.t} value={v} />
                    );
                })
            }
        </NavTabs>
    );
}

function BottomNavMenu() {
    const location = useLocation()
    const [menu, setMenu] = React.useState(false)

    return (
        <>
            <BottomNavigation value={getPageIfInMenu(location.pathname)}
                sx={{
                    position: 'fixed', bottom: 0, left: 0, right: 0,
                    zIndex: 'snackbar', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px'
                }}
            >
                <BottomNavigationAction icon={(menu ? <CloseIcon /> : <MenuIcon />)} label={'menu'}
                    value={'menu'} onClick={() => { setMenu(!menu) }}
                />
                {
                    menus
                        .filter(x => !["Chat & Call"].includes(x.l) && x)
                        .map((item, index) => {
                            let v = getPageFormLoc(item.t);
                            return (
                                <BottomNavigationAction key={index} component={Link}
                                    label={item.l} to={item.t} value={v}
                                    icon={item.i}
                                />
                            );
                        })
                }
            </BottomNavigation>
            <DrawerMenu open={menu} onClose={() => { setMenu(false) }} />
        </>
    );
}

function SettingsMenu({ anchor, handleClose, toggleTheme }) {
    const theme = useTheme()
    const isMenuOpen = Boolean(anchor);
    return (
        <Menu id="settings-menu"
            anchorEl={anchor} open={isMenuOpen} onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            // extra props for showing arrow
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={toggleTheme}>
                <ListItemIcon>
                    {({ 'dark': <LightModeOutlinedIcon fontSize='small' />, 'light': <DarkModeOutlinedIcon fontSize="small" /> }[theme.palette.mode])}
                </ListItemIcon>
                <ListItemText>{({ 'dark': 'light', 'light': 'dark' }[theme.palette.mode])} mode</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <ListItemIcon>
                    <InfoOutlinedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{process.env.REACT_APP_VERSION}</ListItemText>
            </MenuItem>
        </Menu>
    )
}

function DrawerMenu({ open, onClose }) {
    return (
        <Drawer anchor='left' open={open} onClose={onClose} sx={{ display: 'flex' }}>
            <Typography sx={{ py: 2, pl: 2 }}></Typography>
            <div style={{ flexGrow: 1 }}></div>
            <List>
                {
                    drawerMenus.map((item, index) => {
                        if ('divider' in item)
                            return (<Divider key={index} />)
                        return (
                            <MenuItem key={index} onClick={onClose}
                                component={Link} to={item.t}
                                sx={{ pl: 3 }}
                            >
                                <ListItemIcon>{item.i}</ListItemIcon>
                                <ListItemText sx={{ pr: 5 }}>{item.l}</ListItemText>
                            </MenuItem>
                        )
                    })
                }
            </List>
            <div style={{ flexBasis: 60 }}></div>
        </Drawer>
    )
}

// util functions -------------------------

const getPageFormLoc = (loc) => {
    return loc.split('/')[1]
}

const getPageIfInMenu = (loc) => {
    let page = getPageFormLoc(loc)
    // check if page in menus
    let isPageInMenu = menus.reduce((acum, item) => {
        return acum || getPageFormLoc(item.t) == page
    }, false);
    return isPageInMenu ? page : false
}

// ---------------------- styles -------------------------------------

/* custom style for top nav bar */
const NavTabs = styled(Tabs)({
    alignSelf: 'end',
    minHeight: '40px',
    '& .MuiTabs-scroller': {
        display: 'inline-flex'
    },
    '& .MuiTab-root': {
        fontSize: '0.8125rem',
        flexDirection: 'row',
        minHeight: 'auto',
        padding: '12px 12px',
        // textTransform: 'capitalize',
        '& .MuiSvgIcon-root': {
            marginBottom: '0px'
        }
    }
});