import React, { useEffect, useState, useContext } from "react";
import { Box, Chip, CardMedia, Stack, CardActions } from "@mui/material";

import L, { popup } from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet/dist/leaflet.css";

import moment from "moment-timezone";

import AppContext from '../AppContext';

const markerIcon = "pos_anonymous.svg";
const phoneIcon = "mobile-phone-svgrepo-com.svg";
const laptopIcon = "laptop-computer-svgrepo-com.svg"

const beepSound10m = "buzzer10m.wav"; // Import the beep sound file
const beepSound100m = "buzzer100m.wav";
const beepSound500m = "buzzer500m.wav";

export default function MapDistance({ id = "DistanceMapDefault", maxHeight, startLocation = [78.9629, 20.5937], data, setOpen = null }) {
    const [appState, appStateDispatch] = useContext(AppContext)
    const [enableBuzzer, setEnableBuzzer] = useState(true)
    const [map, setMap] = useState(null);
    const [routingControl, setRoutingControl] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [currentTime, setCurrentTime] = useState(moment());
    const [circle, setCircle] = useState(null);
    const [playBeep10m, setPlayBeep10m] = useState(false); // State to control beep sound
    const [playBeep100m, setPlayBeep100m] = useState(false); // State to control beep sound
    const [playBeep500m, setPlayBeep500m] = useState(false); // State to control beep sound
    const [distance, setDistance] = useState(0); // State to hold the calculated distance
    const [myLocation, setMyLocation] = useState({});
    const did = appState?.did

    useEffect(() => {
        if (id) {
            const newMap = L.map(id).setView([28.6139, 77.209], 5); // Zoom level to show entire India

            const newTileLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(newMap);

            setMap(newMap);

            const mapContainer = document.getElementById(id);
            if (mapContainer) {
                mapContainer.style.color = "black";
                mapContainer.style.fontFamily = "Roboto"
                mapContainer.style.fontWeight = "bold"
            }

            return () => {
                newTileLayer.remove()
                newMap.remove()
            };
        }
    }, [id]);

    useEffect(() => {
        const updateDayNight = () => {
            const hour = currentTime.hour();
            const isDay = hour >= 6 && hour <= 18;
            const mapContainer = document.getElementById(id);
            if (mapContainer) {
                mapContainer.style.background = isDay ? "#f2f2f2" : "#333";
            }
        };

        updateDayNight();
        const timer = setInterval(updateDayNight, 60000); // Update day/night every minute

        return () => clearInterval(timer);
    }, [currentTime, id]);

    useEffect(() => {
        if (map && Object.keys(data).length >= 2 && did) {
            try {
                // const { [did]: start, ...end } = data
                const start = data?.[did];
                const otherDid = Object.keys(data).filter(key => key !== did && key)[0]
                const end = data?.[otherDid];

                // console.log("start:", start, "end:", end)

                var startPopupDesc, endPopupDesc, bounds, startBound, endBound;

                // Create LatLng bounds from start and end points

                const waypoints = [
                    L.latLng(start.latitude, start.longitude),
                    L.latLng(end.latitude, end.longitude),
                ];

                if (!routingControl) {
                    var popupMarkers = [];
                    const newRoutingControl = L.Routing.control({
                        waypoints,
                        routeWhileDragging: true,
                        lineOptions: {
                            styles: [{ color: "#007bff", weight: 5 }],
                        },
                        createMarker: function (i, waypoint, n) {
                            var customMarkerIcon = markerIcon;
                            var startIcon = phoneIcon;
                            var endIcon = laptopIcon;

                            const MOBILES = ['android', 'ios', 'osx']

                            if (MOBILES.includes(start?.deviceInfo?.operatingSystem)) {
                                startIcon = phoneIcon;
                            } else {
                                startIcon = laptopIcon;
                            }

                            if (MOBILES.includes(end?.deviceInfo?.operatingSystem)) {
                                startIcon = phoneIcon;
                            } else {
                                startIcon = laptopIcon;
                            }

                            var routeMarker

                            if (i === 0) {
                                // Start marker
                                customMarkerIcon = L.icon({
                                    iconUrl: startIcon,
                                    iconSize: [32, 32],
                                    iconAnchor: [16, 32],
                                    popupAnchor: [0, -32],
                                });
                                routeMarker = L.marker(waypoint.latLng, {
                                    icon: customMarkerIcon,
                                    draggable: true,
                                })
                                    .bindPopup(startPopupDesc)
                                    .openPopup();
                                popupMarkers.push(routeMarker);
                            } else if (i === n - 1) {
                                // End marker
                                customMarkerIcon = L.icon({
                                    iconUrl: endIcon,
                                    iconSize: [32, 32],
                                    iconAnchor: [16, 32],
                                    popupAnchor: [0, -32],
                                });
                                routeMarker = L.marker(waypoint.latLng, {
                                    icon: customMarkerIcon,
                                    draggable: true,
                                })
                                    .bindPopup(endPopupDesc)
                                    .openPopup();
                                popupMarkers.push(routeMarker);
                            }

                            return routeMarker;
                        },
                    }).addTo(map);

                    newRoutingControl.on("routeselected", function (e) {
                        var routes = e.route; // Access the route object
                        var waypoints = routes.waypoints; // Access the waypoints of the route
                        // console.log(waypoints[0].latLng);
                        startBound = L.latLng(
                            waypoints[0].latLng.lat,
                            waypoints[0].latLng.lng
                        );
                        endBound = L.latLng(
                            waypoints[waypoints.length - 1].latLng.lat,
                            waypoints[waypoints.length - 1].latLng.lng
                        );
                        bounds = L.latLngBounds(startBound, endBound);
                        console.log(bounds);
                        // Calculate center and radius of circle to encompass both points
                        const center = bounds.getCenter();
                        const distance = bounds
                            .getNorthWest()
                            .distanceTo(bounds.getSouthEast());
                        setDistance(distance);

                        // Remove previous circle if exists

                        console.log(distance);
                        // Draw blue circle around both locations if distance is less than 20km

                        if (distance < 10) {
                            const newCircle = L.circle(center, {
                                color: "#C70039",
                                fillColor: "#F94C10",
                                fillOpacity: 0,
                                radius: distance / 2,
                            }).addTo(map);
                            setCircle(newCircle);
                            // Play beep sound
                            setPlayBeep10m(true);
                        } else if (distance < 100) {
                            const newCircle = L.circle(center, {
                                color: "#5AB2FF",
                                fillColor: "#A0DEFF",
                                fillOpacity: .4,
                                radius: distance / 2,
                            }).addTo(map);
                            setCircle(newCircle);
                            // Play beep sound
                            setPlayBeep100m(true);
                        } else if (distance < 500) {
                            const newCircle = L.circle(center, {
                                color: "black",
                                fillColor: "black",
                                fillOpacity: 0,
                                radius: distance / 2,
                            }).addTo(map);
                            setCircle(newCircle);
                            setPlayBeep500m(true);
                        }

                        routes.instructions.forEach((element, index) => {
                            if (element.road !== "") {
                                endPopupDesc = element.road;
                            }
                        });
                        routes.instructions.reverse().forEach((element, index) => {
                            if (element.road !== "") {
                                startPopupDesc = element.road;
                            }
                        });

                        waypoints.forEach(function (waypoint, index) {
                            var latLng = waypoint.latLng;
                            var popupContent;
                            if (index === 0) {
                                popupContent = "Start Waypoint: " + latLng.toString();
                            } else if (index === waypoints.length - 1) {
                                popupContent = "End Waypoint: " + latLng.toString();
                            }
                            popupMarkers[index].bindPopup(popupContent);
                        });
                    });

                    setRoutingControl(newRoutingControl);

                    // Update the distance state
                } else {
                    routingControl.setWaypoints(waypoints);
                    if (map && circle) {
                        map?.removeLayer(circle);
                        setCircle(null);
                    }
                }
            } catch (e) { }
        } else {
            if (routingControl) {
                routingControl.remove();
                setRoutingControl(null);
            }
            if (map && circle) {
                map?.removeLayer(circle);
                setCircle(null);
            }
        }
    }, [map, data, did]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {

        return () => {
            if (map && routingControl) {
                routingControl.off("routeselected")
                map.removeControl(routingControl)
                console.log("clearing routingControl!")
            }
        }
    }, [routingControl, map])

    useEffect(() => {
        if (enableBuzzer && playBeep10m) {
            const beep = new Audio(beepSound10m);
            beep.play();
            setTimeout(function () {
                beep.pause();
                beep.currentTime = 0;
                setPlayBeep10m(false);
            }, 3000);
        }
    }, [playBeep10m, enableBuzzer]);

    useEffect(() => {
        if (enableBuzzer && playBeep100m) {
            const beep = new Audio(beepSound100m);
            beep.play();
            setTimeout(function () {
                beep.pause();
                beep.currentTime = 0;
                setPlayBeep100m(false);
            }, 3000);
        }
    }, [playBeep100m, enableBuzzer]);

    useEffect(() => {
        if (enableBuzzer && playBeep500m) {
            const beep = new Audio(beepSound500m);
            beep.play();
            setTimeout(function () {
                beep.pause();
                beep.currentTime = 0;
                setPlayBeep500m(false);
            }, 3000);
        }
    }, [playBeep500m, enableBuzzer]);

    return (
        <Stack justifyContent={"flex-start"} alignItems={"flex-start"} sx={{ width: '100%', height: maxHeight ? maxHeight : `${window.innerHeight - 60}px` }}>
            <CardMedia component={"div"} id={id} style={{ width: '100%', height: "100%" }} />
            <CardActions>
                <Chip label={enableBuzzer ? "Buzzer ON" : "Buzzer OFF"} onClick={() => setEnableBuzzer(ps => !ps)} />
                {setOpen && <Chip label="Close" onClick={() => setOpen(false)} />}
            </CardActions>
        </Stack>
    );
}

// Function to calculate distance between two coordinates using Haversine formula
function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}
