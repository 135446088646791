import { Box, Stack } from '@mui/material';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";

export default function Footer() {
    return (
        <Stack className='footer' direction='row'
            sx={{ py: 2, px: 4, bgcolor: 'background.default' }}
        >
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box>
                <Typography variant='subtitle2'>
                    <Link href="https://knuct.com/index.html#about" target="_black" sx={{ m: 1, textDecoration: 'none' }}>About</Link>
                    <Link href="https://knuct.com/index.html#contact" target="_black" sx={{ m: 1, textDecoration: 'none' }}>Contact</Link>
                </Typography>
            </Box>
        </Stack >
    );
}