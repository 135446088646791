import wordwrap from 'wordwrapjs';
import { randomIntArrayInRange } from './common';

export const vc = (style, plaintText, wrapWidth = 25) => {
    return new Promise((resolve, reject) => {
        var textImage = window?.TextImage(style);
        const msgImg = textImage.toDataURL(`${wordwrap.wrap(plaintText, { width: wrapWidth, break: true })}`)

        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var canvas1 = document.createElement("canvas");
        var ctx1 = canvas1.getContext("2d");
        var canvas2 = document.createElement("canvas");
        var ctx2 = canvas2.getContext("2d");
        var canvas3 = document.createElement("canvas");
        var ctx3 = canvas3.getContext("2d");
        var imgData = []

        var image = new Image();

        image.src = msgImg

        image.onload = function () {
            canvas.height = image.height
            canvas.width = image.width
            canvas1.height = image.height
            canvas1.width = image.width
            canvas2.height = image.height
            canvas2.width = image.width
            canvas3.height = image.height
            canvas3.width = image.width
            ctx.drawImage(image, 0, 0);
            imgData = ctx.getImageData(0, 0, image.width, image.height)
            const imgArray = new Uint8Array(imgData.data.buffer)
            const pvtShare1Array = new Uint8Array(randomIntArrayInRange(0, 255, imgArray.length))

            const imgData1 = ctx1.createImageData(image.width, image.height)
            const imgData2 = ctx2.createImageData(image.width, image.height)
            const imgData3 = ctx3.createImageData(image.width, image.height)
            for (let i = 0; i < imgData1.data.length; i += 4) {
                pvtShare1Array[i + 3] = 255; //imgArray[i + 3]
                imgData1.data[i + 0] = pvtShare1Array[i + 0];
                imgData1.data[i + 1] = pvtShare1Array[i + 1];
                imgData1.data[i + 2] = pvtShare1Array[i + 2];
                imgData1.data[i + 3] = pvtShare1Array[i + 3];

                imgData2.data[i + 0] = imgArray[i + 0] ^ pvtShare1Array[i + 0];
                imgData2.data[i + 1] = imgArray[i + 1] ^ pvtShare1Array[i + 1];
                imgData2.data[i + 2] = imgArray[i + 2] ^ pvtShare1Array[i + 2];
                imgData2.data[i + 3] = 255; //imgArray[i + 3] ^ pvtShare1Array[i + 3];

                imgData3.data[i + 0] = imgData2.data[i + 0] ^ pvtShare1Array[i + 0];
                imgData3.data[i + 1] = imgData2.data[i + 1] ^ pvtShare1Array[i + 1];
                imgData3.data[i + 2] = imgData2.data[i + 2] ^ pvtShare1Array[i + 2];
                imgData3.data[i + 3] = 255; //imgData2.data[i + 3] ^ pvtShare1Array[i + 3];
            }

            ctx1.putImageData(imgData1, 0, 0)
            ctx2.putImageData(imgData2, 0, 0)
            ctx3.putImageData(imgData3, 0, 0)
            if (canvas1.toDataURL() && canvas2.toDataURL())
                resolve(([canvas1.toDataURL(), canvas2.toDataURL()]))
            else {
                reject(new Error("share create error!"))
            }
        }
    })
}