import localforage from "localforage";

export var fileStore = localforage.createInstance({
    name: "fileStore"
});

export var historyStore = localforage.createInstance({
    name: "history"
});

export var recordingsStore = localforage.createInstance({
    name: "recordings"
});

export var contactsStore = localforage.createInstance({
    name: "contacts"
});

export const updateHistory = (id, newData) => {
    historyStore.getItem(id).then(prevData => {
        const nextData = { ...prevData, ...newData }
        historyStore.setItem(id, nextData).then(updatedData => {
            console.log("updatedData:", updatedData)
        }).catch(() => {
            console.log("updateDate error")
        })
    }).catch(() => {
        console.log("data not found error")
    })
}