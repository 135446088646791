import useNetworkStatus from "../hooks/useNetworkStatus "

import { Stack, Tooltip } from "@mui/material";

import CircleIcon from '@mui/icons-material/Circle';
import PublicOffTwoToneIcon from '@mui/icons-material/PublicOffTwoTone';

export default function OnlineOfflineStatus() {
    const { isOnline } = useNetworkStatus();

    return (
        <Stack alignItems={"center"} sx={{ mx: 1 }}>
            {isOnline && <Tooltip title={"online"}><CircleIcon sx={{ fontSize: 12 }} color={"success"} /></Tooltip>}
            {!isOnline && <Tooltip title={"no internet connection"}><PublicOffTwoToneIcon color={"error"} /></Tooltip>}
        </Stack >
    )
}