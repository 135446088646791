import { useEffect, useState } from "react";
import { db, messageEntity } from "../utils/clientdb";

const useStoredMessages = (from, to) => {
    const [storedMessages, setStoredMessages] = useState([])

    useEffect(() => {
        if (from && to) {
            console.log("---useStoredMessage---")
            const chatMessages = [...db.entity(messageEntity).query({ from: to, to: from }).all, ...db.entity(messageEntity).query({ from: from, to: to }).all].sort((a, b) => (a?.timestamp - b?.timestamp))
            setStoredMessages(chatMessages)

            const unsubscribe_create_event = db.entity(messageEntity).events.on("created", () => {
                // console.log("/// create event ///")
                const chatMessages = [...db.entity(messageEntity).query({ from: to, to: from }).all, ...db.entity(messageEntity).query({ from: from, to: to }).all].sort((a, b) => (a?.timestamp - b?.timestamp))
                setStoredMessages(chatMessages)
            })

            const unsubscribe_update_event = db.entity(messageEntity).events.on("updated", () => {
                // console.log("/// update event ///")
                const chatMessages = [...db.entity(messageEntity).query({ from: to, to: from }).all, ...db.entity(messageEntity).query({ from: from, to: to }).all].sort((a, b) => (a?.timestamp - b?.timestamp))
                setStoredMessages(chatMessages)
            })

            const unsubscribe_remove_event = db.entity(messageEntity).events.on("removed", () => {
                // console.log("/// remove event ///")
                const chatMessages = [...db.entity(messageEntity).query({ from: to, to: from }).all, ...db.entity(messageEntity).query({ from: from, to: to }).all].sort((a, b) => (a?.timestamp - b?.timestamp))
                setStoredMessages(chatMessages)
            })

            return () => {
                console.log("useStoredMessage cleanup!")
                unsubscribe_create_event()
                unsubscribe_update_event()
                unsubscribe_remove_event()
            }
        }
    }, [from, to])

    return storedMessages
};

export default useStoredMessages;