import axios from "axios"

const gameApiAxios = axios.create({
    baseURL: process.env.REACT_APP_KNUCT_GAME_URL
})

gameApiAxios.defaults.withCredentials = true

export const ErrorSource = {
    Client: 'client',
    Server: 'server'
}

export const ErrorTypes = {
    NetworkError: 'Network Error',
    TimeoutError: 'Timeout Error',
    RequestAborted: 'Timeout Error',
    RequestCanceled: 'Request Canceled',
    JSONParseError: 'JSON Parse Error',
    BadResponse: 'Bad Response',     // used when response has bad data ( during response parsing)
    Unknown: 'Unknown Error'
}


// ------------ request functions -------------
export function authChallenge(hash, cancelCallback = null) {
    const data = {
        hash: hash
    }

    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios({
        method: 'post',
        url: '/auth/challenge',
        data: data,
        signal: controller.signal,
    })
}

export function authResponse(response, cancelCallback = null) {
    const data = {
        response: response
    }

    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios({
        method: 'post',
        url: '/auth/response',
        data: data,
        signal: controller.signal,
        withCredentials: true,
    })
}

export function logout() {

    let prom = gameApiAxios({
        method: 'get',
        url: '/logout',
        //withCredentials: true,
    })
    prom.catch((err) => {
        console.log('logout error: ', err)
    })
    return prom
}

// ----------------- interceptors ----------------
const responseInterceptor = gameApiAxios.interceptors.response.use(
    function (response) { return response },
    function (error) {
        const tError = transformError(error)
        if (tError.source == ErrorSource.Server) {
            if (tError.status == 401) {
                // not authenticated redirect to auth page
                //window.location.assign('/?requireauth')
                console.error('not authentictad')
            }
        }

        return Promise.reject(tError)
    } // end error func
)

// --------------------------------------------------

function transformError(error) {
    if (axios.isCancel(error))
        return createClientError(ErrorTypes.RequestCanceled, 'Request canceled by calling cancel callback.')
    if (error.message && error.message == 'Network Error')
        return createClientError(ErrorTypes.NetworkError, 'Network error, see if you are connected.', error)
    else if (error.message && error.message.match('timeout'))
        return createClientError(ErrorTypes.TimeoutError, 'Request timedout, see if you are connected.', error)
    else if (error.message && error.message == 'Request aborted')
        return createClientError(ErrorTypes.RequestAborted, 'Request aborted.', error)
    else if (error.code && error.code == 'E_JSON_PARSE')
        return createClientError(ErrorTypes.JSONParseError, error.message, error)
    else if (error.message && error.message.match('Request failed with status code'))
        return createServerError(error)
    else {
        //check if request was cancelled the return aborted error.
        return createClientError(ErrorTypes.Unknown, 'Something went wrong', error)
    }
}

export function createServerError(err) {
    let e = new Error('Server returned error')
    e.name = 'SAPIError'
    e.source = ErrorSource.Server
    if (err.response) {
        // if status code not set set it with 999
        e.status = err.response.status ? err.response.status : 999
        e.statusText = err.response.statusText ? err.response.statusText : 'No Status'

        // check if error response
        if (err.response.status.toString().trim().charAt(0) != '2') {
            // has no error data
            if (!err.response.data) {
                e.data = {
                    error: {
                        title: 'Error - No Response Data',
                        detail: 'Error occured but server returned no data'
                    }
                }
            }
            // data is not json type
            else if (typeof err.response.data !== 'object') {
                e.data = {
                    error: {
                        title: 'Error - Bad Response',
                        detail: 'Server returned bad response'
                    }
                }
                // log the data
                console.error('Bad Response', err.response.data)
            }
            // has data but doesn't contain any error info
            else if (!err.response.data.error) {
                e.data = {
                    error: {
                        title: 'Error - No Error Info',
                        detail: 'Error occured but server returned no information about the error'
                    }
                }
                // log the data
                console.error('Bad Response', err.response.data)
            }
            // error is not of proper type
            else if (!err.response.data.error.title || !err.response.data.error.detail) {
                err.response.data.error.title = err.response.data.error.title || 'No Error Title'
                err.response.data.error.detail = err.response.data.error.detail || 'No error detail'
                // log the data
                console.error('Bad Response', err.response.data)
            }
            else {
                e.data = err.response.data
            }
        }
        // successfull response
        else if (err.response.data) {
            e.data = err.response.data
        }
    }
    // no response
    else {
        e.status = 999
        e.statusText = 'unknown'
        e.data = {
            error: {
                title: 'No Response',
                detail: 'No response received from server'
            }
        }
    }
    e.cause = err
    return e
}

export function createClientError(type, msg, err) {
    let e = new Error(msg)
    e.name = 'SAPIError'
    e.source = ErrorSource.Client
    e.type = type
    e.cause = err
    return e;
}

export function payment(data, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios.post("/user/payment", data, { signal: controller.signal })
}

export function userScoreboard(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios.get("/user/scoreboard", { signal: controller.signal })
}

export function dashboard(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios.get("/user/dashboard", { signal: controller.signal })
}

export function attemptChance(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios.post("/user/attempt", { signal: controller.signal })
}

export function redeemPoints(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios.post("/user/redeem", { signal: controller.signal })
}

export function gameNodes(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios.get("/public/freeNodes", { signal: controller.signal })
}

export function releaseGameNodes(did, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return gameApiAxios.post("/public/releaseBusyNodes", { did: did }, { signal: controller.signal })
}