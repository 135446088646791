import { historyStore } from "../../utils/localforageInstances";
import { useState, useEffect } from "react";
import { Alert, Button, Stack, Typography, Skeleton, Avatar } from '@mui/material';
import { IconButton } from '@mui/material';
import { ListItem, ListItemAvatar, ListItemIcon, ListItemText } from '@mui/material';
import { format } from 'date-fns';

import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import RefreshIcon from '@mui/icons-material/Refresh';
import HistoryIcon from '@mui/icons-material/History';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export default function CallHistory() {
    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (historyStore) {
            let temp = []
            setLoading(true)
            setError(false)
            historyStore.iterate(function (value, key, iterationNumber) {
                temp = [...temp, { key: key, ...value }]
            }).then(function () {
                console.log('Iteration has completed');
                const sortedHistory = temp
                    // .filter(x => x?.receivedAt && x?.madeAt && x)
                    .map(x => ({ ...x, arrivedAt: x?.madeAt ? new Date(x?.madeAt).getTime() : new Date(x?.receivedAt).getTime() }))
                    .sort((a, b) => ((a.arrivedAt < b.arrivedAt) ? 1 : (a.arrivedAt > b.arrivedAt) ? -1 : 0))
                setHistory(sortedHistory)
                setLoading(false)
            }).catch(function (err) {
                console.log(err);
                setError(err?.message)
            }).finally(() => {
                setLoading(false)
            });
        }
    }, [refresh])

    return (
        <Stack spacing={1} sx={{ wordBreak: "break-all" }}>
            {history.length > 0 && history.length > 0 && <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="subtitle1" fontWeight={"bold"} color={"text.secondary"}>Call history • {history.length}</Typography>
                <IconButton size="small" onClick={() => setRefresh(ps => !ps)}><RefreshIcon fontSize="small" /></IconButton>
            </Stack>}
            {!loading && !error && history.length === 0 && <Typography align="center" variant="subtitle1" fontWeight={"bold"} color={"text.disabled"}>No history found!</Typography>}
            {error && <Alert severity="error">{error}</Alert>}
            {loading && [...Array(3)].map((_, index) => (<Skeleton key={index} variant="rounded" height={60} />))}
            {!loading && !error && history.length > 0 && <Stack maxHeight={500} spacing={0} sx={{ border: 1, borderRadius: 3, borderColor: "divider", overflow: "auto", scrollbarWidth: "thin" }}>
                {history.length > 0 && history
                    .map(x => (
                        <ListItem key={x?.key} divider>
                            {x?.direction === 'outgoing' ? <>
                                <ListItemAvatar>
                                    <Avatar sx={{ background: "transparent" }} variant="rounded">
                                        {x?.acceptedAt ? <CallMadeIcon color="success" /> : <CallMissedOutgoingIcon color="error" />}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ wordBreak: "break-all" }} primary={<Typography variant="subtitle2">{x?.toId}</Typography>} secondary={x?.receivedAt ? format(new Date(x?.receivedAt), "Ppp") : x?.madeAt ? format(new Date(x?.madeAt), "Ppp") : ""} />
                                <ListItemIcon>
                                    {x.erroredAt && <ErrorRoundedIcon color="error" />}
                                </ListItemIcon>
                            </> : <>
                                <ListItemAvatar>
                                    <Avatar sx={{ background: "transparent" }} variant="rounded">
                                        {x?.acceptedAt ? <CallReceivedIcon color="success" /> : <CallMissedIcon color="error" />}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ wordBreak: "break-all" }} primary={<Typography variant="subtitle2">{x?.fromId}</Typography>} secondary={x?.receivedAt ? format(new Date(x?.receivedAt), "Ppp") : x?.madeAt ? format(new Date(x?.madeAt), "Ppp") : ""} />
                                <ListItemIcon>
                                    {x.erroredAt && <ErrorRoundedIcon color="error" />}
                                </ListItemIcon>
                            </>}
                        </ListItem>
                    ))}
                {Object.keys(history).length > 0 && <Button color="error" size="large" onClick={() => { historyStore.clear(); setHistory({}) }} sx={{ p: 2 }}>Delete logs</Button>}
            </Stack>}
        </Stack >
    )
}