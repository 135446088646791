import { useState, useEffect, useContext, useRef } from "react";
import { Alert, Box, Divider, Button, Stack, Grid, Typography, TextField, Tooltip, ListItem, ListItemText, ToggleButton, ListItemIcon } from '@mui/material';
import { Badge, Card, CardMedia, CardActions, Dialog, IconButton, Chip, Fab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format, formatDistanceToNow } from 'date-fns';
import SEA from "gun/sea";
import { Howl } from "howler";
import RecordRTC from "recordrtc";
import { VideoStreamMerger } from "video-stream-merger";
import { useSnackbar } from 'notistack';
import { distance } from "../utils/distance";
import { vc } from "../utils/vc";
import { db, messageEntity, receivedFileEntity } from "../utils/clientdb";

import ringtone from '../assets/audio/office_phone.mp3';
import messagetone from '../assets/audio/messagetone.mp3';

import AppContext from '../AppContext';
import FilePreview from "../components/FilePreview";
import useServices from "../hooks/useServices";
import useContacts from "../hooks/useContacts";
import useStoredMessages from "../hooks/useStoredMessages";

import { socket } from "../utils/socket";
import { mypeer } from "../utils/peer";
import { formatBytes } from "../utils/common";
import { historyStore, updateHistory, recordingsStore } from "../utils/localforageInstances";

import { useDropzone } from 'react-dropzone';

import { Geolocation } from '@capacitor/geolocation';
import { Device } from '@capacitor/device';

import DistanceMap from "../components/DistanceMap";
import P2PReceivedFiles from "../components/P2PReceivedFiles";
import ShowAddress from "../components/ShowAddress";
import { MessageBubble } from "./KnuctTalk";

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ChatIcon from '@mui/icons-material/Chat';
import PlaceIcon from '@mui/icons-material/Place';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MapIcon from '@mui/icons-material/Map';

const constraints = {
    audio: {
        noiseSuppression: { exact: true },
        echoCancellation: { exact: true },
    },
    video: {
        width: { exact: 320 },
        height: { exact: 240 },
        frameRate: 16
    }
}

const incoming_sound = new Howl({
    src: [ringtone],
    autoplay: false,
    onload: () => {
        console.log("Loaded!")
    },
    onend: () => {
        console.log('Finished!');
    }
});

const message_sound = new Howl({
    src: [messagetone],
    autoplay: false,
    onload: () => {
        console.log("Loaded!")
    },
    onend: () => {
        console.log('Finished!');
    }
});

const pulseAnimation = {
    animation: "pulse 1500ms infinite",
    "@keyframes pulse": {
        "0%": {
            boxShadow: "#16FF00 0 0 0 0"
        },
        "75%": {
            boxShadow: "#9CFF2E00 0 0 0 16px"
        }
    }
}

const MAX_FILE_SIZE = 500 * 1024 * 1024
const ALLOW_INDIVIDUAL_CALL_RECORDINGS = true
const ALLOW_COMBINED_CALL_RECORDINGS = false
const ALLOW_WATERMARK_ON_CALL_RECORDINGS = false

export default function AnswerCall() {
    const [appState, appStateDispatch] = useContext(AppContext)
    const [refresh, setRefresh] = useState(false)
    const [calls, setCalls] = useState({})
    // const [history, setHistory] = useState({})
    const [fingerprint, setFingerprint] = useState("")
    const { contacts, isLoading } = useContacts()
    const [nicknames, setNicknames] = useState({})
    const [gunAuth, setGunAuth] = useState({})
    const allowCallRecording = appState?.callRecording === 'true'
    const peer = mypeer; // || appState.peer;
    const gun = appState.gun;
    const did = appState.did;
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openReceivedFiles, setOpenReceivedFiles] = useState(false)

    const playIncomingSoundButton = useRef()
    const stopIncomingSoundButton = useRef()
    const playMessageSoundButton = useRef()
    const stopMessageSoundButton = useRef()

    useEffect(() => {
        const peerChecker = setInterval(() => {
            console.log("💛 ANSWER PEER: ", peer?._id)
            if (peer?._id) {
                socket.emit("decentralizedIdentity", { did: did, peerId: peer?._id })
                peer.on('call', incomingCall => {
                    try {
                        try { stopIncomingSoundButton.current.click() } catch (e) { console.log("stop button error: ", e) }
                        try { playIncomingSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                        enqueueSnackbar(`CALL INCOMING`, {
                            variant: "info",
                        })
                        console.log("incoming call from: ", incomingCall?.peer, incomingCall?.metadata)
                        historyStore.setItem(incomingCall?.connectionId,
                            {
                                direction: "incoming",
                                from: incomingCall?.peer,
                                to: peer?._id,
                                receivedAt: new Date().toISOString(),
                                ...(incomingCall?.metadata && incomingCall?.metadata)
                            }
                        )
                        setCalls(ps => ({ ...ps, [incomingCall?.peer]: incomingCall }))
                        incomingCall.on('disconnected', () => {
                            console.log("incoming call disconnected")
                            try { stopIncomingSoundButton.current.click() } catch (e) { console.log("stop button error: ", e) }
                        })
                        incomingCall.on('close', () => {
                            console.log("incoming call closed")
                            try { stopIncomingSoundButton.current.click() } catch (e) { console.log("stop button error: ", e) }
                        })
                        incomingCall.on('error', () => {
                            console.log("incoming call errored")
                            try { stopIncomingSoundButton.current.click() } catch (e) { console.log("stop button error: ", e) }
                        })
                    } catch (e) { console.log(e) }
                })

                peer.on('open', (id) => {
                    socket.on('connect', () => {
                        socket.emit("decentralizedIdentity", { did: did, peerId: id })
                    })
                    socket.emit("decentralizedIdentity", { did: did, peerId: id })
                })

                peer.on('connection', conn => {
                    try {
                        const { from, to } = conn?.metadata
                        conn.on('data', data => {
                            console.log('Data Received!');
                            if (data?.file && data?.type) {
                                console.log("FILE IS RECEIVED")
                                try { stopMessageSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                                try { playMessageSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                                const blob = new Blob([data.file], { type: data.type });
                                const newFile = new File([blob], data.name, { type: data.type })
                                const newData = {
                                    id: data?.id,
                                    file: newFile,
                                    from: from,
                                    to: to,
                                    sendOn: data?.sendOn,
                                    timestamp: data?.timestamp,
                                    arrivedAt: new Date().getTime(),
                                }
                                if (from && to && data?.to === to && data?.from === from && data?.id) {
                                    try {
                                        db.entity(receivedFileEntity).create({ ...newData })
                                        console.log("🟢 record created")
                                    } catch (err) {
                                        console.log("🔴 record create error: ", err)
                                        try {
                                            db.entity(receivedFileEntity).update(data?.id, { ...newData })
                                            console.log("🟢 record updated")
                                        }
                                        catch (e) { console.log("🔴 record update error: ", e) }
                                    }
                                }
                            }
                            if (from && to && data?.to === to && data?.from === from && data?.uuid) {
                                try {
                                    db.entity(messageEntity).create({ id: data?.uuid, voice: null, attachment: null, vc: false, edited: false, ...data })
                                    console.log("🟢 msg record created")
                                } catch (err) {
                                    console.log("🔴 msg record create error: ", err)
                                    try {
                                        db.entity(messageEntity).update(data?.uuid, { id: data?.uuid, voice: null, attachment: null, vc: false, edited: false, ...data })
                                        console.log("🟢 msg record updated")
                                    }
                                    catch (e) { console.log("🔴 msg record update error: ", e) }
                                }
                            }
                        })
                    } catch (e) { console.log(e) }
                })

                clearInterval(peerChecker)
            }
        }, 2 * 1000);

        return () => {
            peer?.off()
            incoming_sound.stop()
            clearInterval(peerChecker)
        }

    }, [peer, did])

    useEffect(() => {
        const authChecker = setInterval(() => {
            console.log("🔑 2. AUTH CHECKING...")
            try {
                if (window.localStorage) {
                    const auth = window.localStorage.getItem("gunAuth")
                    const _fingerprint = window.localStorage.getItem("fingerprint")
                    if (_fingerprint) {
                        setFingerprint(_fingerprint)
                        console.log("🕵🏼‍♂️ fingerprint: %s", _fingerprint)
                    }
                    if (auth) {
                        const authObject = JSON.parse(auth)
                        console.log("🔥 OLD AUTH")
                        setGunAuth(ps => ({ ...ps, ...(authObject) }))
                        clearInterval(authChecker)
                    }
                }

            } catch (e) {
                console.log("error: ", e)
            }
        }, 1 * 1000);

        return () => {
            clearInterval(authChecker)
        }
    }, [])

    useEffect(() => {
        if (contacts && contacts.length > 0) {
            // console.log("contacts: ", contacts)
            const tempNicknames = {}
            contacts
                .filter(x => x?.did && x?.onlineStatus && x)
                .map(x => {
                    tempNicknames[x.did] = x?.nickname
                    return x?.nickname
                })
            setNicknames(tempNicknames)
        }
    }, [contacts])

    const open = calls && Object.keys(calls).length > 0 && Object.values(calls).filter(x => x && x?.peer && x).length > 0

    return (
        <Box>
            <button id="ringtone_play" ref={playIncomingSoundButton} onClick={() => incoming_sound.play()} style={{ display: "none" }}>ringtone play</button>
            <button id="ringtone_stop" ref={stopIncomingSoundButton} onClick={() => incoming_sound.stop()} style={{ display: "none" }}>ringtone stop</button>
            <button id="messagetone_play" ref={playMessageSoundButton} onClick={() => message_sound.play()} style={{ display: "none" }}>messagetone play</button>
            <button id="messagetone_stop" ref={stopMessageSoundButton} onClick={() => message_sound.stop()} style={{ display: "none" }}>messagetone stop</button>

            <Dialog open={open} sx={{ mb: { xs: 6, sm: 6, md: 0, lg: 0 } }} fullScreen>
                <Stack spacing={1} sx={{ p: { xs: 1, sm: 2, md: 2, lg: 2 } }}>
                    {calls && Object.keys(calls).length > 0 && Object.values(calls)
                        .filter(x => x && x?.peer && x)
                        .map(call => (
                            <CallConnection key={call?.peer} did={did} gun={gun} gunAuth={gunAuth} peer={peer} call={call} setCalls={setCalls} nicknames={nicknames} stopIncomingSoundButton={stopIncomingSoundButton} allowCallRecording={allowCallRecording} appState={appState} />
                        ))}
                </Stack>
            </Dialog>
        </Box>
    )
}

const CallConnection = ({ peer, did, gun, gunAuth, call, setCalls, nicknames, stopIncomingSoundButton, allowCallRecording, appState }) => {
    const [streams, setStreams] = useState({ localStream: undefined })
    const [isCamera, setIsCamera] = useState(true)
    const [callStates, setCallStates] = useState({ ringing: true, answering: false })
    const [isVideo, setIsVideo] = useState(false)
    const [isAudio, setIsAudio] = useState(false)
    const [showChat, setShowChat] = useState(true)

    const data = useServices(did)
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { fromId, toId } = call?.metadata

    useEffect(() => {
        console.log("ONLINE STATUS UPDATE!")
        const onlineInterval = setInterval(() => {
            const message = { isOnline: true, updatedOn: new Date().getTime() }
            did && SEA.sign(message, gunAuth).then(signedMessage => {
                signedMessage && gun.get(did).get("isOnline").put(signedMessage)
            })

        }, 5 * 1000);

        return () => {
            clearInterval(onlineInterval)
            const message = { isOnline: false, updatedOn: new Date().getTime() }
            did && SEA.sign(message, gunAuth).then(signedMessage => {
                signedMessage && gun.get(did).get("isOnline").put(signedMessage).then(response => {
                    console.log("🔴 OFFLINE %O", message)
                })
            })
        }
    }, [did, gun, gunAuth])

    const toggleVideo = () => {
        console.log("toggle video")
        if (streams.localStream?.getVideoTracks()[0]) {
            streams.localStream.getVideoTracks()[0].enabled = !streams.localStream?.getVideoTracks()[0].enabled;
            setIsVideo(streams.localStream?.getVideoTracks()[0]?.enabled)
            console.log("toggling video")
        } else {
            navigator?.mediaDevices && navigator.mediaDevices.getUserMedia(constraints)
                .then((stream) => {
                    setIsCamera(true)
                    setStreams(ps => ({ ...ps, localStream: stream }))
                    if (stream?.getVideoTracks()[0]) {
                        setIsVideo(stream.getVideoTracks()[0]?.enabled)
                    }
                    if (stream?.getAudioTracks()[0]) {
                        setIsAudio(stream.getAudioTracks()[0]?.enabled)
                    }
                }).catch(error => {
                    setIsCamera(false)
                    console.log("MEDIA DEVICE USER MEDIA ERROR %O", error)
                    // navigator?.mediaDevices && navigator.mediaDevices.getDisplayMedia()
                    //     .then((stream) => {
                    //         setStreams(ps => ({ ...ps, localStream: stream }))
                    //         if (stream?.getVideoTracks()[0]) {
                    //             setIsVideo(stream.getVideoTracks()[0]?.enabled)
                    //         }
                    //         if (stream?.getAudioTracks()[0]) {
                    //             setIsAudio(stream.getAudioTracks()[0]?.enabled)
                    //         }
                    //     }).catch(error => {
                    //         console.log("MEDIA DEVICE DISPLAY MEDIA ERROR %O", error)
                    //     })
                })
        }
    }

    const toggleAudio = () => {
        console.log("toggle audio")
        if (streams.localStream?.getAudioTracks()[0]) {
            streams.localStream.getAudioTracks()[0].enabled = !streams.localStream.getAudioTracks()[0].enabled;
            setIsAudio(streams.localStream.getAudioTracks()[0]?.enabled)
            console.log("toggling audio")
        }
    }

    useEffect(() => {
        navigator?.mediaDevices && navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                setIsCamera(true)
                setStreams(ps => ({ ...ps, localStream: stream }))
                if (stream?.getVideoTracks()[0]) {
                    setIsVideo(stream.getVideoTracks()[0]?.enabled)
                }
                if (stream?.getAudioTracks()[0]) {
                    setIsAudio(stream.getAudioTracks()[0]?.enabled)
                }
            }).catch(error => {
                setIsCamera(false)
                console.log("MEDIA DEVICE USER MEDIA ERROR %O", error)
                // navigator?.mediaDevices && navigator.mediaDevices.getDisplayMedia()
                //     .then((stream) => {
                //         setStreams(ps => ({ ...ps, localStream: stream }))
                //         if (stream?.getVideoTracks()[0]) {
                //             setIsVideo(stream.getVideoTracks()[0]?.enabled)
                //         }
                //         if (stream?.getAudioTracks()[0]) {
                //             setIsAudio(stream.getAudioTracks()[0]?.enabled)
                //         }
                //     }).catch(error => {
                //         console.log("MEDIA DEVICE DISPLAY MEDIA ERROR %O", error)
                //     })
            })
    }, [])

    useEffect(() => {
        if (streams?.localStream) {
            var displayMediaVideoOutgoing = document.getElementById("ME->" + call?.peer);
            if (displayMediaVideoOutgoing) {
                displayMediaVideoOutgoing.srcObject = streams?.localStream;
                displayMediaVideoOutgoing.addEventListener('loadedmetadata', () => {
                    displayMediaVideoOutgoing.play();
                })
            }
        }

        return () => {
            if (streams?.localStream) {
                streams?.localStream?.getTracks().forEach((track) => {
                    track.stop();
                });

                console.log("LOCAL STREAM GOT CLEARED 🗑!")
            }
        }
    }, [streams, call?.peer])

    const answerCall = () => {
        try {
            try { stopIncomingSoundButton.current.click() } catch (e) { console.log("stop button error: ", e) }
            if (call?.peer && streams?.localStream) {
                var displayMediaVideoIncoming = document.getElementById(call?.peer + "->ME");
                call.answer(streams?.localStream)
                const { fromId, toId } = call?.metadata
                let remoteRecorder, localRecorder, mergeRecorder
                let videoStreamMerger
                call.on('stream', remoteStream => {
                    console.log("incoming call accepted")
                    if (allowCallRecording && ALLOW_INDIVIDUAL_CALL_RECORDINGS) {
                        remoteRecorder = RecordRTC(remoteStream, {
                            type: 'video'
                        });

                        remoteRecorder.startRecording();

                        localRecorder = RecordRTC(streams?.localStream, {
                            type: 'video'
                        });

                        localRecorder.startRecording();
                    }

                    if (allowCallRecording && ALLOW_COMBINED_CALL_RECORDINGS) {
                        const rw = remoteStream?.getVideoTracks()?.[0]?.getSettings()?.width ?? 320
                        const rh = remoteStream?.getVideoTracks()?.[0]?.getSettings()?.height ?? 320
                        const lw = streams?.localStream?.getVideoTracks()?.[0]?.getSettings()?.width ?? 320
                        const lh = streams?.localStream?.getVideoTracks()?.[0]?.getSettings()?.height ?? 320

                        console.log(rw, rh, lw, lh)

                        videoStreamMerger = new VideoStreamMerger({
                            width: rw + lw,
                            height: rh + lh
                        })

                        videoStreamMerger.addStream(remoteStream, {
                            x: 0,
                            y: 0,
                            width: rw,
                            height: rh,
                            mute: false,
                            ...(ALLOW_WATERMARK_ON_CALL_RECORDINGS && {
                                draw: (ctx, frame, done) => {
                                    // You can do whatever you want with this canvas context
                                    ctx.drawImage(frame, 0, 0, rw, rh)
                                    // // Add a custom caption
                                    ctx.font = '10px JetBrains Mono';
                                    ctx.fillStyle = '#09ff00';
                                    ctx.fillText(`${new Date().toISOString()}`, 5, 15);
                                    ctx.fillText(`FROM: ${fromId}`, 5, 30);
                                    ctx.fillText(`TO: ${toId}`, 5, 45);
                                    done()
                                }
                            })
                        })

                        videoStreamMerger.addStream(streams?.localStream, {
                            x: 0,
                            y: rh,
                            width: lw,
                            height: lh,
                            mute: false,
                        })

                        videoStreamMerger.start()

                        mergeRecorder = RecordRTC(videoStreamMerger.result, {
                            type: 'video'
                        });

                        mergeRecorder.startRecording();
                    }

                    updateHistory(call?.connectionId, { acceptedAt: new Date().toISOString() })

                    setCallStates(ps => ({ ...ps, ringing: false, answering: true }))
                    if (displayMediaVideoIncoming) {
                        displayMediaVideoIncoming.srcObject = remoteStream;
                        displayMediaVideoIncoming.addEventListener('loadedmetadata', () => {
                            displayMediaVideoIncoming.play()
                        })
                    }
                })
                call.on('disconnected', () => {
                    console.log("incoming call disconnected")
                    if (allowCallRecording && ALLOW_INDIVIDUAL_CALL_RECORDINGS) {
                        remoteRecorder.stopRecording((blob) => {
                            const dataUrl = remoteRecorder.toURL()
                            console.log("remote dataUrl: ", dataUrl)
                            recordingsStore.setItem(`remote-${call?.connectionId}`, { file: new File([blob], `incoming-remote-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                        localRecorder.stopRecording((blob) => {
                            const dataUrl = localRecorder.toURL()
                            console.log("local dataUrl: ", dataUrl)
                            recordingsStore.setItem(`local-${call?.connectionId}`, { file: new File([blob], `incoming-local-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                    }
                    if (allowCallRecording && ALLOW_COMBINED_CALL_RECORDINGS) {
                        mergeRecorder.stopRecording((blob) => {
                            const dataUrl = mergeRecorder.toURL()
                            console.log("merge dataUrl: ", dataUrl)
                            recordingsStore.setItem(`merged-${call?.connectionId}`, { file: new File([blob], `incoming-merged-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                    }
                    setCallStates(ps => ({ ...ps, ringing: false, answering: false }))
                    updateHistory(call?.connectionId, { disconnectedAt: new Date().toISOString() })
                    setCalls(ps => ({ ...ps, [call?.peer]: undefined }))
                    if (displayMediaVideoIncoming) {
                        displayMediaVideoIncoming.srcObject = null;
                        // displayMediaVideoIncoming.play()
                    }
                })
                call.on('close', () => {
                    console.log("incoming call closed")
                    if (allowCallRecording && ALLOW_INDIVIDUAL_CALL_RECORDINGS) {
                        remoteRecorder.stopRecording((blob) => {
                            const dataUrl = remoteRecorder.toURL()
                            console.log("remote dataUrl: ", dataUrl)
                            recordingsStore.setItem(`remote-${call?.connectionId}`, { file: new File([blob], `incoming-remote-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                        localRecorder.stopRecording((blob) => {
                            const dataUrl = localRecorder.toURL()
                            console.log("local dataUrl: ", dataUrl)
                            recordingsStore.setItem(`local-${call?.connectionId}`, { file: new File([blob], `incoming-local-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                    }
                    if (allowCallRecording && ALLOW_COMBINED_CALL_RECORDINGS) {
                        mergeRecorder.stopRecording((blob) => {
                            const dataUrl = mergeRecorder.toURL()
                            console.log("merge dataUrl: ", dataUrl)
                            recordingsStore.setItem(`merged-${call?.connectionId}`, { file: new File([blob], `incoming-merged-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                    }
                    setCallStates(ps => ({ ...ps, ringing: false, answering: false }))
                    updateHistory(call?.connectionId, { closedAt: new Date().toISOString() })
                    setCalls(ps => ({ ...ps, [call?.peer]: undefined }))
                    if (displayMediaVideoIncoming) {
                        displayMediaVideoIncoming.srcObject = null;
                        // displayMediaVideoIncoming.play()
                    }
                })
                call.on('error', () => {
                    console.log("incoming call errored")
                    if (allowCallRecording && ALLOW_INDIVIDUAL_CALL_RECORDINGS) {
                        remoteRecorder.stopRecording((blob) => {
                            const dataUrl = remoteRecorder.toURL()
                            console.log("remote dataUrl: ", dataUrl)
                            recordingsStore.setItem(`remote-${call?.connectionId}`, { file: new File([blob], `incoming-remote-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                        localRecorder.stopRecording((blob) => {
                            const dataUrl = localRecorder.toURL()
                            console.log("local dataUrl: ", dataUrl)
                            recordingsStore.setItem(`local-${call?.connectionId}`, { file: new File([blob], `incoming-local-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                    }
                    if (allowCallRecording && ALLOW_COMBINED_CALL_RECORDINGS) {
                        mergeRecorder.stopRecording((blob) => {
                            const dataUrl = mergeRecorder.toURL()
                            console.log("merge dataUrl: ", dataUrl)
                            recordingsStore.setItem(`merged-${call?.connectionId}`, { file: new File([blob], `incoming-merged-${call?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: fromId, to: toId, preview: dataUrl })
                        })
                    }
                    setCallStates(ps => ({ ...ps, ringing: false, answering: false }))
                    updateHistory(call?.connectionId, { erroredAt: new Date().toISOString() })
                    setCalls(ps => ({ ...ps, [call?.peer]: undefined }))
                    if (displayMediaVideoIncoming) {
                        displayMediaVideoIncoming.srcObject = null;
                        // displayMediaVideoIncoming.play()
                    }
                })
            }
        } catch (e) { console.log(e) }
    }

    const endCall = () => {
        try {
            try { stopIncomingSoundButton.current.click() } catch (e) { console.log("stop button error: ", e) }
            if (call?.peer) {
                console.log("🔴 ENDING ANSWERED CALL", call)
                var displayMediaVideoIncoming = document.getElementById(call?.peer + "->ME");
                call?.close()
                setCalls(ps => ({ ...ps, [call?.peer]: undefined }))
                setCallStates(ps => ({ ...ps, ringing: false, answering: false }))
                if (displayMediaVideoIncoming) {
                    displayMediaVideoIncoming.srcObject = null;
                    // displayMediaVideoIncoming.play()
                }
            } else {
                console.log("💥 END CALL FAILED", call)
            }
        } catch (e) { console.log(e) }
    }

    return (
        <Grid container gap={1} justifyContent={"center"} alignItems={"flex-start"}>
            <Grid item xs={12} sm={12} md={5}>
                <Stack spacing={1} sx={{ border: 1, borderRadius: 3, borderColor: "divider", p: 1 }}>
                    <ListItem sx={{ wordBreak: "break-all", borderBottomColor: "success.main" }} divider>
                        <ListItemText
                            primary={call?.metadata?.fromId && nicknames?.[call?.metadata?.fromId] && (nicknames?.[call?.metadata?.fromId])}
                            secondary={<Typography variant="subtitle2" fontFamily={"JetBrains Mono"} color={"text.secondary"}>{call?.metadata?.fromId ? call?.metadata?.fromId : call?.peer ? call?.peer : "UNKNOWN CALLER"}</Typography>}
                        />
                    </ListItem>

                    <Box sx={{ position: "relative", p: 0, ...(!isCamera && { display: "none" }) }} maxWidth={"md"}>
                        <Card className="shadow-sm" variant="outlined" sx={{ borderRadius: 3, background: "transparent" }}>
                            <CardMedia sx={{ borderRadius: 3, background: "transparent", minHeight: 300, maxHeight: 400 }} id={call?.peer + "->ME"} component={"video"} autoPlay />
                        </Card>
                        <Box sx={{ position: "absolute", bottom: 0, right: 0, p: 1 }}>
                            <Card variant="outlined" sx={{ border: 1, borderColor: "divider", borderRadius: 3, boxShadow: 5 }}>
                                <CardMedia sx={{ borderRadius: 3, background: "black", height: 100, ...(isCamera && { transform: 'ScaleX(-1)', WebkitTransform: "ScaleX(-1)" }) }} id={"ME->" + call?.peer} component={"video"} autoplay muted />
                            </Card>
                        </Box>
                        <Stack justifyContent={"flex-start"} alignItems={"flex-start"} sx={{ position: "absolute", top: 0, left: 0, p: 1 }}>
                            {/* {!showAddress && showLocation && !callerAddress && <Chip label="Show Address" sx={{ borderRadius: 5, border: 2 }} onClick={() => setShowAddress(true)} />} */}
                            {/* {data?.liveLocations?.[fromId]?.latitude && data?.liveLocations?.[fromId]?.longitude && <ShowAddress location={data?.liveLocations?.[fromId]} />} */}
                            <Stack spacing={1}>
                                {data?.liveLocations?.[fromId]?.latitude && data?.liveLocations?.[fromId]?.longitude && <ShowAddress label={"Receiver's"} location={data?.liveLocations?.[fromId]} color="primary" />}
                                {data?.liveLocations?.[toId]?.latitude && data?.liveLocations?.[toId]?.longitude && <ShowAddress label={"Caller's"} location={data?.liveLocations?.[toId]} color="error" />}
                            </Stack>
                        </Stack>
                    </Box>

                    {!isCamera && <Alert severity="warning" sx={{ justifyContent: "center", alignItems: "center", border: 2, borderRadius: 3 }}>
                        You don't have an active media stream to share!
                    </Alert>}

                    {/* {!showAddress && !callerAddress && <Chip label="Show Address" sx={{ borderRadius: 3 }} onClick={() => setShowAddress(true)} />} */}

                    {/* {callerAddress && <Alert severity="info" icon={<LocationOnIcon />} sx={{ justifyContent: "center", alignItems: "center", border: 1, borderRadius: 3 }}>{callerAddress}</Alert>} */}

                    <CardActions sx={{ justifyContent: "space-between", px: 2, border: 1, borderRadius: 3, borderColor: "divider" }}>
                        <Stack justifyContent={"center"} alignItems={"center"}>
                            <IconButton size={smScreen ? "medium" : "large"} color={isAudio ? "info" : "error"} onClick={toggleAudio} >
                                {isAudio ? <MicIcon /> : <MicOffIcon />}
                            </IconButton>
                            <Typography align="center" variant="caption" color={"text.secondary"}>{isAudio ? "Mic On" : "Mic Off"}</Typography>
                        </Stack>
                        <Stack justifyContent={"center"} alignItems={"center"}>
                            <Fab size={smScreen ? "small" : "medium"} color="success" onClick={answerCall} disabled={!streams?.localStream || !call?.peer || !callStates.ringing} sx={{ ...(streams?.localStream && call?.peer && callStates.ringing && pulseAnimation) }}><CallIcon sx={{ color: callStates.ringing ? "white" : 'inherit' }} /></Fab>
                            <Typography align="center" variant="caption" color={"text.secondary"}>Answer Call</Typography>
                        </Stack>
                        <Stack justifyContent={"center"} alignItems={"center"}>
                            <Fab size={smScreen ? "small" : "medium"} color="error" onClick={endCall} ><CallEndIcon sx={{ color: "white" }} /></Fab>
                            <Typography align="center" variant="caption" color={"text.secondary"}>End Call</Typography>
                        </Stack>
                        <Stack justifyContent={"center"} alignItems={"center"}>
                            <IconButton size={smScreen ? "medium" : "large"} color={isVideo ? "info" : "error"} onClick={toggleVideo}>
                                {isVideo ? <VideocamIcon /> : <VideocamOffIcon />}
                            </IconButton>
                            <Typography align="center" variant="caption" color={"text.secondary"}>{isVideo ? "Cam On" : "Cam Off"}</Typography>
                        </Stack>
                        <Stack justifyContent={"center"} alignItems={"center"}>
                            <IconButton size={smScreen ? "medium" : "large"} onClick={() => setShowChat(ps => !ps)}>
                                <Badge badgeContent={showChat ? 0 : db.entity(messageEntity).query({ from: fromId, to: toId }).count} color="error" max={999}>
                                    <ChatIcon color="secondary" />
                                </Badge>
                            </IconButton>
                            <Typography align="center" variant="caption" color={"text.secondary"}>{showChat ? "Hide Chat" : "Show Chat"}</Typography>
                        </Stack>
                    </CardActions>

                    {!isCamera &&
                        <Stack spacing={1}>
                            {data?.liveLocations?.[fromId]?.latitude && data?.liveLocations?.[fromId]?.longitude && <ShowAddress label={"Receiver's"} location={data?.liveLocations?.[fromId]} color="primary" />}
                            {data?.liveLocations?.[toId]?.latitude && data?.liveLocations?.[toId]?.longitude && <ShowAddress label={"Caller's"} location={data?.liveLocations?.[toId]} color="error" />}
                        </Stack>
                    }
                </Stack>
            </Grid>
            {fromId && toId && did && gunAuth && <Grid item xs={12} sm={12} md={6} sx={{ border: 1, borderColor: "divider", borderRadius: 3, ...(!showChat && { display: "none" }) }}>
                <ChatConnection senderKeys={gunAuth} nickname={nicknames?.[call?.metadata?.fromId]} receiverPubs={data?.gunPublicKeys?.[fromId]} location={data?.liveLocations?.[fromId]} to={fromId} from={did} receiverPeerId={call?.peer} showChat={showChat} />
            </Grid>}
        </Grid>

    )

}

const ChatConnection = ({ from, to, nickname, location, senderKeys, receiverPubs, receiverPeerId, showChat }) => {
    const [message, setMessage] = useState("")
    const [attachments, setAttachments] = useState([])
    const [sharedLiveLocation, setSharedLiveLocation] = useState({})
    const [sharedDeviceId, setSharedDeviceId] = useState("")
    const [refresh, setRefresh] = useState(false)
    const [error, setError] = useState(false)
    const [sendMessage, setSendMessage] = useState(false)
    const [isOnline, setIsOnline] = useState(false)
    const [appState, appStateDispatch] = useContext(AppContext)
    const [dataConn, setDataConn] = useState()
    const [myLocation, setMyLocation] = useState({})
    const [showMap, setShowMap] = useState(false)
    const [useVC, setUseVC] = useState(false)
    const gun = appState.gun;
    const peer = mypeer // || appState.peer;
    const storedMessages = useStoredMessages(from, to)
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const chatAreaRef = useRef(null)

    useEffect(() => {
        if (chatAreaRef) {
            chatAreaRef.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });

        }

    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 20,
        maxSize: MAX_FILE_SIZE,
        onError: err => setError(err),
        onDrop: acceptedFiles => {
            setAttachments(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    useEffect(() => {
        console.log("acceptedFiles:", acceptedFiles)
        setAttachments(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
    }, [acceptedFiles, refresh])

    const files = attachments
        .sort((a, b) => a.size > b.size)
        .map((file, index) => (
            <Stack key={file.name + index} spacing={0}>
                <FilePreview file={file} />
            </Stack>
        ));

    useEffect(() => {
        try {
            if (from && peer) {
                const dataConnection = peer.connect(receiverPeerId, {
                    metadata: {
                        from: from,
                        to: to,
                    }
                })

                setDataConn(dataConnection)

                return () => {
                    dataConnection?.off()
                    console.log(`🔴 dataConn CLOSED for ${to}`)
                    // peer.off()
                }
            }
        } catch (e) { console.log(e) }
    }, [peer, from, to, receiverPeerId])

    useEffect(() => {
        let watchId = Geolocation.watchPosition({ enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }, (response, error) => {
            if (!error && response) {
                const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = response.coords

                const liveLocation = {
                    ...(appState.did && { did: appState.did }),
                    accuracy: accuracy,
                    altitude: altitude,
                    altitudeAccuracy: altitudeAccuracy,
                    heading: heading,
                    latitude: latitude,
                    longitude: longitude,
                    speed: speed,
                    timestamp: new Date(response.timestamp)
                }

                setMyLocation(liveLocation)
            }
        })

        return () => {
            Geolocation.clearWatch(watchId)
        }
    }, [appState.did])

    const sendFiles = async () => {
        console.log("📎 FILES GOING TO SEND: ", acceptedFiles)
        for (const file of acceptedFiles) {
            try {
                if (file.size <= MAX_FILE_SIZE) {
                    const blob = new Blob([file], { type: file.type })
                    const timestamp = new Date()
                    dataConn.send({
                        id: crypto.randomUUID(),
                        file: blob,
                        name: file.name,
                        type: file.type,
                        from: from,
                        to: to,
                        sendOn: timestamp.toISOString(),
                        timestamp: timestamp.getTime()
                    })
                }
            } catch (e) {
                console.log(e)
            }
        }
        acceptedFiles.splice(0, acceptedFiles.length)
        setRefresh(ps => !ps)
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const toArrayBuffer = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    useEffect(() => {
        console.log("↻ REFRESH MESSAGES!")
        if (gun && to && from) {
            gun.get(to).get(from).on(signedEncryptedMessage => {
                console.log(`📤 MY MESSAGE TO: ${to}`)
                signedEncryptedMessage && SEA.verify(signedEncryptedMessage, senderKeys?.pub).then(encryptedMessage => {
                    encryptedMessage && SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                        // console.log("shared passphrase: ", passphrase)
                        passphrase && SEA.decrypt(encryptedMessage, passphrase).then(decryptedMessage => {
                            // console.log("myDecryptedMessage:", decryptedMessage)
                            try {
                                decryptedMessage && decryptedMessage?.uuid && db.entity(messageEntity).create({ id: decryptedMessage?.uuid, voice: null, attachment: null, vc: false, edited: false, ...decryptedMessage })
                            } catch (err) {
                                decryptedMessage && decryptedMessage?.uuid && db.entity(messageEntity).update(decryptedMessage?.uuid, { id: decryptedMessage?.uuid, voice: null, attachment: null, vc: false, edited: false, ...decryptedMessage })
                            }
                            // decryptedMessage && decryptedMessage?.uuid && setMessages(ps => ({ ...ps, [decryptedMessage?.uuid]: decryptedMessage }))
                        }).catch(error => console.log(`❌ DECRYPT ERROR ${error}`))
                    }).catch(error => console.log(`❌ SECRET ERROR ${error}`))
                }).catch(error => console.log(`❌ VERIFY ERROR ${error}`))
            })

            gun.get(from).get(to).on(signedEncryptedMessage => {
                console.log(`📥 MESSAGE FROM: ${to}`)
                signedEncryptedMessage && SEA.verify(signedEncryptedMessage, receiverPubs?.pub).then(encryptedMessage => {
                    encryptedMessage && SEA.secret(receiverPubs?.epub, senderKeys).then(pass => {
                        // console.log("shared passphrase: ", pass)
                        pass && SEA.decrypt(encryptedMessage, pass).then(decryptedMessage => {
                            // console.log("theirDecryptedMessage:", decryptedMessage)
                            try {
                                decryptedMessage && decryptedMessage?.uuid && db.entity(messageEntity).create({ id: decryptedMessage?.uuid, voice: null, attachment: null, vc: false, edited: false, ...decryptedMessage })
                            } catch (err) {
                                decryptedMessage && decryptedMessage?.uuid && db.entity(messageEntity).update(decryptedMessage?.uuid, { id: decryptedMessage?.uuid, voice: null, attachment: null, vc: false, edited: false, ...decryptedMessage })
                            }
                            // decryptedMessage && decryptedMessage?.uuid && setMessages(ps => ({ ...ps, [decryptedMessage?.uuid]: decryptedMessage }))
                            gun.get(from).get(to).put(null)
                        }).catch(error => console.log(`❌ DECRYPT ERROR ${error}`))
                    }).catch(error => console.log(`❌ SECRET ERROR ${error}`))
                }).catch(error => console.log(`❌ VERIFY ERROR ${error}`))
            })

            gun.get("liveLocation").get(from).get(to).on(signedEncryptedMessage => {
                console.log(`📥 LIVE LOCATION FROM: ${to}`)
                signedEncryptedMessage && SEA.verify(signedEncryptedMessage, receiverPubs?.pub).then(encryptedMessage => {
                    encryptedMessage && SEA.secret(receiverPubs?.epub, senderKeys).then(pass => {
                        // console.log("shared passphrase: ", pass)
                        pass && SEA.decrypt(encryptedMessage, pass).then(decryptedMessage => {
                            // console.log("theirDecryptedMessage:", decryptedMessage)
                            decryptedMessage && setSharedLiveLocation(decryptedMessage)
                        }).catch(error => console.log(`❌ DECRYPT ERROR ${error}`))
                    }).catch(error => console.log(`❌ SECRET ERROR ${error}`))
                }).catch(error => console.log(`❌ VERIFY ERROR ${error}`))
            })

            gun.get("deviceId").get(from).get(to).on(signedEncryptedMessage => {
                console.log(`📥 DEVICE ID FROM: ${to}`)
                signedEncryptedMessage && SEA.verify(signedEncryptedMessage, receiverPubs?.pub).then(encryptedMessage => {
                    encryptedMessage && SEA.secret(receiverPubs?.epub, senderKeys).then(pass => {
                        // console.log("shared passphrase: ", pass)
                        pass && SEA.decrypt(encryptedMessage, pass).then(decryptedMessage => {
                            // console.log("theirDecryptedMessage:", decryptedMessage)
                            decryptedMessage && setSharedDeviceId(decryptedMessage)
                        }).catch(error => console.log(`❌ DECRYPT ERROR ${error}`))
                    }).catch(error => console.log(`❌ SECRET ERROR ${error}`))
                }).catch(error => console.log(`❌ VERIFY ERROR ${error}`))
            })

            gun.get(to).get("isOnline").on(signedStatus => {
                signedStatus && SEA.verify(signedStatus, receiverPubs?.pub).then(verifiedStatus => {
                    if (verifiedStatus && verifiedStatus?.updatedOn && ((new Date().getTime() - verifiedStatus?.updatedOn) < 10 * 1000)) {
                        setIsOnline(verifiedStatus?.isOnline)
                    }
                    else {
                        setIsOnline(false)
                    }
                })
            })
        }

        return () => {
            gun.get(to).get(from).off()
            gun.get(from).get(to).off()
            gun.get(to).get("isOnline").off()
        }
    }, [to, from, senderKeys, gun, receiverPubs?.epub, receiverPubs?.pub])

    useEffect(() => {
        if (sendMessage && senderKeys && receiverPubs) {
            if (Array.isArray(attachments) && attachments.length === 0 && message) {
                const timestamp = new Date()
                const messageContent = {
                    uuid: crypto.randomUUID(),
                    message: message,
                    from: from,
                    vc: useVC,
                    to: to,
                    sendOn: timestamp.toISOString(),
                    timestamp: timestamp.getTime()
                }

                var style = {
                    font: "JetBrains Mono",
                    align: 'left',
                    color: 'black',
                    size: 18,
                    background: "#FDFF00" || "rgb(0, 0, 0, 0)",
                    stroke: 0,
                    strokeColor: "rgba(255, 255, 255, 1)",
                    lineHeight: "1.2em",
                    bold: true,
                    italic: false,
                };

                if (useVC) {
                    vc(style, message, 25).then(shares => {
                        // console.log("shares:", shares)
                        try {
                            db.entity(messageEntity).create({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                        } catch (err) {
                            db.entity(messageEntity).update(messageContent?.uuid, { id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                        }
                        try { shares && shares?.[0] && shares?.[1] && dataConn.send({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent }) } catch (e) { }
                        // shares && shares?.[0] && shares?.[1] && setMessages(ps => ({ ...ps, [messageContent?.uuid]: { ...messageContent, message: shares } }))
                        shares && shares?.[0] && shares?.[1] && SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                            // console.log("shared passphrase: ", passphrase)
                            passphrase && SEA.encrypt({ ...messageContent, message: shares }, passphrase).then(encryptedMessage => {
                                SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                                    signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                                    setMessage("")
                                    setSendMessage(false)
                                })
                            })
                        })
                    })
                } else {
                    try {
                        db.entity(messageEntity).create({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                    } catch (err) {
                        db.entity(messageEntity).update(messageContent?.uuid, { id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                    }
                    try { dataConn.send({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent }) } catch (e) { }
                    // setMessages(ps => ({ ...ps, [messageContent?.uuid]: messageContent }))
                    SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                        // console.log("shared passphrase: ", passphrase)
                        passphrase && SEA.encrypt(messageContent, passphrase).then(encryptedMessage => {
                            SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                                signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                                setMessage("")
                                setSendMessage(false)
                            })
                        })
                    })
                }

                setMessage("")
                setSendMessage(false)
            }

            if (Array.isArray(attachments) && attachments.length > 0 && attachments < 2 && attachments[0]?.size < MAX_FILE_SIZE - (1 * 1024 * 1024)) {
                const timestamp = new Date()
                toArrayBuffer(attachments[0]).then(result => {
                    crypto.subtle.digest('SHA-256', result).then(hashBuffer => {
                        const hashArray = Array.from(new Uint8Array(hashBuffer));
                        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                        console.log(attachments[0], hashHex);
                        toBase64(attachments[0]).then(encoded => {
                            const messageContent = {
                                uuid: crypto.randomUUID(),
                                message: message,
                                attachment: { ...attachments[0], size: attachments[0]?.size, dataURI: encoded, sha256: hashHex },
                                from: from,
                                to: to,
                                sendOn: timestamp.toISOString(),
                                timestamp: timestamp.getTime()
                            }

                            console.log("messageContent:", messageContent)

                            try {
                                db.entity(messageEntity).create({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                            } catch (err) {
                                db.entity(messageEntity).update(messageContent?.uuid, { id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                            }
                            try { dataConn.send({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent }) } catch (e) { }
                            // setMessages(ps => ({ ...ps, [messageContent?.uuid]: messageContent }))
                            SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                                // console.log("shared passphrase: ", passphrase)
                                passphrase && SEA.encrypt(messageContent, passphrase).then(encryptedMessage => {
                                    SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                                        signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                                        setMessage("")
                                        setSendMessage(false)
                                    })
                                }).catch(() => { setSendMessage(false) })
                            })
                        }).catch(e => console.log("base64 encode error: ", e))
                    }).catch(ex => console.error(ex));
                }).catch(() => { setSendMessage(false) })

                setSendMessage(false)
            } else {
                console.log("⚠ Either attachment not found or over the size limit 1MB.")
                sendFiles()
                setSendMessage(false)
            }

        } else {
            setSendMessage(false)
        }

    }, [sendMessage, message, attachments, from, to, receiverPubs, senderKeys, gun, useVC, dataConn])

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            setSendMessage(true)
        }
    }

    const deleteMessage = (key) => {
        try { db.entity(messageEntity).remove(key) } catch (err) { console.log("message delete err: ", err) }
    }

    const undoMessage = (key) => {
        const removed = db.entity(messageEntity).findById(key)

        if (removed && to !== appState?.did && from === appState?.did) {
            const messageData = removed.getData()
            try {
                db.entity(messageEntity).update(key, { ...messageData, message: null, attachment: null, voice: null, edited: true })
            } catch (err) { console.log("message update err: ", err) }
            SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                // console.log("shared passphrase: ", passphrase)
                passphrase && SEA.encrypt({ ...messageData, message: null, attachment: null, voice: null, edited: true }, passphrase).then(encryptedMessage => {
                    SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                        signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                    })
                })
            })
        }
    }

    return (
        <Stack spacing={1}>
            <ListItem>
                <ListItemText
                    primary={nickname && nickname}
                    secondary={<Typography variant="subtitle2" sx={{ color: "text.secondary", wordBreak: "break-all" }} fontFamily={"JetBrains Mono"}>{to && to}</Typography>}
                />
                <Box sx={{ flexGrow: 1 }}></Box>
                <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
                    <P2PReceivedFiles filter={{ from: to }} />
                    {<Tooltip title={showMap ? "Hide Map" : "Show Map"} >
                        <ToggleButton size="small" onClick={() => setShowMap(ps => !ps)} value={"map"} selected={showMap} color={"primary"} sx={{ textTransform: "none", borderRadius: 2, ...(!smScreen && { px: 1 }) }}>
                            <MapIcon />
                            {!smScreen && <Typography sx={{ ml: 1 }} variant="caption">Map</Typography>}
                        </ToggleButton>
                    </Tooltip>}
                    <Tooltip title={useVC ? "Disable Visual Crypto" : "Enable Visual Crypto"} >
                        <ToggleButton size="small" selected={useVC} value={"useVC"} onClick={() => setUseVC(ps => !ps)} color="warning" sx={{ textTransform: "none", borderRadius: 2, ...(!smScreen && { px: 1 }) }}>
                            <Badge badgeContent={useVC ? <LockIcon fontSize="10px" /> : <LockOpenIcon fontSize="10px" />} anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}>
                                <MmsOutlinedIcon />
                            </Badge>
                            {!smScreen && <Typography sx={{ ml: 1 }} variant="caption">VC</Typography>}
                        </ToggleButton>
                    </Tooltip>
                </Stack>
            </ListItem>
            <Divider sx={{ bgcolor: isOnline ? "success.main" : "error.main" }} />
            <Box sx={{ p: 1, overflow: "auto" }} minHeight={600}>
                <Stack ref={chatAreaRef} id={`${to}-chat-area`} spacing={1} minHeight={500} maxHeight={500} sx={{ overflow: "auto", p: 2, scrollbarWidth: "thin", my: 1 }}>
                    {/* {messages && Object.keys(messages).length > 0 &&
                        Object.keys(messages).filter(key => messages[key]?.uuid && key).sort((a, b) => (messages[a]?.timestamp - messages[b]?.timestamp)).map((key, index) => (<MessageBubble key={key} keyIndex={key} index={index} message={messages[key]} from={from} deleteMessage={() => deleteMessage(key)} undoMessage={() => undoMessage(key)} />))} */}
                    {storedMessages.map((message, index) => (<MessageBubble key={message?.id} keyIndex={message?.id} index={index} message={message} from={from} deleteMessage={() => deleteMessage(message?.id)} undoMessage={() => undoMessage(message?.id)} />))}
                </Stack>
                <Divider sx={{ bgcolor: isOnline ? "success.main" : "error.main", my: 2 }} />
                <Stack spacing={1}>
                    <TextField onKeyDown={handleEnter} value={message} inputProps={{ maxLength: 250 }} onChange={(e) => setMessage(e.target.value)} placeholder="Message"
                        InputProps={{
                            sx: { pl: 1, pr: 0, borderRadius: 1 },
                            endAdornment: (
                                <Button sx={{ py: 2, borderRadius: 0 }} onClick={() => setSendMessage(true)} disabled={message.length === 0 && attachments.length === 0}>
                                    <Tooltip title="Send">
                                        <SendRoundedIcon color={message.length === 0 && attachments.length === 0 ? "text.disabled" : "primary"} />
                                    </Tooltip>
                                </Button>
                            )
                        }}
                    />
                    <>
                        {/* <Button color="info" startIcon={<LocationOnIcon />} onClick={startSharingLiveLocation}>Share My Live Location</Button> */}
                        {/* <Button color="error" onClick={stopSharingLiveLocation}>Stop Live Location</Button> */}
                        {/* <Stack direction={smScreen ? "column" : "row"} spacing={1} alignItems={"center"} component={"form"} method="post" action="https://vc.knuct.com/map" target="_blank"> */}
                        {/* <input type="text" placeholder="Another Wallet's Device ID" name="id" required="True" defaultValue={sharedDeviceId} hidden={true} /> */}
                        {/* <Button variant="contained" startIcon={<LocationOnIcon />} onClick={sendDeviceId} fullWidth>Share Location</Button> */}
                        {/* <input type="submit" value="Show on map" /> */}
                        {/* <Button variant="contained" startIcon={<PublicIcon />} type="submit" disabled={sharedDeviceId === ""} fullWidth>Show map</Button> */}
                        {/* </Stack> */}
                        <Stack sx={{ p: 1, border: 1, borderRadius: 3, borderColor: "divider" }}>
                            {location?.latitude && location?.longitude && myLocation?.latitude && myLocation?.longitude && <Typography align="center" color={"text.primary"} variant="subtitle2" fontWeight={"light"}>Accessing from {distance(location?.latitude, location?.longitude, myLocation?.latitude, myLocation?.longitude, "K").toFixed(3)} KM far.</Typography>}
                            <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Updated at: {location?.timestamp && format(new Date(location?.timestamp), "Ppp")} {`${location?.timestamp && "(" + formatDistanceToNow(new Date(location?.timestamp)) + " ago)"}`}</Typography>
                            <Typography align="center" color={"text.secondary"} variant="caption" fontWeight={"light"}>Latitude: {location?.latitude}, Longitude: {location?.longitude}</Typography>
                        </Stack>
                        {/* {showMap && <Card className="shadow" sx={{ borderRadius: 2 }}>
                            <DistanceMap id={`incoming_mapOf${to}`} maxHeight={300} data={{ [appState?.did]: myLocation, [to]: location }} />
                        </Card>} */}
                        {
                            <Dialog open={showMap} onClose={() => setShowMap(false)} PaperProps={{ sx: { borderRadius: 3 } }} fullWidth>
                                <ListItem>
                                    <ListItemText
                                        primary={nickname && nickname}
                                        secondary={<Typography variant="subtitle2" sx={{ color: "text.secondary", wordBreak: "break-all" }} fontFamily={"JetBrains Mono"}>{to && to}</Typography>}
                                    />
                                    <ListItemIcon>
                                        <Chip color="error" label={"close"} onClick={() => setShowMap(false)} />
                                    </ListItemIcon>
                                </ListItem>
                                <Card className="shadow" sx={{ borderRadius: 0, position: "relative" }}>
                                    <DistanceMap id={`mapOf${to}`} maxHeight={window.innerHeight - 150} data={{ [appState?.did]: myLocation, [to]: location }} />
                                </Card>
                            </Dialog>
                        }
                    </>

                    {/* <Dialog open={showMap} onClose={() => setShowMap(false)} fullScreen>
                        <Box sc={{ position: "relative", justifyContent: "center", alignItems: "center" }}>
                            <MapDistance id={`incoming_mapOf${to}`} data={{ [appState?.did]: myLocation, [to]: location }} setOpen={setShowMap} />
                        </Box>
                    </Dialog> */}

                    <Stack spacing={2}>
                        <Box sx={{ border: 2, borderRadius: 2, borderColor: files.length > 0 ? "primary.main" : "divider", borderStyle: "dashed", minWidth: "sm" }} component={'div'} {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <Typography sx={{ m: 6 }} variant='subtitle2' align='center' >Drag 'n' drop some files here, or click to select files</Typography>
                        </Box>
                        {files && files.length > 0 && <Stack maxHeight={500} spacing={0} sx={{ border: 1, borderRadius: 3, borderColor: "divider", overflow: "auto", scrollbarWidth: "thin" }}>
                            {files}
                            <Button startIcon={<DeleteTwoToneIcon />} onClick={() => { acceptedFiles.splice(0, acceptedFiles.length); setRefresh(ps => !ps) }} color='error' sx={{ p: 2 }}>
                                CLEAR INPUT FILE(S)
                            </Button>
                        </Stack>}
                        {error && <Alert severity='error'>{error}</Alert>}
                    </Stack>
                    {/* <BasicDropZone setInputFiles={setAttachments} settings={{ disabled: sendMessage, clearOption: true }} /> */}
                    {Array.isArray(attachments) && attachments.length > 0 && attachments[0]?.size > 250 * 1024 && <Alert severity="warning">
                        {`Max file sharing size limit in chat is 250KB, large files and if there are multiple files will be send in P2P fashion and it will not appear on chat. Max large file limit is ${formatBytes(MAX_FILE_SIZE)} `}
                    </Alert>}
                </Stack>
            </Box >
        </Stack>
    )
}