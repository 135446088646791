import { useState, useEffect, useContext, useReducer } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Button, ToggleButton, ToggleButtonGroup, Link } from '@mui/material';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';
import { Alert, AlertTitle, CircularProgress, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';

import HelpSetterContext from '../components/HelpWindow';

import * as sapi from '../api/ServerAPI';

const words = ['hill', 'bull', 'bag', 'window',
    'parrot', 'cloud', 'design', 'zebra',
    'book', 'cat', 'mobile', 'dog',
    'tree', 'computer', 'bottle', 'water']

const Views = {
    Index: 0,
    Loading: 1,
    Download: 2,
}

const Steps = {
    StartTempNode: { index: 0, text: 'Starting Temporary Node' },
    CreateWallet: { index: 1, text: 'Creating Wallet' },
}

const Statuses = {
    Initial: 0,
    Loading: 1,
    Success: 2,
    Error: 3
}

const stepStatusReducer = (state, action) => {

    if (action.reset) {
        return stepStatusInit()
    }

    const nState = [...state]
    switch (action.status) {
        case Statuses.Loading:
            nState[action.step].status = Statuses.Loading
            nState[action.step].cancel = action.cancel
            nState[action.step].error = null
            break;
        case Statuses.Success:
            nState[action.step].status = Statuses.Success
            nState[action.step].cancel = null
            break;
        case Statuses.Error:
            nState[action.step].status = Statuses.Error
            nState[action.step].error = action.error
            nState[action.step].cancel = null
            break;
    }
    return nState;
}

// initialize step state state with an array of 3 obj in the form 
// {status: (integer,default 0) current status of request,
//  cancel: (callback,default null) callback to cancel request
//  error: (object) {title,detail} error if some error occured during request}
const stepStatusInit = () => {
    return Object.values(Steps).map((step, index) => {
        step.index = index  // setting correct index 
        return { status: Statuses.Initial, cancel: null, error: null }
    });
}

export default function CreateWallet() {

    const [curView, setCurView] = useState(Views.Index)
    const [formFields, setFormFields] = useState({ passphrase: crypto.randomUUID(), seedWords: [] })
    const [sendRequest, setSendRequest] = useState(false)
    const [stepStatus, dispatchStepStatus] = useReducer(stepStatusReducer, null, stepStatusInit)
    //const [serverErrors, setServerErrors] = useState([])
    const [privshare, setPrivshare] = useState('')

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // setting page title
    useEffect(() => {
        window.document.title = 'WebWallet - Create Wallet'
        return () => { window.document.title = process.env.REACT_APP_NAME }
    }, [])

    useEffect(() => {
        if (!sendRequest)
            return

        setCurView(Views.Loading)
        startTempNode()

        return cancelRequests;
    }, [sendRequest])

    const cancelRequests = () => {
        stepStatus.forEach(status => {
            status.cancel && status.cancel()
        });
    }

    const backHandle = () => {
        cancelRequests()
        dispatchStepStatus({ reset: true })
        setCurView(Views.Index)
    }

    const startTempNode = () => {
        const step = Steps.StartTempNode.index
        let cancelRequest = () => { }

        sapi.startTempNode((cr) => { cancelRequest = cr })
            .then((response) => {
                // show start node step as successfull
                dispatchStepStatus({ step: step, status: Statuses.Success })
                createWallet()
            })
            .catch((err) => {
                catchError(err, step)
            })
        // show loading spinner of start node step
        dispatchStepStatus({ step: step, status: Statuses.Loading, cancel: cancelRequest })
    }

    const createWallet = () => {
        const step = Steps.CreateWallet.index
        let cancelRequest = () => { }

        sapi.createWallet(formFields.passphrase, formFields.seedWords, (cr) => { cancelRequest = cr })
            .then((response) => {
                // if response doesn't have necessory fields throw error.
                if (!response.data || !response.data.data || !response.data.data.privshare) {
                    throw sapi.createClientError(sapi.ErrorTypes.BadResponse,
                        'Server returned bad response, contact admin if this error repeats.', null)
                }
                // processing response
                setPrivshare(response.data.data.privshare)
                // show create wallet step as successfull
                dispatchStepStatus({ step: step, status: Statuses.Success })
                // show download private share view on success
                setCurView(Views.Download)
            })
            .catch((err) => {
                catchError(err, step)
            })
            .finally(() => {
                // resetting request state since this is the last step
                setSendRequest(false)
            })
        // show loading spinner of create wallet step
        dispatchStepStatus({ step: step, status: Statuses.Loading, cancel: cancelRequest })
    }

    const catchError = (err, step) => {
        // reseting request state
        setSendRequest(false)

        // if unknown error type throw it
        if (!err.name || err.name != 'SAPIError')
            throw err
        // errors from server (i.e response with error satus code)
        if (err.source == sapi.ErrorSource.Server) {
            // set error for the step
            dispatchStepStatus({
                step: step,
                status: Statuses.Error,
                error: {
                    title: err.data.error.title,
                    detail: err.data.error.detail
                }
            })
        }
        // error encountered with in browser
        else {
            // do nothing if request was cancelled
            if (err.type == sapi.ErrorTypes.RequestCanceled)
                return
            // if bad response from server show it as server error.
            else if (err.type == sapi.ErrorTypes.BadResponse) {
                // set error for the step
                dispatchStepStatus({
                    step: step,
                    status: Statuses.Error,
                    error: {
                        title: 'server error',
                        detail: err.message
                    }
                })
            }
            // generic snackbar error and go to index view.
            else {
                enqueueSnackbar(err.message, { variant: 'error' })
                backHandle()
                //setCurView(Views.Index)
            }
        }
    }

    return (
        <Box sx={{ bgcolor: 'background.default', display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center', py: '3em', px: '1em' }}>
                {curView == Views.Index && <IndexView formFields={formFields} setFormFields={setFormFields} setSendRequest={setSendRequest} />}
                {curView == Views.Loading && <LoadingView statuses={stepStatus} backHandle={backHandle} setSendRequest={setSendRequest} />}
                {curView == Views.Download && <DownloadView privshareLink={privshare} />}
            </Box>
        </Box>
    );
}

function DownloadView({ privshareLink }) {
    const setHelp = useContext(HelpSetterContext)
    const [agree, setAgree] = useState(false)
    const [download, setDownload] = useState(false)

    const downloadHandle = () => {
        sapi.privshare(privshareLink)
        setDownload(true)
    }
    return (
        <>
            <Typography variant="h4" sx={{ mb: 1, mx: 'auto', color: 'success.light' }}>
                Wallet Created
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 1, maxWidth: '26em', mx: 'auto', color: 'text.primary' }}>
                Your wallet was successfully created. Now to use this wallet you have to
                download the private share.
            </Typography>
            <Link component='button' onClick={() => { setHelp('privshare') }}> What is private share? </Link>
            <Box sx={{ my: 4 }}>
                <Typography variant='h6' sx={{ my: 1, textAlign: 'left' }}>Step 1</Typography>
                <Typography variant="subtitle1" sx={{ mb: 1, maxWidth: '28em', mx: 'auto', color: 'text.primary' }}>
                    Save the private share safely. Its your key to access your wallet so do not loose it.
                </Typography>
                <Link component='button' onClick={() => { setHelp('privshare') }}>How to store private share?</Link>
                <Alert severity="warning" sx={{ mt: 2, mb: 3, maxWidth: '36em', color: 'text.secondary' }}>
                    Do not share your private share with anyone.
                </Alert>
                <Button variant={download ? 'outlined' : "contained"} size="medium" className='shadow'
                    startIcon={<DownloadIcon />}
                    onClick={downloadHandle}
                    sx={{ py: 2, width: '18em' }}
                >
                    Download Private Share
                </Button>
            </Box>
            {
                download &&
                <Box sx={{ mt: 6 }}>
                    <Typography variant='h6' sx={{ my: 1, textAlign: 'left' }}>Step 2</Typography>
                    <Typography variant="subtitle1" sx={{ mb: 1, maxWidth: '28em', mx: 'auto', color: 'text.primary' }}>
                        Now open your wallet. You have to do a first access within 48 hours of
                        wallet creation or your wallet will be deleted.
                    </Typography>
                    <FormControlLabel control={<Checkbox checked={agree} onChange={(e, v) => { setAgree(v) }} />}
                        label={<Typography variant='body2'>I have downloaded the private share.</Typography>}
                        sx={{ mb: 2, mt: 3 }}
                    />
                    <br />
                    <Button variant="contained" size="large" className='shadow'
                        startIcon={<AutoAwesomeIcon />} disabled={!agree}
                        component={RouterLink} to='/accesswallet'
                        sx={{ py: 2, width: '17em' }}
                    >
                        Access Wallet
                    </Button>
                </Box>
            }
        </>
    )
}


function LoadingView({ statuses, backHandle, setSendRequest }) {
    const colors = ['text.disabled', 'text.secondary', 'success.light', 'error.light']
    return (
        <>
            <Typography variant="h4" sx={{ mb: 1, mx: 'auto' }}>
                Creating Wallet
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 3, maxWidth: '26em', mx: 'auto', color: 'text.secondary' }}>
                This will take some time. Please wait till the procedures completes.
            </Typography>
            <Box>
                {
                    Object.values(statuses).map((status, index) => {
                        if (status.error) {
                            return (
                                <Alert key={index} severity="error" icon={false} sx={{ justifyContent: 'center' }}>
                                    <AlertTitle>{status.error.title}</AlertTitle>
                                    {status.error.detail}
                                </Alert>
                            )
                        }
                        else return null
                    })
                }
            </Box>
            <Stack spacing={1} sx={{ pt: 4, pb: 5 }}>
                {
                    Object.values(Steps).map((step, index) => {
                        let status = statuses[step.index]
                        let color = colors[status.status]
                        let fw = status.status == 2 ? 'medium' : 'normal'

                        return (
                            <Box key={index}>
                                <Typography variant="subtitle1"
                                    sx={{ px: 4, color: color, fontWeight: fw, display: 'inline-block', position: 'relative' }}
                                >
                                    {step.text}
                                    <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                                        {status.status == 1 && <CircularProgress size={20} sx={{ ml: 2, color: 'inherit' }} />}
                                        {status.status == 2 && <CheckIcon sx={{ color: color }} />}
                                        {status.status == 3 && <CloseIcon sx={{ color: color }} />}
                                    </Box>
                                </Typography>
                            </Box>
                        );
                    })
                }
            </Stack>
            {
                // if any of the step has an error
                statuses.reduce((prev, status) => (prev || (status.status == 3)), false)
                    ?
                    <>
                        <Button variant="text" size="large" color='secondary'
                            onClick={backHandle}
                            startIcon={<ArrowBackIcon />} sx={{ py: 1, px: 4, mx: 2 }}
                        >
                            Back
                        </Button>
                        <Button variant="text" size="large"
                            onClick={() => { setSendRequest(true) }}
                            endIcon={<ReplayIcon />} sx={{ py: 1, px: 4, mx: 2 }}
                        >
                            Retry
                        </Button>
                    </>
                    :
                    <Button variant="text" size="large" color='error'
                        onClick={backHandle}
                        endIcon={<CloseIcon />} sx={{ py: 1, px: 4, mx: 2 }}
                    >
                        Cancel
                    </Button>
            }
        </>
    )
}

function Timer() {
    const [time, setTime] = useState(0)
    useEffect(() => {
        const intervalHandle = setInterval(() => {
            setTime((pTime) => pTime + 1)
        }, 1000)
        return () => {
            clearInterval(intervalHandle);
        }
    }, [])
    const min = parseInt(time / 60)
    const sec = time % 60
    return (
        <Box>
            <Typography sx={{ color: 'text.disabled' }}>Time elapsed</Typography>
            <Typography variant='h5' sx={{ my: 1, color: 'success.light' }}>
                {min ? (min + ' min') : ''} {sec} sec
            </Typography>
        </Box>
    );
}

function IndexView({ formFields, setFormFields, setSendRequest }) {
    const [passphrase, setPassphrase] = useState({
        value: formFields.passphrase ? formFields.passphrase : '',
        error: ''
    })
    const [selected, setSelected] = useState({
        value: formFields.seedWords ? formFields.seedWords : [],
        error: ''
    });

    const SetValue = (setFunc, vMap = null) => {
        return function (event) {
            let val = vMap ? vMap(event) : event.target.value
            setFunc((prevState) => {
                return { ...prevState, value: val }
            })
        }
    }

    const handleSelection = (event, newFormats) => {
        SetValue(setSelected, (v) => v)(newFormats.slice(-4))
    };

    const continueHandle = () => {
        if (validateData()) {
            setFormFields({
                passphrase: passphrase.value,
                seedWords: selected.value
            })
            setSendRequest(true)
        }
    }

    function validateData() {
        let hasError = false
        let [passphraseError, selectError] = ['', '']

        const SetError = (eMsg, setFunc) => {
            setFunc((prevState) => { return { ...prevState, error: eMsg } })
            if (Boolean(eMsg))
                hasError = true
        }
        if (passphrase.value.length == 0)
            passphraseError = 'You cannot leave this empty'
        else if (passphrase.value.length < 3)
            passphraseError = 'Passphrase should atleast be 3 characters long'

        if (selected.value.length < 4)
            selectError = 'Select exactly 4 words from the list'

        SetError(passphraseError, setPassphrase);
        SetError(selectError, setSelected);

        return !hasError
    }

    return (
        <>
            <Typography variant="h4" sx={{ mb: 3, mx: 'auto' }}>
                Create New Wallet
            </Typography>
            <Typography variant="body1" sx={{ mb: 5, maxWidth: '27em', mx: 'auto', color: 'text.secondary' }}>
                To initialize your wallet we need some random inputs from you. You don't
                have to memorize any of these inputs for later use.
            </Typography>

            <Typography sx={{ textAlign: 'left', my: 2 }}>Enter a passphrase</Typography>
            <TextField label="Passphrase" variant="outlined" fullWidth
                value={passphrase.value} onChange={SetValue(setPassphrase)}
                error={Boolean(passphrase.error)} helperText={Boolean(passphrase.error) ? passphrase.error : " "}
            />

            <Box sx={{ maxWidth: '36em', mt: 3 }}>
                <Typography sx={{ textAlign: 'left', my: 2 }}>Choose any 4 words from below.</Typography>
                <ToggleButtonGroup value={selected.value} onChange={handleSelection}
                    size='small' color="secondary"
                    sx={{ flexWrap: 'wrap', p: '3px', outline: Boolean(selected.error) ? '1px solid' : 'none', outlineColor: 'red' }}
                >
                    <Grid container spacing={1}>
                        {
                            words.map((word, index) => {
                                return (
                                    <Grid item key={index} xs={4} sm={3} md={3} lg={3}>
                                        <ToggleButtonCustom value={word} sx={{ px: 2 }} fullWidth>
                                            {word}
                                        </ToggleButtonCustom>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </ToggleButtonGroup>
                {
                    Boolean(selected.error) ?
                        <Typography variant='caption' component='div' color='error' sx={{ pl: 2, pt: 1, textAlign: 'left' }}>{selected.error}</Typography>
                        : null
                }
            </Box>
            <Stack spacing={3} sx={{ alignItems: 'center', mt: 3 }}>
                <Button variant="contained" size="large" className='shadow'
                    onClick={continueHandle}
                    endIcon={<ArrowForwardIcon />}
                    sx={{ py: 2, width: '17em' }}
                >
                    Continue
                </Button>
                <Button variant="text" size="large"
                    startIcon={<ArrowBackIcon />}
                    component={RouterLink} to='/'
                    sx={{ py: 2, width: '17em' }}
                >
                    Back
                </Button>
            </Stack>
        </>
    )
}

// ---------------------- styles -------------------------------------

/* custom style for word select toggle button */
const ToggleButtonCustom = styled(ToggleButton)(({ theme }) => ({
    textTransform: 'capitalize',
    '&.Mui-selected': {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.background.default,
        boxShadow: 'inset 0px 0px 0px 2px ' + theme.palette.secondary.light + 'a6',
    },
}));


/*
    useEffect(() => {
        if(formFields.passphrase.length == 0)
            return
        // var to hold request cancel callback
        let cancelRequest = ()=>{}
        console.log(formFields)
        setCurView(Views.Loading)
        
        sapi.create(formFields.passphrase,formFields.seedWords,(cr)=>{cancelRequest=cr})
        .then((response) => {
            // if response doesn't have necessory fields throw error.
            if(!response.data || !response.data.data || !response.data.data.privshare) {
                throw sapi.createClientError(sapi.ErrorTypes.BadResponse,
                    'Server returned bad response, contact admin if this error repeats.',null)
            }
            // processing response
            setPrivshare(response.data.data.privshare)
            // show download private share view on success
            setCurView(Views.Download)
        })
        .catch((err) => {
            // if unknown error type throw it
            if(!err.name || err.name != 'SAPIError')
                throw err
            // errors from server (i.e response with error satus code)
            if(err.source == sapi.ErrorSource.Server){
                setCurView(Views.Error)
                setServerErrors([{
                    title:err.data.error.title, 
                    detail:err.data.error.detail
                }])
            }   
            // error encountered with in browser
            else {
                // do nothing if request was cancelled
                if(err.type == sapi.ErrorTypes.RequestCanceled)
                    return
                // if bad response from server show it as server error.
                else if(err.type == sapi.ErrorTypes.BadResponse) {
                    setCurView(Views.Error)
                    setServerErrors([{
                        title:'server error', 
                        detail:err.message
                    }])
                }
                // generic snackbar error and go to index view.
                else {
                    enqueueSnackbar(err.message,{variant:'error'})
                    setCurView(Views.Index)
                }
            }
        })
        // retutn cleanup function that cancel the request.
        return cancelRequest;
    }, [formFields])

    */