/**
 * contains functions for manipulating privshare
 */

import md5 from 'md5';
import { isMobileDevice } from './common';

/**
 * gets the image data of a file using canvas API's context.getImageData()
 * @param {File} file - a file object of an image, usually taken from <input type='file'>
 * @param {function} callback - called on success with the image data(Uint8Array) of the 
 *      given image file as argument.
 * @param {function} errCallback - (optional) called on error with the error object that 
 *      was thrown
 * @throws {Error} - if callback is not a function
 */
export function getImageData(file, callback, errCallback = null) {
    if (typeof callback != 'function')
        throw new Error('callback should be a function')

    var cvs = document.createElement('canvas')
    var ctx = cvs.getContext('2d')
    var img = new Image()
    var imgData = []

    img.onload = function () {
        if (!isMobileDevice()) {
            cvs.width = img.width
            cvs.height = img.height
            ctx.drawImage(img, 0, 0)
            imgData = ctx.getImageData(0, 0, img.width, img.height)
            callback(new Uint8Array(imgData.data.buffer))
        } else {
            let mat = window?.cv.imread(img);
            imgData = mat?.data
            mat?.delete();
            callback(imgData)
        }
    }

    img.onerror = function (err) {
        if (typeof errCallback == 'function')
            errCallback(err)
    }

    img.src = URL.createObjectURL(file)
}

/**
 * removes the alpha channel from image data got from
 * value canvas API.
 * @param {Uint8Array} imgData - Image data with alpha value
 * @returns {Uint8Array} - Alpha removed image data
 * @throws {Error} - if image data is not of proper type or if
 *      image data's length is not a multiple of 4
 */
export function removeAlphaChannel(imgData) {
    if (!(imgData instanceof Uint8Array))
        throw new Error('image data should be of type Uint8Array')
    if (imgData.length % 4 !== 0)
        throw new Error('invalid length for image data, not a multiple of 4')

    const len = imgData.length - imgData.length / 4 // number of r,g,b values (no alpha)
    const newImgData = new Uint8Array(len)

    for (let i = 0, j = 0; i < imgData.length; i += 4, j += 3) {
        newImgData[j] = imgData[i]
        newImgData[j + 1] = imgData[i + 1]
        newImgData[j + 2] = imgData[i + 2]
    }
    return newImgData
}

/**
 * compute the md5 multi-hash of a string or buffer.
 * The multi-format code used for md5 is only in draft stage.
 * @param {String|Uint8Array} data - data whose hash is to be computed
 * @returns {Uint8Array} - Array of bytes (lenght=16+2)
 */
export function mh_md5(data) {
    const md5Code = 0xd5
    const hashLenght = 16 // in bytes
    let hash = md5(data, { asBytes: true })
    // adding hash length
    hash.unshift(hashLenght)
    // adding algo code (varint of md5Code is 0xd5 0x1)
    hash.unshift(0x1)
    hash.unshift(md5Code)
    return hash
}

/**
 * convert given data to string using multi-bases's base32 
 * encoding format.
 * @param {Uint8Array|Array} data - data to convert to base32
 * @returns {String} - base32 encoded data
 */
export function mb_base32(data) {
    const prefix = 'b'
    const alphabet = 'abcdefghijklmnopqrstuvwxyz234567'
    const bitsPerChar = 5
    return prefix + baseEncode(data, alphabet, bitsPerChar)
}


/*
export function uint8ArrayToUTF16_(array) {
    if(!array instanceof Uint8Array)
        throw new Error('Array should be of type Uint8Array')
    let str = ''
    let isFirstByte = null
    let prevByt = null
    for(byt of array) {
        // if its the first byte for 2 byte (16 bit)
        if(isFirstByte) {
            // if first byte is greater than & equal ro 0xdD7 (single utf-16 
            // symbols stops at 0xD800) encode it with 1 full utf-16 character
            // 0xD7xx where xx is the character
            if(byt >= 0xD6) {
                str += String.fromCharCode( 0xD7 + byt )
            }
            // if byte < 0xD7 then store it temporarly to encode it
            // with another byte to a utf-16 character
            else {
                prevByt = byt
                isFirstByte = false
            }
        }
        // second byte of 16 bit
        else {
            str += String.fromCharCode( prevByt + byt )
            isFirstByte = true
        }
    }
    return str
}

export function UTF16ToUint8Array_(str) {
    for(char of str) {
        let code = char.charCode;
    }
}
//*/

// problem with array of odd length
export function ui8ArrayToUTF16(array) {
    if (!array instanceof Uint8Array)
        throw new Error('require an array of type Uint8Array as parameter')
    if (array.length & 1)
        throw new Error('Array should be of even length')

    var str = ''
    var char
    for (let i = 0; i < array.length; i += 2) {
        char = (array[i] << 8) | array[i + 1]
        str += String.fromCharCode(char)
    }
    return str;
}

export function ui8ArrayFromUTF16(str) {
    if (typeof str !== 'string')
        throw new Error('require a string a parameter')

    var array = new Uint8Array(str.length * 2)
    var char
    for (let i = 0; i < str.length; i++) {
        char = str.charCodeAt(i)
        array[i * 2] = char >> 8
        array[i * 2 + 1] = char & 0xFF
    }
    return array
}

/**
 * baseEncode function of multi-base
 * source: js-multiformats/bases/base.js encode function
 * @param {*} data 
 * @param {*} alphabet - alphabet
 * @param {*} bitsPerChar 
 * @returns {string}
 */
function baseEncode(data, alphabet, bitsPerChar) {
    const pad = alphabet[alphabet.length - 1] === '='
    const mask = (1 << bitsPerChar) - 1
    let out = ''

    let bits = 0 // Number of bits currently in the buffer
    let buffer = 0 // Bits waiting to be written out, MSB first
    for (let i = 0; i < data.length; ++i) {
        // Slurp data into the buffer:
        buffer = (buffer << 8) | data[i]
        bits += 8

        // Write out as much as we can:
        while (bits > bitsPerChar) {
            bits -= bitsPerChar
            out += alphabet[mask & (buffer >> bits)]
        }
    }

    // Partial character:
    if (bits) {
        out += alphabet[mask & (buffer << (bitsPerChar - bits))]
    }

    // Add padding characters until we hit a byte boundary:
    if (pad) {
        while ((out.length * bitsPerChar) & 7) {
            out += '='
        }
    }

    return out
}