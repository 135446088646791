import axios from "axios"
import { startNode } from './ServerAPI'

const APIURL = process.env.REACT_APP_CAPI_URL
const capiAxios = axios.create()

// ----------------- interceptors ----------------
const responseInterceptor = capiAxios.interceptors.response.use(
    function (response) { return response },
    function (error) {
        console.log("From CAPI: ", error)
        const tError = transformError(error)
        if (error.response) {
            if (error.response.status === 409) {
                console.log("Starting node...")
                startNode()
                    .then((response) => {
                        if (response && response.status === 200)
                            window.location.reload();
                    })
                    .catch((err) => {
                        console.log('start node error', err)
                    })
            }
            else if (error.response.status === 401) {
                console.log("Not authentictad...")
                window.location.assign(process.env.PUBLIC_URL + '/?requireauth')
            }
        }
        return Promise.reject(tError)
    } // end error func
)

// --------------------------------------------------

function transformError(error) {
    if (error.message && error.message === 'Network Error')
        return createClientError("Network Error", 'Network error, see if you are connected.', error)
    else if (error.message && error.message.match('Request failed with status code 409'))
        return createClientError('Server Error', 'Wallet node not running, will restart shortly.', error)
    else if (error.message && error.message.match('Request failed with status code 401'))
        return createClientError('Server Error', 'Authentication Error.', error)
    else if (error.message && error.message.match('canceled'))
        return createClientError('Server Error', '', error)
    else if (error.message && error.message.match('Request failed with status code'))
        return createClientError('Server Error', 'Server Error.', error)
    else {
        return error
    }
}

export function createClientError(type, msg, err) {
    let e = new Error(msg)
    e.name = 'CAPIError'
    e.source = 'Client'
    e.type = type
    e.cause = err
    return e;
}

capiAxios.defaults.withCredentials = process.env.REACT_APP_CAPI_CREDENTIALS ? (process.env.REACT_APP_CAPI_CREDENTIALS === 'true') : true

// Check wether the user is registered or not
export function check(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/check', { signal: controller.signal })
}

// Initial Setup: Does the initial setup of IPFS and syncing the network nodes
export function start(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/start', { signal: controller.signal })
}

// Account Information: Retrieves the user account details
export function getAccountInfo(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getAccountInfo', { signal: controller.signal })
}

// Get Transaction Header Details: Summarize transaction information
export function getTransactionHeader(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getTransactionHeader', { signal: controller.signal })
}

// List of Tokens: Lists out all the tokens available in the user's wallet
export function viewTokens(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/viewTokens', { signal: controller.signal })
}

// List of Peers Online: Lists all your contacts currently available online
export function getOnlinePeers(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getOnlinePeers', { signal: controller.signal })
}

// View Contacts: Lists all contacts in your addressbook
export function getContactsList(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getContactsList', { signal: controller.signal })
}

// View Contacts online status: Lists all contacts in your addressbook and online status
export function getContactsListStatus(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getContactsListStatus', { signal: controller.signal })
}

// View Network Nodes: Add an easier and convenient name to your contacts
export function getNetworkNodes(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getNetworkNodes', { signal: controller.signal })
}

// Details for Dashboard: Lists all required details of user's wallet
export function getNftDashboard(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getNftDashboard', { signal: controller.signal })
}

// Details for Dashboard: Lists all required details of user's wallet
export function getDashboard(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getDashboard', { signal: controller.signal })
}

// Close Streams: To close all the streams open
export function p2pClose(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/p2pClose', { signal: controller.signal })
}

// Synchronize Network Nodes: To synchronize the DIDs of the systems, 
// so that the node will have an updated list of all the DIDs in the network.
export function sync(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/sync', { signal: controller.signal })
}

// Shutdown: Closes all application processes and exits
export function shutdown(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/shutdown', { signal: controller.signal })
}

// Transfer Tokens: Transfers token(s) from one wallet address to another
export function initiateTransaction(receiver, tokenCount, comment, type = 1, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/initiateTransaction', { receiver: receiver, tokenCount: tokenCount, comment: comment, type: 1 }, { signal: controller.signal })
}

// Get Transaction Details with Transaction ID: Details of a particular transaction like Quorum involved, token transferred, receiver details, time and more
export function getTxnDetails(transactionID, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getTxnDetails', { transactionID: transactionID }, { signal: controller.signal })
}

// Get Transaction Details with Date: Retrieves the details of all the transactions committed during the specified period
export function getTxnByDate(startDate, endDate, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getTxnByDate', { sDate: startDate, eDate: endDate }, { signal: controller.signal })
}

// Get Transaction Details with Comment: Retrieves the details of all the transactions committed with the specified comment
export function getTxnByComment(comment, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getTxnByComment', { comment: comment }, { signal: controller.signal })
}

// Get Transaction Details with Count: Retrieves the last specified count of transactions committed
export function getTxnByCount(count, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getTxnByCount', { txnCount: count }, { signal: controller.signal })
}

// Get Transaction Details with User's DID: Retrieves all the transactions made with the input DID
export function getTxnByDID(dID, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    // return capiAxios.post(APIURL + '/getTxnByDID', { did: dID }, { signal: controller.signal })
    return capiAxios({
        method: 'post',
        url: APIURL + '/getTxnByDID',
        data: { did: dID },
        signal: controller.signal,
    })
}

// Get Transaction Details by Range: Retrieves all the transactions made with the input DID
export function getTxnByRange(startRange, endRange, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getTxnByRange', { startRange: startRange, endRange: endRange }, { signal: controller.signal })
}

// Add Nicknames to contacts: Add an easier and convenient name to your contacts
export function addContact(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/addContact', formData, config)
}

export function addNickName(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/addNickName', formData, config)
}

// DID Creation: Creates a unique Decentralized IDentity
export function create(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/create', formData, config)
}

// Transfer NFT Tokens: Transfers NFT token(s) from one wallet address to another
export function initiateNftTransaction(buyer, nftToken, eKey, dKey, amount, comment, type = 2, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/initiateNftTransaction', { buyer: buyer, nftToken: nftToken, eKey: eKey, dKey: dKey, amount: amount, comment: comment, type: 1 }, { signal: controller.signal })
}

// NFT Assets: To get the NFT assets
export function getMyAssets(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getMyAssets', { signal: controller.signal })
}

// Add DP to contacts
export function addDp(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/addDp', formData, config)
}

// Get NFT Transaction Details with Transaction ID: Details of a particular transaction like Quorum involved, token transferred, receiver details, time and more
export function getNftTxnDetails(transactionID, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getNftTxnDetails', { transactionID: transactionID }, { signal: controller.signal })
}

// Get NFT Transaction Details with Date: Retrieves the details of all the transactions committed during the specified period
export function getNftTxnByDate(startDate, endDate, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getNftTxnByDate', { sDate: startDate, eDate: endDate }, { signal: controller.signal })
}

// Get NFT Transaction Details with Comment: Retrieves the details of all the transactions committed with the specified comment
export function getNftTxnByComment(comment, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getNftTxnByComment', { comment: comment }, { signal: controller.signal })
}

// Get NFT Transaction Details with Count: Retrieves the last specified count of transactions committed
export function getNftTxnByCount(count, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getNftTxnByCount', { txnCount: count }, { signal: controller.signal })
}

// Get NFT Transaction Details with User's DID: Retrieves all the transactions made with the input DID
export function getNftTxnByDID(dID, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    // return capiAxios.post(APIURL + '/getNftTxnByDID', { did: dID }, { signal: controller.signal })
    return capiAxios({
        method: 'post',
        url: APIURL + '/getNftTxnByDID',
        data: { did: dID },
        signal: controller.signal,
    })
}

// Get NFT Transaction Details by Range: Retrieves all the transactions made with the input DID
export function getNftTxnByRange(startRange, endRange, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getNftTxnByRange', { startRange: startRange, endRange: endRange }, { signal: controller.signal })
}

// Get NFT list
export function getNftTokens(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getNftTokens', { signal: controller.signal })
}

// Get NFT Details
export function viewMyNft(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/viewMyNft', formData, config, { signal: controller.signal })
}

//Get online status of DID
export function getOnlineStatus(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getOnlineStatus', formData, config, { signal: controller.signal })
}

// Add Key Value Item
export function addItem(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/addItem', formData, config)
}

// Remove Key Value Item
export function removeItem(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/removeItem', formData, config)
}

// Update Key Value Item
export function updateItem(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/updateItem', formData, config)
}

// Add Key Value Item
export function getItem(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getItem', formData, config)
}

// Get All Key Value Items
export function getAllItems(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getAllPairs', formData, config)
}

// Edit contact
export function editContact(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/editContact', formData, config)
}

// Delete contact
export function deleteContact(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/deleteContact', formData, config)
}

// Add stock data
export function addStockData(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/addStockData', formData, config)
}

// Remove stock data
export function removeStockData(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/removeStockData', formData, config)
}

// Get pvt share
export function getStockPvtShare(formData, config, cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.post(APIURL + '/getStockPvtShare', formData, config)
}

// Get all stock metadata
export function getAllStockData(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getAllStockData', { signal: controller.signal })
}

// Get sold stock metadata
export function getSoldStockRecords(cancelCallback = null) {
    const controller = new AbortController();
    if (typeof cancelCallback == 'function')
        cancelCallback(() => { controller.abort() })

    return capiAxios.get(APIURL + '/getSoldStockRecords', { signal: controller.signal })
}