import { useState , useContext, useEffect , useReducer } from 'react';
import { Box , Stack , Typography } from '@mui/material';
import { Button , Link } from '@mui/material';
import { Alert , AlertTitle , CircularProgress } from '@mui/material';
import { Link as RouterLink , useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';

import HelpSetterContext from '../components/HelpWindow';
import AppContext from '../AppContext';

import * as sapi from '../api/ServerAPI';
import * as privShareUtils from '../utils/privShare';
import { createChallengeResponse } from '../utils/nlss';

const Views = {
    Index : 0,
    Loading : 1
}

const Steps = {
    Challenge : {index: 0, text: 'Checking Wallet'},
    ChallengeResponse : {index: 1, text: 'Authenticating'},
    StartNode : {index: 2, text: 'Starting Wallet Node'},
    FetchData : {index: 3, text: 'Fetching Initial Data'},
}

const Statuses = {
    Initial : 0,
    Loading : 1,
    Success : 2,
    Error : 3
}

const stepStatusReducer = (state,action) => {

    if(action.reset) {
        return stepStatusInit()
    }

    const nState = [...state]
    switch(action.status) {
        case Statuses.Loading:
            nState[action.step].status = Statuses.Loading
            nState[action.step].cancel = action.cancel
            nState[action.step].error = null 
        break;
        case Statuses.Success:
            nState[action.step].status = Statuses.Success
            nState[action.step].cancel = null
        break;
        case Statuses.Error:
            nState[action.step].status = Statuses.Error
            nState[action.step].error = action.error
            nState[action.step].cancel = null
        break;
    }
    return nState;
}

// initialize step state state with an array of 3 obj in the form 
// {status: (integer,default 0) current status of request,
//  cancel: (callback,default null) callback to cancel request
//  error: (object) {title,detail} error if some error occured during request}
const stepStatusInit = () => {
    return Object.values(Steps).map((step,index) => {
        step.index = index  // setting correct index 
        return {status: Statuses.Initial, cancel: null, error: null}
    });
}

export default function AccessWallet() {
    const [appState,dispatchAppState] = useContext(AppContext)
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [curView,setCurView] = useState(Views.Index)
    const [file,setFile] = useState(null)
    const [sendRequest, setSendRequest] = useState(false)
    const [stepStatus, dispatchStepStatus] = useReducer(stepStatusReducer,null,stepStatusInit)

    var privShare=null;
    var hash=null;

    // setting page title
    useEffect( () => {
        window.document.title = 'WebWallet - Access Wallet'
        return () => {window.document.title = process.env.REACT_APP_NAME}
    },[])

    useEffect(() => {
        if(!sendRequest)
            return
        
        // fake login when on dev mode
        if (process.env.REACT_APP_FAKE_LOGIN && process.env.REACT_APP_FAKE_LOGIN === 'true' ) {
            hash = 'qoehrfn'
            privShare = new Uint8Array([1,2,3,4])
            login('xyxyxyx fake login');      //fake login (when server not running)
            return
        }
        if(file) {
            privShareUtils.getImageData(file,(imgData) => {
                privShare = privShareUtils.removeAlphaChannel(imgData)
                let hashRaw = privShareUtils.mh_md5(privShare)
                hash = privShareUtils.mb_base32(hashRaw) 
                setCurView(Views.Loading)
                askForChallenge(hash)
            },
            (err) => {
                enqueueSnackbar('choose a valid private share',{variant:'error'})
            });
        }
        else {
            enqueueSnackbar('No file selected',{variant:'warn'})
        }

        return cancelRequests;
    }, [sendRequest])


    const cancelRequests = () => {
        stepStatus.forEach(status => {
            status.cancel && status.cancel()
        });
    }

    const backHandle = () => {
        cancelRequests()
        dispatchStepStatus({reset:true})
        setCurView(Views.Index)
    }

    const login = (did) => {
        // show manual redirect button after 7 sec if not redirected
        setTimeout(()=>{
            try{
                document.getElementById('redirect-notice').style.visibility='visible';
            }catch{}
        },7000)

        // login and redirect to dashboard
        dispatchAppState({type:'login', did:did, hash:hash, privShare:privShare})
        history.push('/')
    }

    const askForChallenge = (hash) => { 
        let cancelRequest = ()=>{}

        sapi.authChallenge(hash,(cr)=>{cancelRequest=cr})
        .then((response) => {
            // if response doesn't have necessory fields throw error.
            if(!response.data || !response.data.data || !response.data.data.challenge) {
                throw sapi.createClientError(sapi.ErrorTypes.BadResponse,
                    'Server returned bad response, contact admin if this error repeats.',null)
            }
            // show challenge step as success
            dispatchStepStatus({step:Steps.Challenge.index, status:Statuses.Success})
            sendResponse(response.data.data.challenge)
        })
        .catch((err) => {
            catchError(err,Steps.Challenge.index)
        })
        // show loading spinner of challenge step
        dispatchStepStatus({step:Steps.Challenge.index, status:Statuses.Loading, cancel:cancelRequest})
    }

    const sendResponse = (challenge) => { 
        let cancelRequest = ()=>{}
        // calculate response
        let challengeResponse = createChallengeResponse(challenge,32,privShare)
        sapi.authResponse(challengeResponse, (cr)=>{cancelRequest=cr})
        .then((response) => {
            // show challenge response step as successfull
            dispatchStepStatus({step:Steps.ChallengeResponse.index, status:Statuses.Success})
            startNode()
        })
        .catch((err) => {
            catchError(err,Steps.ChallengeResponse.index)
        })
        // show loading spinner of challenge response step
        dispatchStepStatus({step:Steps.ChallengeResponse.index, status:Statuses.Loading, cancel:cancelRequest})
    }
    
    const startNode = () => { 
        let cancelRequest = ()=>{}

        sapi.startNode((cr)=>{cancelRequest=cr})
        .then((response) => {
            // show start node step as successfull
            dispatchStepStatus({step:Steps.StartNode.index, status:Statuses.Success})
            fetchInitData()
        })
        .catch((err) => {
            catchError(err, Steps.StartNode.index)
        })
        // show loading spinner of start node step
        dispatchStepStatus({step:Steps.StartNode.index, status:Statuses.Loading, cancel:cancelRequest})
    }
    
    const fetchInitData = () => { 
        let cancelRequest = ()=>{}

        sapi.walletData((cr)=>{cancelRequest=cr})
        .then((response) => {
            // if response doesn't have necessory fields throw error.
            if(!response.data || !response.data.data || !response.data.data.did) {
                throw sapi.createClientError(sapi.ErrorTypes.BadResponse,
                    'Server returned bad response, contact admin if this error repeats.',null)
            }
            // show fetch data step as successfull
            dispatchStepStatus({step:Steps.FetchData.index, status:Statuses.Success})
            // loging in
            login(response.data.data.did)
        })
        .catch((err) => {
            catchError(err, Steps.FetchData.index)
        })
        .finally(() => {
            // resetting request state since this is the last step
            setSendRequest(false)
        })
        // show loading spinner of fetch data step
        dispatchStepStatus({step:Steps.FetchData.index, status:Statuses.Loading, cancel:cancelRequest})
    }

    const catchError = (err, step) => {
        // reseting request state
        setSendRequest(false)

        // if unknown error type throw it
        if(!err.name || err.name != 'SAPIError')
            throw err
        // errors from server (i.e response with error satus code)
        if(err.source == sapi.ErrorSource.Server){
            // set error for the step
            dispatchStepStatus({
                step: step,
                status: Statuses.Error,
                error : {
                    title:err.data.error.title, 
                    detail:err.data.error.detail
                }
            })
        }   
        // error encountered with in browser
        else {
            // do nothing if request was cancelled
            if(err.type == sapi.ErrorTypes.RequestCanceled)
                return
            // if bad response from server show it as server error.
            else if(err.type == sapi.ErrorTypes.BadResponse) {
                // set error for the step
                dispatchStepStatus({
                    step: step,
                    status: Statuses.Error,
                    error : {
                        title:'server error', 
                        detail:err.message
                    }
                })
            }
            // generic snackbar error and go to index view.
            else {
                enqueueSnackbar(err.message,{variant:'error'})
                backHandle();
                //setCurView(Views.Index)
            }
        }
    }

    return(
        <Box sx={{bgcolor:'background.default', display: 'flex', height:'100%',justifyContent: 'center', alignItems: 'center'}}>
            <Box sx={{textAlign:'center',py:'3em',px:'1em'}}>
                {curView == Views.Index && <IndexView setFile={setFile} setSendRequest={setSendRequest}/>}
                {curView == Views.Loading && <LoadingView statuses={stepStatus} backHandle={backHandle} setSendRequest={setSendRequest}/>}
            </Box>
        </Box>
    );
}

function LoadingView({statuses,backHandle,setSendRequest}){
    const colors=['text.disabled','text.secondary','success.light','error.light']
    return (
        <>
        <Typography variant="h4" sx={{mb:1,mx:'auto'}}>
            Accessing Wallet
        </Typography>
        <Typography variant="subtitle1" sx={{mb:3,maxWidth:'26em',mx:'auto',color:'text.secondary'}}>
            This will take some time. Please wait till the procedures completes. 
        </Typography>
        <Box>
        {
            Object.values(statuses).map((status,index) => {
                if(status.error) {
                    return(
                        <Alert key={index} severity="error" icon={false} sx={{justifyContent:'center'}}>
                            <AlertTitle>{status.error.title}</AlertTitle>
                            {status.error.detail}
                        </Alert>
                    )
                }
                else return null
            })
        }
        </Box>
        <Stack spacing={1} sx={{pt:4,pb:5}}>
        {
            Object.values(Steps).map((step,index) => {
                let status = statuses[step.index]
                let color = colors[status.status]
                let fw = status.status == 2 ? 'medium' : 'normal'

                return (
                    <Box key={index}>
                        <Typography variant="subtitle1" 
                            sx={{px:4,color:color,fontWeight:fw,display:'inline-block',position:'relative'}}
                        >
                            {step.text}
                            <Box sx={{position:'absolute',right:0,top:0}}>
                                {status.status == 1 && <CircularProgress size={20} sx={{ml:2,color:'inherit'}}/>}
                                {status.status == 2 && <CheckIcon sx={{color:color}} />}
                                {status.status == 3 && <CloseIcon sx={{color:color}} />}
                            </Box>
                        </Typography>
                    </Box>
                );
            })
        }
        </Stack>
        {
            // if any of the step has an error
            statuses.reduce((prev,status) => (prev || (status.status==3)),false)
            ?
            <>
            <Button variant="text" size="large" color='secondary'
                onClick={backHandle}
                startIcon={<ArrowBackIcon/>} sx={{py:1,px:4,mx:2}}
            >
                Back
            </Button>
            <Button variant="text" size="large" 
                onClick={()=>{setSendRequest(true)}}
                endIcon={<ReplayIcon/>} sx={{py:1,px:4,mx:2}}
            >
                Retry
            </Button>
            </>
            :
            <Button variant="text" size="large" color='error' 
                onClick={backHandle}
                endIcon={<CloseIcon/>} sx={{py:1,px:4,mx:2}}
            >
                Cancel
            </Button>
        }
        {   
            // if all steps were successfull
            statuses[2].status == 2
            ?
            <Typography id='redirect-notice' variant='body2' 
                sx={{pt:3,color:'text.secondary',visibility:'hidden'}}
            >
                Click <Link component={RouterLink} to='/'>here</Link> if you 
                haven't automatically redirect to dashboard.
            </Typography>
            :
            null
        }
        </>
    )
}

function IndexView({setFile,setSendRequest}) {
    const [loading, setLoading] = useState(false)
    const fileOpenHandle = (e) => {
        if(e.target.files.length == 0)
            return
        setFile(e.target.files[0])
        setSendRequest(true);
        setLoading(true)
    }

    return (
        <>
            <Typography variant="h4" sx={{mb:1,mx:'auto'}}>
                Access Wallet
            </Typography>
            <Typography variant="subtitle1" sx={{mb:3,maxWidth:'26em',mx:'auto',color:'text.secondary'}}>
                To access your wallet upload your private share. 
            </Typography>

            <Stack spacing={3} sx={{alignItems:'center',mt:3}}>
                <input type='file' id="open-privshare" 
                    accept='image/png'
                    onChange={fileOpenHandle}
                    style={{width:0,height:0,opacity:0}}
                    
                />
                <Button variant="contained" size="large"  className='shadow'
                    component='label' htmlFor='open-privshare'
                    startIcon={loading ? <CircularProgress color='inherit' size={24}/>: <BubbleChartOutlinedIcon/>}
                    sx={{py:2,width:'17em'}}
                >
                    Choose Private Share
                </Button>
                <Button variant="text" size="large"
                    startIcon={<ArrowBackIcon/>}
                    component={RouterLink} to='/'
                    sx={{py:2,width:'17em'}}
                >
                    Back
                </Button>
            </Stack>
        </>
    )
}