import { useState, useEffect, useContext } from "react";
import { Alert, Stack, Switch, Typography, Skeleton } from '@mui/material';
import { IconButton, Button } from '@mui/material';
import { callRecordings } from "../../utils/common";
import { recordingsStore } from "../../utils/localforageInstances";

import AppContext from "../../AppContext";

import FilePreview from '../../components/FilePreview'

import RefreshIcon from '@mui/icons-material/Refresh';

export default function CallRecordings() {
    const [appState, appStateDispatch] = useContext(AppContext)
    const [data, setData] = useState({})
    const [recs, setRecs] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const files = recs
        .sort((a, b) => ((a.arrivedAt < b.arrivedAt) ? 1 : (a.arrivedAt > b.arrivedAt) ? -1 : 0))
        .map((x, index) => (
            <Stack key={x.file.name + index} spacing={0}>
                <FilePreview file={Object.assign(x.file, { preview: x?.preview, from: x?.from, to: x?.to })} />
            </Stack>
        ));

    useEffect(() => {
        const tempRecs = []
        setLoading(true)
        recordingsStore.iterate(function (value, key, iterationNumber) {
            tempRecs.push(value)
        }).then(function () {
            console.log('Iteration has completed');
            setRecs(tempRecs)
            setLoading(false)
        }).catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }, [refresh])

    const toggleCallRecordingClick = () => {
        appStateDispatch({ type: 'toggleCallRecord' })
    }

    return (
        <Stack spacing={1} sx={{ wordBreak: "break-all" }} >
            {files && files.length > 0 && <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"} >
                <Typography variant="subtitle1" fontWeight={"bold"} color={"text.secondary"}>Call recordings • {files.length}</Typography>
                <IconButton size="small" onClick={() => setRefresh(ps => !ps)}><RefreshIcon fontSize="small" /></IconButton>
            </Stack>}
            <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 2, py: 1, border: 1, borderRadius: 3, borderColor: "divider" }}>
                <Typography variant="subtitle2">{`Call recordings ${appState?.callRecording === 'true' ? "enabled" : "disabled"}`}</Typography>
                <Switch
                    color="info"
                    checked={appState?.callRecording === 'true'}
                    onChange={toggleCallRecordingClick}
                />
            </Stack>
            {files && files.length > 0 && <Stack maxHeight={500} spacing={0} sx={{ border: 1, borderRadius: 3, borderColor: "divider", overflow: "auto", scrollbarWidth: "thin" }}>
                {files}
                <Button color="error" size="large" onClick={() => { recordingsStore.clear(); setRecs([]) }} sx={{ p: 2 }}>Delete logs</Button>
            </Stack>}
            {!loading && files && files.length === 0 &&
                <Typography align="center" variant="subtitle1" fontWeight={"bold"} color={"text.disabled"}>No recordings found!</Typography>
            }
            {error && <Alert severity="error">{error}</Alert>}
            {loading && [...Array(3)].map((_, index) => (<Skeleton key={index} variant="rounded" height={60} />))}
        </Stack>
    )
}