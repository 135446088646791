import { useEffect, useState } from "react";
import { socket } from "../utils/socket"

const useServices = (did) => {
    const [data, setData] = useState({ peers: [], fingerprints: [], gunPublicKeys: {}, peerIds: {}, liveLocations: {}, visible: false })

    useEffect(() => {
        console.log("---useServices---")

        socket.on("connect", () => {
            console.log("🟢 socket connected! %s", socket?.id)
        });

        socket.on("peers", (newPeers) => {
            setData(ps => ({ ...ps, peers: newPeers }))
            // console.log("🌐 PEERS UPDATED!")
        })

        socket.on("fingerprints", (newFingerprints) => {
            setData(ps => ({ ...ps, fingerprints: newFingerprints }))
            // console.log("🕵🏼 FINGERPRINTS UPDATED!")
        })

        socket.on("gunPublicKeys", (newGunPublicKeys) => {
            setData(ps => ({ ...ps, gunPublicKeys: newGunPublicKeys }))
            // console.log("🔫 FINGERPRINTS UPDATED!")
        })

        socket.on("peerIds", (newPeerIds) => {
            setData(ps => ({ ...ps, peerIds: newPeerIds }))
            if (did) {
                const { [did]: x } = newPeerIds
                if (x) {
                    setData(ps => ({ ...ps, visible: true }))
                }
            }
            // console.log("🌏 PEER IDS UPDATED!", newPeerIds?.[did])
        })

        socket.on("liveLocations", (newLiveLocations) => {
            setData(ps => ({ ...ps, liveLocations: newLiveLocations }))
            // console.log("🌏 PEER IDS UPDATED!")
        })

        socket.on("disconnect", () => {
            console.log("🔴 socket disconnected!")
        })

        socket.on("error", (error) => {
            console.log(error)
            socket.off()
            // socket.disconnect()
        })

        return () => {
            socket.off()
            console.log("🔴 socket disconnected!")
        }

    }, [did])

    return data;
};

export default useServices;