import { defineEntity, createClientDb } from "@clientdb/core";

export const messageEntity = defineEntity({
    name: "messages",
    fields: ["id", "uuid", "message", "voice", "attachment", "vc", "from", "to", "sendOn", "timestamp", "edited"],
});

export const receivedFileEntity = defineEntity({
    name: "receivedFiles",
    fields: ["id", "file", "from", "to", "sendOn", "timestamp", "arrivedAt"],
});

export const db = createClientDb([messageEntity, receivedFileEntity]);