import { useState, useEffect, useContext, useRef } from "react";
import { Alert, Box, Divider, Chip, Button, Badge, Stack, Grid, Typography, TextField, Avatar, Link, Dialog, Tooltip, Skeleton, DialogContent, DialogActions, ToggleButton, LinearProgress, Fab } from '@mui/material';
import { Card, CardMedia, CardActions, IconButton } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Menu, MenuItem } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format, formatDistanceToNow } from 'date-fns';

import { Splitter, SplitterPanel } from 'primereact/splitter';

import { Howl } from "howler";
import RecordRTC from "recordrtc";
import { VideoStreamMerger } from "video-stream-merger";

import EmojiPicker from 'emoji-picker-react';

import SEA from "gun/sea";

import AppContext from '../AppContext';
import FilePreview from "../components/FilePreview";

import CallRecordings from "../components/Communications/CallRecordings";
import CallHistory from "../components/Communications/CallHistory";
import P2PReceivedFiles from "../components/P2PReceivedFiles";

import ShowAddress from "../components/ShowAddress";

import { mypeer } from "../utils/peer";
import { formatBytes, scrollToBottom } from "../utils/common";
import { historyStore, updateHistory, recordingsStore } from "../utils/localforageInstances";
import { vc } from "../utils/vc";
import { db, messageEntity } from "../utils/clientdb";

import useServices from "../hooks/useServices";
import useContacts from "../hooks/useContacts"
import useStoredMessages from "../hooks/useStoredMessages";

import dialtone from '../assets/audio/outgoing_sound.mp3';

import { useDropzone } from 'react-dropzone';

import { Geolocation } from '@capacitor/geolocation';

import DistanceMap from "../components/DistanceMap";

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import HistoryIcon from '@mui/icons-material/History';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SearchIcon from '@mui/icons-material/Search';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ContactsIcon from '@mui/icons-material/Contacts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import StopIcon from '@mui/icons-material/Stop';
import UndoIcon from '@mui/icons-material/Undo';
import BlockIcon from '@mui/icons-material/Block';
import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';

const constraints = {
    audio: {
        noiseSuppression: { exact: true },
        echoCancellation: { exact: true },
    },
    video: {
        width: { exact: 320 },
        height: { exact: 240 },
        frameRate: 16
    }
}

const outgoing_sound = new Howl({
    src: [dialtone],
    autoplay: false,
    onload: () => {
        console.log("Loaded!")
    },
    onend: () => {
        console.log('Finished!');
    }
});

const MAX_FILE_SIZE = 500 * 1024 * 1024
const ALLOW_INDIVIDUAL_CALL_RECORDINGS = true
const ALLOW_COMBINED_CALL_RECORDINGS = false
const ALLOW_WATERMARK_ON_CALL_RECORDINGS = false

const menuContents = [
    { label: "Dashboard", icon: <DashboardIcon fontSize="small" /> },
    { label: "Transactions", icon: <SwapVertIcon fontSize="small" /> },
    { label: "Contacts", icon: <ContactsIcon fontSize="small" /> },
]

export default function KnuctTalk() {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [appState, appStateDispatch] = useContext(AppContext)
    const [error, setError] = useState("")
    const data = useServices(appState?.did)
    const { contacts, isLoading } = useContacts()
    const [nicknames, setNicknames] = useState({})
    const [searchKeyword, setSearchKeyword] = useState("")
    const [selectedPeer, setSelectedPeer] = useState("")
    const [selectedFingerprint, setSelectedFingerprint] = useState("")
    const [selectedChat, setSelectedChat] = useState("")
    const [fingerprint, setFingerprint] = useState("")
    const [gunAuth, setGunAuth] = useState({})
    const [streams, setStreams] = useState({ localStream: undefined })
    const [isCamera, setIsCamera] = useState(true)
    const [showCallRecordings, setShowCallRecordings] = useState(false)
    const [showCallHistory, setShowCallHistory] = useState(false)
    const [myLocation, setMyLocation] = useState({})
    const [folder, setFolder] = useState('1');
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const playOutgoingSoundButton = useRef()
    const stopOutgoingSoundButton = useRef()

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleFolderChange = (event, newValue) => {
        setSelectedChat("")
        setFolder(newValue);
    };

    const peer = mypeer; // || appState.peer;
    const gun = appState.gun;
    const did = appState.did;

    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleSearchKeyword = (e) => {
        setSearchKeyword(e.target.value)
    }

    const toggleVideo = () => {
        console.log("toggle video")
        if (streams.localStream?.getVideoTracks()[0]) {
            streams.localStream.getVideoTracks()[0].enabled = !streams.localStream?.getVideoTracks()[0].enabled;
            console.log("toggling video")
        } else {
            navigator?.mediaDevices && navigator.mediaDevices.getUserMedia(constraints)
                .then((stream) => {
                    setIsCamera(true)
                    setStreams(ps => ({ ...ps, localStream: stream }))
                }).catch(err => {
                    setIsCamera(false)
                    console.log("MEDIA DEVICE USER MEDIA ERROR %O", err)
                    setError(err?.message)
                    // navigator?.mediaDevices && navigator.mediaDevices.getDisplayMedia()
                    //     .then((stream) => {
                    //         setStreams(ps => ({ ...ps, localStream: stream }))
                    //     }).catch(error => {
                    //         console.log("MEDIA DEVICE DISPLAY MEDIA ERROR %O", error)
                    //     })
                })
        }
    }

    const toggleAudio = () => {
        console.log("toggle audio")
        if (streams.localStream?.getAudioTracks()[0]) {
            streams.localStream.getAudioTracks()[0].enabled = !streams.localStream.getAudioTracks()[0].enabled;
            console.log("toggling audio")
        }
    }

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    useEffect(() => {
        let watchId = Geolocation.watchPosition({ enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }, (response, error) => {
            if (!error && response) {
                const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = response.coords

                const liveLocation = {
                    ...(appState.did && { did: appState.did }),
                    accuracy: accuracy,
                    altitude: altitude,
                    altitudeAccuracy: altitudeAccuracy,
                    heading: heading,
                    latitude: latitude,
                    longitude: longitude,
                    speed: speed,
                    timestamp: new Date(response.timestamp)
                }

                setMyLocation(liveLocation)
            }
        })

        return () => {
            Geolocation.clearWatch(watchId)
        }
    }, [appState.did])

    useEffect(() => {
        const authChecker = setInterval(() => {
            console.log("🔑 1. AUTH CHECKING...")
            try {
                if (window.localStorage) {
                    const auth = window.localStorage.getItem("gunAuth")
                    const _fingerprint = window.localStorage.getItem("fingerprint")
                    if (_fingerprint) {
                        setFingerprint(_fingerprint)
                        console.log("🕵🏼‍♂️ fingerprint: %s", _fingerprint)
                    }
                    if (auth) {
                        const authObject = JSON.parse(auth)
                        console.log("🔥 OLD AUTH")
                        setGunAuth(ps => ({ ...ps, ...(authObject) }))
                        clearInterval(authChecker)
                    }
                }

            } catch (e) {
                console.log("error: ", e)
            }
        }, 1 * 1000);

        return () => {
            clearInterval(authChecker)
        }

    }, [])

    useEffect(() => {
        try {
            navigator?.mediaDevices && navigator.mediaDevices.getUserMedia(constraints)
                .then((stream) => {
                    setIsCamera(true)
                    setStreams(ps => ({ ...ps, localStream: stream }))
                }).catch(err => {
                    setIsCamera(false)
                    console.log("MEDIA DEVICE USER MEDIA ERROR %O", err)
                    setError(err?.message)
                    // navigator?.mediaDevices && navigator.mediaDevices.getDisplayMedia()
                    //     .then((stream) => {
                    //         setStreams(ps => ({ ...ps, localStream: stream }))
                    //     }).catch(error => {
                    //         console.log("MEDIA DEVICE DISPLAY MEDIA ERROR %O", error)
                    //     })
                })
        } catch (e) { console.log("💔 ERROR: F", e) }
    }, [])

    useEffect(() => {
        console.log("ONLINE STATUS UPDATE!")
        const onlineInterval = setInterval(() => {
            const message = { isOnline: true, updatedOn: new Date().getTime() }
            did && SEA.sign(message, gunAuth).then(signedMessage => {
                signedMessage && gun.get(did).get("isOnline").put(signedMessage)
            })

        }, 5 * 1000);

        return () => {
            clearInterval(onlineInterval)
            const message = { isOnline: false, updatedOn: new Date().getTime() }
            did && SEA.sign(message, gunAuth).then(signedMessage => {
                signedMessage && gun.get(did).get("isOnline").put(signedMessage).then(response => {
                    console.log("🔴 OFFLINE %O", message)
                })
            })
        }
    }, [did, gun, gunAuth])

    useEffect(() => {

        return () => {
            if (streams?.localStream) {
                streams?.localStream?.getTracks().forEach((track) => {
                    console.log("track:", track)
                    track.stop();
                });

                console.log("LOCAL STREAM GOT CLEARED!")
            }
        }
    }, [streams])

    useEffect(() => {
        if (contacts && contacts.length > 0) {// console.log("contacts: ", contacts)
            const tempNicknames = {}
            contacts
                .filter(x => x?.did && x?.onlineStatus && x)
                .map(x => {
                    try {
                        let dpObj = JSON.parse(x?.dp)
                        tempNicknames[x.did] = { nickname: x?.nickname, dp: dpObj }
                    } catch (e) {
                        tempNicknames[x.did] = { nickname: x?.nickname, dp: { format: "png", base64: "" } }
                    }
                    return { nickname: x?.nickname, dp: x?.dp }
                })
            setNicknames(tempNicknames)
            // console.log("tempNicknames:", tempNicknames)
        }
    }, [contacts])

    const onlineDIDs = contacts
        .filter(x => x?.did && x?.onlineStatus === 'online' && x?.nickname.toLowerCase().includes(searchKeyword.toLowerCase()) && x)
        .map(x => x.did)

    const contactDIDs = contacts
        .filter(x => x?.did && x?.onlineStatus && x?.nickname.toLowerCase().includes(searchKeyword.toLowerCase()) && x)
        .map(x => x.did)

    useEffect(() => {
        if (!onlineDIDs.includes(selectedChat)) {
            setSelectedChat("")
        }
    }, [onlineDIDs, selectedChat])

    return (
        <Box minHeight={dimensions.height - 120} maxHeight={dimensions.height - 120} sx={{ border: 0 }}>
            <button id="dialtone_play" ref={playOutgoingSoundButton} onClick={() => outgoing_sound.play()} style={{ display: "none" }}>dialtone play</button>
            <button id="dialtone_stop" ref={stopOutgoingSoundButton} onClick={() => outgoing_sound.stop()} style={{ display: "none" }}>dialtone stop</button>
            <Box>
                <Splitter layout={smScreen ? "vertical" : "horizontal"}>
                    <SplitterPanel key={"p1"} size={20}>
                        <Stack key={"xxxx"} sx={{ border: 1, borderColor: "divider", minHeight: dimensions.height - 120, maxHeight: dimensions.height - 120, overflow: "auto", scrollbarWidth: "thin", scrollBehavior: "smooth", ...(smScreen && selectedChat && { display: "none" }) }} >
                            <Card component={ListItem} sx={{ borderRadius: 0, p: 2 }}>
                                {/* <ListItemIcon>
                            <IconButton
                            // onClick={handleMenuClick}
                            >
                                <MenuRoundedIcon />
                            </IconButton>
                        </ListItemIcon> */}
                                <TextField size="small" variant="filled" placeholder="search..." fullWidth hiddenLabel InputProps={{
                                    disableUnderline: true,
                                    sx: { pl: 1, borderRadius: 10, border: 0, borderColor: "divider", pr: 1 },
                                    startAdornment: (
                                        <SearchIcon sx={{ color: "text.disabled", mx: 1 }} />
                                    ),
                                    endAdornment: (
                                        <Stack spacing={1} justifyContent={"flex-end"} alignItems={"center"} direction={"row"}>
                                            <IconButton color="error" size="small" sx={{ ml: 1, display: searchKeyword.length === 0 ? "none" : "" }} onClick={() => setSearchKeyword("")} disabled={searchKeyword.length === 0}>
                                                <Tooltip title="clear">
                                                    <ClearRoundedIcon color={searchKeyword.length === 0 ? "text.disabled" : "error"} />
                                                </Tooltip>
                                            </IconButton>
                                            <Tooltip title="Call Recordings">
                                                <IconButton size="small" onClick={() => setShowCallRecordings(true)}>
                                                    <VideoLibraryIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Call History">
                                                <IconButton size="small" onClick={() => setShowCallHistory(true)}>
                                                    <HistoryIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    )
                                }}
                                    value={searchKeyword} onChange={handleSearchKeyword}
                                />
                            </Card>

                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleMenuClose}
                            >
                                {menuContents.map((x, index) => (
                                    <MenuItem key={index} onClick={handleMenuClose} sx={{ minWidth: 320 }}>
                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                            {x.icon}
                                            <Typography variant="subtitle2">{x.label}</Typography>
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </Menu>

                            {false && did && <Stack direction={"row"} spacing={2} sx={{ pt: 2, pb: 1, px: 2 }} alignItems={"center"} justifyContent={"center"}>
                                {/* <FiberManualRecordIcon color={"success"} /> */}
                                <Avatar key={"avatar"} sx={{ border: 2, borderColor: "#32FF6A" }}><AccountCircleIcon /></Avatar>
                                <Typography key={"did"} variant="subtitle1" sx={{ color: "text.secondary", wordBreak: "break-all" }} fontWeight={"bold"} fontFamily={"JetBrains Mono"}>{did}</Typography>
                            </Stack>}

                            <Divider sx={{ bgcolor: (!data?.visible && !mypeer?._id) ? "error.main" : "success.main" }} />

                            {false && <TabContext value={folder}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleFolderChange}>
                                        <Tab key={1} label="Personal" value="1" />
                                        {/* <Tab key={2} label="All" value="2" disabled={false} /> */}
                                    </TabList>
                                </Box>
                            </TabContext>}

                            <List sx={{ minHeight: dimensions.height - 207, maxHeight: dimensions.height - 207, overflow: "auto", scrollbarWidth: "thin" }}>
                                {!isLoading && contacts.length > 0 && data?.gunPublicKeys && Object.keys(data?.gunPublicKeys).length > 0 && Object.keys(data?.gunPublicKeys)
                                    .filter(key => key !== did && key)
                                    .filter(x => onlineDIDs.includes(x) && x)
                                    .map((key, index) => (
                                        <Stack key={index}>
                                            <Tooltip key={key} title={key && key} arrow placement="top">
                                                <ListItemButton disabled={!onlineDIDs.includes(key)} key={index} onClick={() => setSelectedChat(key)} selected={selectedChat === key} divider
                                                    sx={{
                                                        wordBreak: "break-all",
                                                        // border: 1,
                                                        // borderRadius: 3,
                                                        // borderColor: "divider",
                                                        // mb: 1,
                                                        // mx: 1,
                                                        // ...(folder === '1' ? contactDIDs.includes(key) ? {} : { display: "none" } : {}),
                                                    }}>
                                                    <ListItemAvatar>
                                                        <Avatar alt={""} src={nicknames?.[key]?.dp && `data:image/${nicknames?.[key]?.dp?.format};base64,${nicknames?.[key]?.dp?.base64}`} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={nicknames?.[key]?.nickname ? nicknames?.[key]?.nickname : ""}
                                                    // secondary={<Typography variant="body2" color={"text.secondary"} fontFamily={"JetBrains Mono"}>{key}</Typography>}
                                                    />
                                                    <ListItemIcon>
                                                        <Typography variant="body2" fontWeight={"bold"} fontFamily={"JetBrains Mono"} color={onlineDIDs.includes(key) ? "success.main" : "error.main"}>{onlineDIDs.includes(key) ? "ONLINE" : "OFFLINE"}</Typography>
                                                    </ListItemIcon>
                                                </ListItemButton>
                                            </Tooltip>
                                            {/* <Divider variant="inset" sx={{ ...(folder === '1' ? contactDIDs.includes(key) ? {} : { display: "none" } : {}) }} /> */}
                                        </Stack>
                                    ))}
                                {(isLoading || (data?.gunPublicKeys && Object.keys(data?.gunPublicKeys).length === 0)) &&
                                    <LinearProgress sx={{ height: 2 }} />
                                }
                                {(!isLoading && data && Object.keys(data?.gunPublicKeys)
                                    .filter(x => x !== did && x)
                                    .filter(x => onlineDIDs.includes(x) && x).length === 0) &&
                                    <Alert severity="warning" sx={{ borderRadius: 0, my: 1 }}>Nobody from your contacts is online</Alert>
                                }
                            </List>
                        </Stack>
                    </SplitterPanel>

                    <SplitterPanel key={"p2"} size={80} minSize={70}>
                        <Stack sx={{ border: 1, ...(false && !smScreen && { borderLeft: 0 }), borderColor: "divider", minHeight: dimensions.height - 120, maxHeight: dimensions.height - 120, overflow: "auto", scrollbarWidth: "thin", ...(selectedChat === "" && smScreen && { display: "none" }) }}>
                            {contacts.length > 0 && <Stack>
                                {data?.gunPublicKeys && Object.keys(data?.gunPublicKeys).length > 0 && Object.keys(data?.gunPublicKeys)
                                    .filter(x => x !== did && x)
                                    .filter(x => onlineDIDs.includes(x) && x)
                                    .map((c, index) => (
                                        <Box key={index} sx={{ display: selectedChat === c ? "" : "none" }}>
                                            <ChatConnection setSelectedChat={setSelectedChat} myLocation={data?.liveLocations?.[appState?.did]} location={data?.liveLocations?.[c]} nickname={nicknames?.[c]?.nickname} from={did} to={c} senderKeys={gunAuth} receiverPubs={data?.gunPublicKeys?.[c]} senderPeer={peer && peer} receiverPeerId={data?.peerIds && Object.keys(data?.peerIds).length > 0 && data?.peerIds?.[c]} streams={streams} isCamera={isCamera} toggleVideo={toggleVideo} toggleAudio={toggleAudio} dimensions={dimensions} playOutgoingSoundButton={playOutgoingSoundButton} stopOutgoingSoundButton={stopOutgoingSoundButton} />
                                        </Box>
                                    ))}
                            </Stack>}
                            {((contacts.length === 0 && isLoading) || (data?.gunPublicKeys && Object.keys(data?.gunPublicKeys).length === 0)) && <Skeleton animation="wave" variant="rectangle" height={dimensions.height - 118} />}
                        </Stack>
                    </SplitterPanel>
                </Splitter>
            </Box>
            <Dialog open={showCallRecordings} onClose={() => setShowCallRecordings(false)} PaperProps={{ sx: { borderRadius: 3 } }} fullWidth>
                <DialogContent sx={{ px: { xs: 1, sm: 1, md: 2, lg: 2 } }}>
                    <CallRecordings />
                </DialogContent>
                <DialogActions>
                    <Button color="error" sx={{ py: 2 }} onClick={() => setShowCallRecordings(false)} fullWidth>CLOSE</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showCallHistory} onClose={() => setShowCallHistory(false)} PaperProps={{ sx: { borderRadius: 3 } }} fullWidth>
                <DialogContent sx={{ px: { xs: 1, sm: 1, md: 2, lg: 2 } }}>
                    <CallHistory />
                </DialogContent>
                <DialogActions>
                    <Button color="error" sx={{ py: 2 }} onClick={() => setShowCallHistory(false)} fullWidth>CLOSE</Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}

const ChatConnection = ({ setSelectedChat, myLocation, location, nickname, from, to, senderKeys, receiverPubs, senderPeer, receiverPeerId, streams, isCamera, toggleVideo, toggleAudio, dimensions, playOutgoingSoundButton, stopOutgoingSoundButton }) => {
    const [message, setMessage] = useState("")
    const [attachments, setAttachments] = useState([])
    const [myLiveLocation, setMyLiveLocation] = useState({})
    const [sharedLiveLocation, setSharedLiveLocation] = useState({})
    const [myDeviceId, setMyDeviceId] = useState("")
    const [sharedDeviceId, setSharedDeviceId] = useState("")
    const [shareLoc, setShareLoc] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const [error, setError] = useState(false)
    const [sendMessage, setSendMessage] = useState(false)
    const [isOnline, setIsOnline] = useState(false)
    const [appState, appStateDispatch] = useContext(AppContext)
    const [callStates, setCallStates] = useState({ ringing: false, calling: false, dialing: false })
    const [call, setCall] = useState()
    const [dataConn, setDataConn] = useState()
    const [openAttachment, setOpenAttachment] = useState(false)
    const [showChat, setShowChat] = useState(false)
    const [showEmoji, setShowEmoji] = useState(false)
    const [showLocation, setShowLocation] = useState(true)
    const [showMap, setShowMap] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const [useVC, setUseVC] = useState(false)
    const [voiceRecorder, setVoiceRecorder] = useState(null)
    const [callerAddress, setCallerAddress] = useState("")
    const [voiceMsgRecording, setVoiceMsgRecording] = useState(false)
    const allowCallRecording = appState?.callRecording === 'true'
    const gun = appState.gun;
    const peer = mypeer // || appState.peer;
    const storedMessages = useStoredMessages(from, to)
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const chatAreaRef = useRef(null)

    useEffect(() => {
        if (chatAreaRef) {
            chatAreaRef.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });

        }

    }, [])

    useEffect(() => {
        if (streams?.localStream) {
            const voiceRec = RecordRTC(streams?.localStream, {
                type: 'audio'
            });

            setVoiceRecorder(voiceRec)
        }

        return () => {
            setVoiceRecorder(null)
        }
    }, [streams?.localStream])


    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 20,
        maxSize: MAX_FILE_SIZE,
        onError: err => setError(err),
        onDrop: acceptedFiles => {
            setAttachments(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    useEffect(() => {
        console.log("acceptedFiles:", acceptedFiles)
        setAttachments(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
    }, [acceptedFiles, refresh])

    const files = attachments
        .sort((a, b) => a.size > b.size)
        .map((file, index) => (
            <Stack key={file.name + index} spacing={0}>
                <FilePreview file={file} />
            </Stack>
        ));

    useEffect(() => {
        try {
            if (from && peer) {
                const dataConnection = peer.connect(receiverPeerId, {
                    metadata: {
                        from: from,
                        to: to,
                    }
                })

                setDataConn(dataConnection)

                return () => {
                    dataConnection?.off()
                    console.log(`🔴 dataConn CLOSED for ${to}`)
                    // peer.off()
                }
            }
        } catch (e) { console.log(e) }
    }, [peer, from, to, receiverPeerId])

    const sendFiles = async () => {
        console.log("📎 FILES GOING TO SEND: ", acceptedFiles)
        for (const file of acceptedFiles) {
            try {
                if (file.size <= MAX_FILE_SIZE) {
                    const blob = new Blob([file], { type: file.type })
                    const timestamp = new Date()
                    dataConn.send({
                        id: crypto.randomUUID(),
                        file: blob,
                        name: file.name,
                        type: file.type,
                        from: from,
                        to: to,
                        sendOn: timestamp.toISOString(),
                        timestamp: timestamp.getTime()
                    })
                }
            } catch (e) {
                console.log(e)
            }
        }
        acceptedFiles.splice(0, acceptedFiles.length)
        setRefresh(ps => !ps)
    }

    const isVideo = streams?.localStream?.getVideoTracks()?.[0]?.enabled;
    const isAudio = streams?.localStream?.getAudioTracks()?.[0]?.enabled;

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const toArrayBuffer = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const makeCall = () => {
        try {
            if (senderPeer && receiverPeerId) {
                let remoteRecorder, localRecorder, mergeRecorder
                let videoStreamMerger
                if (streams?.localStream) {
                    console.log("🎥 STREAM: %O", streams?.localStream)
                    const madeAt = new Date().toISOString()
                    const outgoingCall = senderPeer.call(receiverPeerId, streams?.localStream, { metadata: { video: true, fromId: from, toId: to, madeAt: madeAt } })
                    setCall(outgoingCall)
                    try { stopOutgoingSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                    try { playOutgoingSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                    if (outgoingCall?.peer) {
                        setCallStates(ps => ({ ...ps, dialing: true }))
                        outgoingCall.on('stream', async (stream) => {
                            setShowMap(false)
                            try { stopOutgoingSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                            console.log("remoteStream:", stream)
                            if (allowCallRecording && ALLOW_INDIVIDUAL_CALL_RECORDINGS) {
                                remoteRecorder = RecordRTC(stream, {
                                    type: 'video'
                                });

                                remoteRecorder.startRecording();

                                localRecorder = RecordRTC(streams?.localStream, {
                                    type: 'video'
                                });

                                localRecorder.startRecording();
                            }

                            if (allowCallRecording && ALLOW_COMBINED_CALL_RECORDINGS) {
                                const rw = stream?.getVideoTracks()?.[0]?.getSettings()?.width ?? 320
                                const rh = stream?.getVideoTracks()?.[0]?.getSettings()?.height ?? 240
                                const lw = streams?.localStream?.getVideoTracks()?.[0]?.getSettings()?.width ?? 320
                                const lh = streams?.localStream?.getVideoTracks()?.[0]?.getSettings()?.height ?? 240

                                console.log(rw, rh, lw, lh)

                                videoStreamMerger = new VideoStreamMerger({
                                    width: rw + lw,
                                    height: rh + lh,
                                })

                                videoStreamMerger.addStream(stream, {
                                    x: 0,
                                    y: 0,
                                    width: rw,
                                    height: rh,
                                    mute: false,
                                    ...(ALLOW_WATERMARK_ON_CALL_RECORDINGS && {
                                        draw: (ctx, frame, done) => {
                                            // You can do whatever you want with this canvas context
                                            ctx.drawImage(frame, 0, 0, rw, rh)
                                            // // Add a custom caption
                                            ctx.font = '10px JetBrains Mono';
                                            ctx.fillStyle = '#09ff00';
                                            ctx.fillText(`${new Date().toISOString()}`, 5, 15);
                                            ctx.fillText(`FROM: ${appState?.did}`, 5, 30);
                                            myLocation?.latitude && myLocation?.longitude && ctx.fillText(`Lat: ${myLocation?.latitude}, Lon: ${myLocation?.longitude}`, 5, 45);
                                            ctx.fillText(`TO: ${to}`, 5, 60);
                                            location?.latitude && location?.longitude && ctx.fillText(`Lat: ${location?.latitude}, Lon: ${location?.longitude}`, 5, 75);
                                            done()
                                        }
                                    })
                                })

                                videoStreamMerger.addStream(streams?.localStream, {
                                    x: 0,
                                    y: rh,
                                    width: lw,
                                    height: lh,
                                    mute: false,
                                })

                                videoStreamMerger.start()

                                mergeRecorder = RecordRTC(videoStreamMerger.result, {
                                    type: 'video'
                                });

                                mergeRecorder.startRecording();
                            }

                            setCallStates(ps => ({ ...ps, dialing: false, calling: true }))

                            historyStore.setItem(outgoingCall?.connectionId,
                                {
                                    direction: "outgoing",
                                    from: senderPeer?._id,
                                    to: receiverPeerId,
                                    fromId: from,
                                    toId: to,
                                    formLocation: myLocation,
                                    toLocation: location,
                                    madeAt: madeAt,
                                    acceptedAt: new Date().toISOString()
                                }
                            )

                            try {
                                var displayMediaVideoIncoming = document.getElementById(to + from);
                                if (displayMediaVideoIncoming) {
                                    displayMediaVideoIncoming.srcObject = stream;
                                    displayMediaVideoIncoming.addEventListener('loadedmetadata', () => {
                                        displayMediaVideoIncoming.play()
                                    })
                                }
                            } catch (e) { }
                        })
                        outgoingCall.on('disconnect', () => {
                            try { stopOutgoingSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                            console.log("outgoing call disconnected!")
                            setShowMap(false)
                            if (allowCallRecording && ALLOW_INDIVIDUAL_CALL_RECORDINGS) {
                                remoteRecorder.stopRecording((blob) => {
                                    const dataUrl = remoteRecorder.toURL()
                                    console.log("remote dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`remote-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-remote-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                                localRecorder.stopRecording((blob) => {
                                    const dataUrl = localRecorder.toURL()
                                    console.log("local dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`local-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-local-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                            }
                            if (allowCallRecording && ALLOW_COMBINED_CALL_RECORDINGS) {
                                mergeRecorder.stopRecording((blob) => {
                                    const dataUrl = mergeRecorder.toURL()
                                    console.log("merge dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`merged-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-merged-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                            }
                            var displayMediaVideoIncoming = document.getElementById(to + from);
                            if (displayMediaVideoIncoming) {
                                displayMediaVideoIncoming.srcObject = null;
                            }
                            setCallStates(ps => ({ ...ps, dialing: false, calling: false }))
                            updateHistory(outgoingCall?.connectionId, { disconnectedAt: new Date().toISOString() })
                            console.log("OUTGOING CALL DISCONNECTED")
                        })
                        outgoingCall.on('close', () => {
                            try { stopOutgoingSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                            console.log("outgoing call closed!")
                            setShowMap(false)
                            if (allowCallRecording && ALLOW_INDIVIDUAL_CALL_RECORDINGS) {
                                remoteRecorder.stopRecording((blob) => {
                                    const dataUrl = remoteRecorder.toURL()
                                    console.log("remote dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`remote-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-remote-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                                localRecorder.stopRecording((blob) => {
                                    const dataUrl = localRecorder.toURL()
                                    console.log("local dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`local-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-local-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                            }
                            if (allowCallRecording && ALLOW_COMBINED_CALL_RECORDINGS) {
                                mergeRecorder.stopRecording((blob) => {
                                    const dataUrl = mergeRecorder.toURL()
                                    console.log("merge dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`merged-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-merged-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                            }
                            var displayMediaVideoIncoming = document.getElementById(to + from);
                            if (displayMediaVideoIncoming) {
                                displayMediaVideoIncoming.srcObject = null;
                            }
                            setCallStates(ps => ({ ...ps, dialing: false, calling: false }))
                            updateHistory(outgoingCall?.connectionId, { closedAt: new Date().toISOString() })
                            console.log("OUTGOING CALL CLOSED")
                        })
                        outgoingCall.on('error', () => {
                            try { stopOutgoingSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
                            console.log("outgoing call errored!")
                            setShowMap(false)
                            if (allowCallRecording && ALLOW_INDIVIDUAL_CALL_RECORDINGS) {
                                remoteRecorder.stopRecording((blob) => {
                                    const dataUrl = remoteRecorder.toURL()
                                    console.log("remote dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`remote-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-remote-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                                localRecorder.stopRecording((blob) => {
                                    const dataUrl = localRecorder.toURL()
                                    console.log("local dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`local-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-local-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                            }
                            if (allowCallRecording && ALLOW_COMBINED_CALL_RECORDINGS) {
                                mergeRecorder.stopRecording((blob) => {
                                    const dataUrl = mergeRecorder.toURL()
                                    console.log("merge dataUrl: ", dataUrl)
                                    recordingsStore.setItem(`merged-${outgoingCall?.connectionId}`, { file: new File([blob], `outgoing-merged-${outgoingCall?.connectionId}.webm`, { type: "video/webm" }), arrivedAt: new Date().getTime(), from: from, to: to, preview: dataUrl })
                                })
                            }
                            var displayMediaVideoIncoming = document.getElementById(to + from);
                            if (displayMediaVideoIncoming) {
                                displayMediaVideoIncoming.srcObject = null;
                            }
                            setCallStates(ps => ({ ...ps, dialing: false, calling: false }))
                            updateHistory(outgoingCall?.connectionId, { erroredAt: new Date().toISOString() })
                            console.log("OUTGOING CALL ERRORED")
                        })
                    }
                }
            }
        } catch (e) { console.log(e) }
    }

    const endCall = () => {
        try { stopOutgoingSoundButton.current.click() } catch (e) { console.log("play button error: ", e) }
        try {
            if (call?.peer) {
                console.log("🔴 ENDING INITIATED CALL")
                call?.close()
                setCallStates(ps => ({ ...ps, dialing: false, calling: false }))
                var displayMediaVideoIncoming = document.getElementById(to + from);
                if (displayMediaVideoIncoming) {
                    displayMediaVideoIncoming.srcObject = null;
                    // displayMediaVideoIncoming.play()
                }
            } else {
                console.log("💥 END CALL FAILED", call)
            }
        } catch (e) { console.log(e) }
    }

    useEffect(() => {
        if (senderPeer && receiverPeerId) {
            console.log("📞 ReceiverPeerId:", receiverPeerId)
        }

        if (streams?.localStream) {
            try {
                var displayMediaVideoOutgoing = document.getElementById(from + to);
                if (displayMediaVideoOutgoing) {
                    displayMediaVideoOutgoing.srcObject = streams?.localStream;
                    displayMediaVideoOutgoing.addEventListener('loadedmetadata', () => {
                        displayMediaVideoOutgoing.play();
                    })
                }
            } catch (e) { console.log("💔 ERROR: ", e) }
        }

    }, [senderPeer, from, to, receiverPeerId, streams])

    useEffect(() => {
        console.log("↻ REFRESH MESSAGES!")
        if (gun && to && from) {
            gun.get(to).get(from).on(signedEncryptedMessage => {
                // console.log(`📤 MY MESSAGE TO: ${to}`)
                signedEncryptedMessage && SEA.verify(signedEncryptedMessage, senderKeys?.pub).then(encryptedMessage => {
                    encryptedMessage && SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                        // console.log("shared passphrase: ", passphrase)
                        passphrase && SEA.decrypt(encryptedMessage, passphrase).then(decryptedMessage => {
                            // console.log("myDecryptedMessage:", decryptedMessage)
                            // decryptedMessage && decryptedMessage?.uuid && setMessages(ps => ({ ...ps, [decryptedMessage?.uuid]: decryptedMessage }))
                            try {
                                decryptedMessage && decryptedMessage?.uuid && db.entity(messageEntity).create({ id: decryptedMessage?.uuid, voice: null, attachment: null, vc: false, edited: false, ...decryptedMessage })
                            } catch (err) {
                                decryptedMessage && decryptedMessage?.uuid && db.entity(messageEntity).update(decryptedMessage?.uuid, { id: decryptedMessage?.uuid, voice: null, attachment: null, vc: false, edited: false, ...decryptedMessage })
                            }
                        }).catch(error => console.log(`❌ DECRYPT ERROR ${error}`))
                    }).catch(error => console.log(`❌ SECRET ERROR ${error}`))
                }).catch(error => console.log(`❌ VERIFY ERROR ${error}`))
            })

            gun.get(from).get(to).on(signedEncryptedMessage => {
                // console.log(`📥 MESSAGE FROM: ${to}`)
                signedEncryptedMessage && SEA.verify(signedEncryptedMessage, receiverPubs?.pub).then(encryptedMessage => {
                    encryptedMessage && SEA.secret(receiverPubs?.epub, senderKeys).then(pass => {
                        // console.log("shared passphrase: ", pass)
                        pass && SEA.decrypt(encryptedMessage, pass).then(decryptedMessage => {
                            // console.log("theirDecryptedMessage:", decryptedMessage)
                            // decryptedMessage && decryptedMessage?.uuid && decryptedMessage && setMessages(ps => ({ ...ps, [decryptedMessage?.uuid]: decryptedMessage }))
                            try {
                                decryptedMessage && decryptedMessage?.uuid && db.entity(messageEntity).create({ id: decryptedMessage?.uuid, voice: null, attachment: null, vc: false, edited: false, ...decryptedMessage })
                            } catch (err) {
                                decryptedMessage && decryptedMessage?.uuid && db.entity(messageEntity).update(decryptedMessage?.uuid, { id: decryptedMessage?.uuid, voice: null, attachment: null, vc: false, edited: false, ...decryptedMessage })
                            }
                            gun.get(from).get(to).put(null)
                        }).catch(error => console.log(`❌ DECRYPT ERROR ${error}`))
                    }).catch(error => console.log(`❌ SECRET ERROR ${error}`))
                }).catch(error => console.log(`❌ VERIFY ERROR ${error}`))
            })

            gun.get("liveLocation").get(from).get(to).on(signedEncryptedMessage => {
                // console.log(`📥 LIVE LOCATION FROM: ${to}`)
                signedEncryptedMessage && SEA.verify(signedEncryptedMessage, receiverPubs?.pub).then(encryptedMessage => {
                    encryptedMessage && SEA.secret(receiverPubs?.epub, senderKeys).then(pass => {
                        // console.log("shared passphrase: ", pass)
                        pass && SEA.decrypt(encryptedMessage, pass).then(decryptedMessage => {
                            // console.log("theirDecryptedMessage:", decryptedMessage)
                            decryptedMessage && setSharedLiveLocation(decryptedMessage)
                        }).catch(error => console.log(`❌ DECRYPT ERROR ${error}`))
                    }).catch(error => console.log(`❌ SECRET ERROR ${error}`))
                }).catch(error => console.log(`❌ VERIFY ERROR ${error}`))
            })

            gun.get("deviceId").get(from).get(to).on(signedEncryptedMessage => {
                // console.log(`📥 DEVICE ID FROM: ${to}`)
                signedEncryptedMessage && SEA.verify(signedEncryptedMessage, receiverPubs?.pub).then(encryptedMessage => {
                    encryptedMessage && SEA.secret(receiverPubs?.epub, senderKeys).then(pass => {
                        // console.log("shared passphrase: ", pass)
                        pass && SEA.decrypt(encryptedMessage, pass).then(decryptedMessage => {
                            // console.log("theirDecryptedMessage:", decryptedMessage)
                            decryptedMessage && setSharedDeviceId(decryptedMessage)
                        }).catch(error => console.log(`❌ DECRYPT ERROR ${error}`))
                    }).catch(error => console.log(`❌ SECRET ERROR ${error}`))
                }).catch(error => console.log(`❌ VERIFY ERROR ${error}`))
            })

            gun.get(to).get("isOnline").on(signedStatus => {
                signedStatus && SEA.verify(signedStatus, receiverPubs?.pub).then(verifiedStatus => {
                    if (verifiedStatus && verifiedStatus?.updatedOn && ((new Date().getTime() - verifiedStatus?.updatedOn) < 10 * 1000)) {
                        setIsOnline(verifiedStatus?.isOnline)
                    }
                    else {
                        setIsOnline(false)
                    }
                })
            })

            gun.get("peerId").get(to).on(signedPeerId => {
                signedPeerId && SEA.verify(signedPeerId, receiverPubs?.pub).then(verified => {
                    verified && console.log("his peerId:", verified)
                })
            })
        }

        return () => {
            gun.get(to).get(from).off()
            gun.get(from).get(to).off()
            gun.get('liveLocation').get(from).get(to).off()
            gun.get(to).get("isOnline").off()
            Geolocation.clearWatch().then(response => {
                console.log("clear watch response:", response)
            }).catch(error => {
                console.log("clear watch error:", error)
            })
        }
    }, [to, from, senderKeys, gun, receiverPubs?.epub, receiverPubs?.pub])

    useEffect(() => {
        if (sendMessage && senderKeys && receiverPubs) {
            if (Array.isArray(attachments) && attachments.length === 0 && message) {
                const timestamp = new Date()
                const messageContent = {
                    uuid: crypto.randomUUID(),
                    message: message,
                    vc: useVC,
                    from: from,
                    to: to,
                    sendOn: timestamp.toISOString(),
                    timestamp: timestamp.getTime()
                }

                var style = {
                    font: "JetBrains Mono",
                    align: 'left',
                    color: 'black',
                    size: 18,
                    background: "#FDFF00" || "rgb(0, 0, 0, 0)",
                    stroke: 0,
                    strokeColor: "rgba(255, 255, 255, 1)",
                    lineHeight: "1.2em",
                    bold: true,
                    italic: false,
                };

                if (useVC) {
                    vc(style, message, 25).then(shares => {
                        // console.log("shares:", shares)
                        try {
                            shares && shares?.[0] && shares?.[1] && db.entity(messageEntity).create({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent, message: shares })
                        } catch (err) {
                            shares && shares?.[0] && shares?.[1] && db.entity(messageEntity).update(messageContent?.uuid, { id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent, message: shares })
                        }
                        try { shares && shares?.[0] && shares?.[1] && dataConn.send({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent, message: shares }) } catch (e) { }
                        // shares && shares?.[0] && shares?.[1] && setMessages(ps => ({ ...ps, [messageContent?.uuid]: { ...messageContent, message: shares } }))
                        shares && shares?.[0] && shares?.[1] && SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                            // console.log("shared passphrase: ", passphrase)
                            passphrase && SEA.encrypt({ ...messageContent, message: shares }, passphrase).then(encryptedMessage => {
                                SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                                    signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                                    // setMessage("")
                                    setSendMessage(false)
                                })
                            })
                        })
                    })
                } else {
                    try {
                        db.entity(messageEntity).create({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                    } catch (err) {
                        db.entity(messageEntity).update(messageContent?.uuid, { id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                    }
                    try { dataConn.send({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent }) } catch (e) { }
                    // setMessages(ps => ({ ...ps, [messageContent?.uuid]: messageContent }))
                    SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                        // console.log("shared passphrase: ", passphrase)
                        passphrase && SEA.encrypt(messageContent, passphrase).then(encryptedMessage => {
                            SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                                signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                                setMessage("")
                                setSendMessage(false)
                            })
                        })
                    })
                }

                setMessage("")
                setSendMessage(false)
            }

            if (Array.isArray(attachments) && attachments.length > 0 && attachments.length < 2 && attachments[0]?.size < 250 * 1024) {
                const timestamp = new Date()
                toArrayBuffer(attachments[0]).then(result => {
                    crypto.subtle.digest('SHA-256', result).then(hashBuffer => {
                        const hashArray = Array.from(new Uint8Array(hashBuffer));
                        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                        console.log(attachments[0], hashHex);
                        toBase64(attachments[0]).then(encoded => {
                            const messageContent = {
                                uuid: crypto.randomUUID(),
                                message: message,
                                attachment: { ...attachments[0], size: attachments[0]?.size, dataURI: encoded, sha256: hashHex },
                                from: from,
                                to: to,
                                sendOn: timestamp.toISOString(),
                                timestamp: timestamp.getTime()
                            }

                            console.log("messageContent:", messageContent)

                            try {
                                db.entity(messageEntity).create({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                            } catch (err) {
                                db.entity(messageEntity).update(messageContent?.uuid, { id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
                            }
                            try { dataConn.send({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent }) } catch (e) { }
                            // setMessages(ps => ({ ...ps, [messageContent?.uuid]: messageContent }))
                            SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                                // console.log("shared passphrase: ", passphrase)
                                passphrase && SEA.encrypt(messageContent, passphrase).then(encryptedMessage => {
                                    SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                                        signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                                        setMessage("")
                                        acceptedFiles.splice(0, acceptedFiles.length)
                                        setRefresh(ps => !ps)
                                        setSendMessage(false)
                                    })
                                }).catch(() => { setSendMessage(false) })
                            })
                        }).catch(e => console.log("base64 encode error: ", e))
                    }).catch(ex => console.error(ex));
                }).catch(() => { setSendMessage(false) })

                setSendMessage(false)
            } else {
                console.log("⚠ Either attachment not found or over the size limit 1MB.")
                sendFiles()
                setSendMessage(false)
            }

        } else {
            setSendMessage(false)
        }

    }, [sendMessage, message, attachments, from, to, receiverPubs, senderKeys, gun, useVC, dataConn])

    const sendVoiceMessage = (dataURL) => {
        const timestamp = new Date()
        const messageContent = {
            uuid: crypto.randomUUID(),
            message: message,
            voice: { dataURI: dataURL },
            from: from,
            to: to,
            sendOn: timestamp.toISOString(),
            timestamp: timestamp.getTime()
        }

        if (dataURL) {
            console.log("messageContent:", messageContent)

            try {
                db.entity(messageEntity).create({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
            } catch (err) {
                db.entity(messageEntity).update(messageContent?.uuid, { id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent })
            }
            try { dataConn.send({ id: messageContent?.uuid, voice: null, attachment: null, vc: false, edited: false, ...messageContent }) } catch (e) { }
            // setMessages(ps => ({ ...ps, [messageContent?.uuid]: messageContent }))
            SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                // console.log("shared passphrase: ", passphrase)
                passphrase && SEA.encrypt(messageContent, passphrase).then(encryptedMessage => {
                    SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                        signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                        setMessage("")
                        acceptedFiles.splice(0, acceptedFiles.length)
                        setRefresh(ps => !ps)
                        setSendMessage(false)
                    })
                }).catch(() => { setSendMessage(false) })
            })
        }
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            setSendMessage(true)
        }
    }

    useEffect(() => {
        if (showChat && to) {
            scrollToBottom(`chatArea-${to}`)
        }
    }, [showChat, to])

    const deleteMessage = (key) => {
        try { db.entity(messageEntity).remove(key) } catch (err) { console.log("message delete err: ", err) }
    }

    const undoMessage = (key) => {
        const removed = db.entity(messageEntity).findById(key)

        if (removed && to !== appState?.did && from === appState?.did) {
            const messageData = removed.getData()
            try {
                db.entity(messageEntity).update(key, { ...messageData, message: null, attachment: null, voice: null, edited: true })
            } catch (err) { console.log("message update err: ", err) }
            SEA.secret(receiverPubs?.epub, senderKeys).then(passphrase => {
                // console.log("shared passphrase: ", passphrase)
                passphrase && SEA.encrypt({ ...messageData, message: null, attachment: null, voice: null, edited: true }, passphrase).then(encryptedMessage => {
                    SEA.sign(encryptedMessage, senderKeys).then(signedEncryptedMessage => {
                        signedEncryptedMessage && gun.get(to).get(from).put(signedEncryptedMessage)
                    })
                })
            })
        }
    }

    const toggleVoiceMessage = () => {
        if (voiceRecorder && isCamera) {
            if (voiceMsgRecording) {
                voiceRecorder?.startRecording()
            } else {
                if (voiceRecorder.state === 'recording')
                    voiceRecorder.stopRecording((blob) => {
                        voiceRecorder.getDataURL(dataURL => {
                            sendVoiceMessage(dataURL)
                        })
                    })
            }
        }
    }

    useEffect(() => {
        toggleVoiceMessage()
    }, [voiceMsgRecording])

    return (
        <Stack sx={{ border: 0 }}>
            <Card component={ListItem} sx={{ borderRadius: 0 }}>
                <ListItemIcon>
                    <Tooltip title={"Back"} >
                        <IconButton onClick={() => setSelectedChat("")}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                </ListItemIcon>
                <ListItemText
                    sx={{ wordBreak: "break-all" }}
                    primary={nickname && nickname}
                    secondary={!smScreen && to && <Typography variant="body2" fontFamily={"JetBrains Mono"} color={"text.secondary"} >{to}</Typography>}
                //{isOnline ? "watching" : "away"}
                />
                <Box sx={{ flexGrow: 1 }}></Box>
                <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
                    <P2PReceivedFiles filter={{ from: to }} />
                    <Tooltip title={useVC ? "Disable Visual Crypto" : "Enable Visual Crypto"} >
                        <ToggleButton size="small" selected={useVC} value={"useVC"} onClick={() => setUseVC(ps => !ps)} color="warning" sx={{ textTransform: "none", borderRadius: 2, ...(!smScreen && { px: 1 }) }}>
                            <Badge badgeContent={useVC ? <LockIcon fontSize="10px" /> : <LockOpenIcon fontSize="10px" />} anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}>
                                <MmsOutlinedIcon />
                            </Badge>
                            {!smScreen && <Typography sx={{ ml: 1 }} variant="caption">VC</Typography>}
                        </ToggleButton>
                    </Tooltip>
                </Stack>
            </Card>
            <Divider sx={{ bgcolor: isOnline ? "success.main" : "error.main" }} />
            <Grid container>
                <Grid id={`chatArea-${to}`} item order={smScreen ? 2 : 1} xs={12} sm={12} md={6} lg={7} sx={{ ...(smScreen && !showChat && { display: "none" }) }}>
                    <Stack ref={chatAreaRef} id={`${to}-chat-area`} spacing={1} minHeight={dimensions.height - 255} maxHeight={dimensions.height - 255} sx={{ overflow: "auto", p: 2, scrollbarWidth: "thin" }} >
                        {/* {messages && Object.keys(messages).length > 0 && Object.keys(messages).filter(key => messages[key]?.uuid && key).sort((a, b) => (messages[a]?.timestamp - messages[b]?.timestamp)).map((key, index) => (<MessageBubble key={key} keyIndex={key} index={index} message={messages[key]} from={from} deleteMessage={() => deleteMessage(key)} undoMessage={() => undoMessage(key)} />))} */}
                        {storedMessages.map((message, index) => (<MessageBubble key={message?.id} keyIndex={message?.id} index={index} message={message} from={from} deleteMessage={() => deleteMessage(message?.id)} undoMessage={() => undoMessage(message?.id)} />))}
                    </Stack>
                    <TextField variant="filled" onKeyDown={handleEnter} value={message} inputProps={{ maxLength: 256 }} onChange={(e) => setMessage(e.target.value)} placeholder="Message"
                        fullWidth hiddenLabel
                        maxRows={3}
                        InputProps={{
                            sx: { pl: 0, pr: 0, borderRadius: 10 },
                            disableUnderline: true,
                            startAdornment: (
                                <Stack direction={"row"} spacing={.5} justifyContent={"center"} alignItems={"center"} sx={{ mx: 1 }}>
                                    <IconButton size="medium" color="primary" onClick={() => setOpenAttachment(true)}>
                                        <Tooltip title="Attachment">
                                            <AttachFileIcon />
                                        </Tooltip>
                                    </IconButton>
                                    {/* <IconButton size="medium" color="warning" onClick={() => setShowEmoji(true)}>
                                        <Tooltip title="Emoji">
                                            <SentimentSatisfiedAltRoundedIcon />
                                        </Tooltip>
                                    </IconButton> */}
                                </Stack>
                            ),
                            endAdornment: (
                                <Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"} sx={{ mr: 1 }}>
                                    <Fab size="small" color="primary" onClick={() => setSendMessage(true)} disabled={message.length === 0 && attachments.length === 0}>
                                        <Tooltip title="Send">
                                            <SendRoundedIcon sx={{ ml: 0.5 }} fontSize="small" />
                                        </Tooltip>
                                    </Fab>
                                    {/* {!smScreen && <Fab size="small" color="info" disabled={!isCamera} onMouseDown={() => { voiceRecorder?.startRecording() }} onMouseUp={() => {
                                        voiceRecorder.stopRecording((blob) => {
                                            voiceRecorder.getDataURL(dataURL => {
                                                sendVoiceMessage(dataURL)
                                            })
                                        })
                                    }}><MicIcon /></Fab>} */}
                                    <Fab size="small" color="info" value={"voice message"} disabled={!isCamera} selected={voiceMsgRecording} component={ToggleButton} onClick={() => setVoiceMsgRecording(ps => !ps)}>{voiceMsgRecording ? <StopIcon color="error" /> : <MicIcon />}</Fab>
                                </Stack>
                            )
                        }}
                    />
                </Grid>
                <Grid order={smScreen ? 1 : 2} item xs={12} sm={12} md={6} lg={5}>
                    <Stack spacing={1} sx={{ ...(!smScreen && { minHeight: dimensions.height - 199, maxHeight: dimensions.height - 199 }), border: 1, borderRadius: 0, borderColor: "divider", p: 1, overflow: "auto", ...(false && { display: "none" }) }}>
                        <Box sx={{ position: "relative", p: 0, ...(!isCamera && { display: "none" }) }}>
                            <Card className="shadow-sm" variant="outlined" sx={{ borderRadius: 3, background: "transparent" }}>
                                <CardMedia sx={{ borderRadius: 3, background: "transparent", ...(!callStates.calling && { height: 115 }), maxHeight: 400 }} id={to + from} component={"video"} autoPlay />
                            </Card>
                            <Box sx={{ position: "absolute", bottom: 0, right: 0, p: 1 }}>
                                <Tooltip title={"ON/OFF Local Stream"} placement="top">
                                    <Card variant="outlined" sx={{ border: 1, borderColor: "divider", borderRadius: 3, boxShadow: 5 }} onClick={() => { toggleVideo(); toggleAudio() }}>
                                        <CardMedia sx={{ borderRadius: 3, background: "black", height: 100, ...(isCamera && { transform: 'ScaleX(-1)', WebkitTransform: "ScaleX(-1)" }) }} id={from + to} component={"video"} autoPlay muted />
                                        {!isVideo && !isAudio && <Stack spacing={1} justifyContent={"center"} alignItems={"center"} sx={{ mx: 1, position: "absolute", bottom: 10, right: 0, p: 1 }}>
                                            <Chip color="error" label={"Stream is OFF"} />
                                        </Stack>}
                                    </Card>
                                </Tooltip>
                            </Box>
                        </Box>

                        {!isCamera &&
                            <Alert severity="warning" sx={{ justifyContent: "center", alignItems: "center", border: 2, borderRadius: 3 }}>
                                You don't have an active media stream to share!
                            </Alert>
                        }

                        <CardActions sx={{ justifyContent: "space-between", alignItems: "center", px: 2, border: 1, borderRadius: 3, borderColor: "divider" }}>
                            <Stack justifyContent={"center"} alignItems={"center"}>
                                <IconButton size={smScreen ? "medium" : "large"} color={isAudio ? "info" : "error"} onClick={toggleAudio} >
                                    {isAudio ? <MicIcon /> : <MicOffIcon />}
                                </IconButton>
                                <Typography align="center" variant="caption" color={"text.secondary"}>{isAudio ? "Mic On" : "Mic Off"}</Typography>
                            </Stack>
                            <Stack justifyContent={"center"} alignItems={"center"}>
                                <IconButton size={smScreen ? "medium" : "large"} color="success" onClick={makeCall} disabled={!streams?.localStream || !receiverPeerId || callStates.dialing || callStates.ringing || callStates.calling}><CallIcon /></IconButton>
                                <Typography align="center" variant="caption" color={"text.secondary"}>Make Call</Typography>
                            </Stack>
                            <Stack justifyContent={"center"} alignItems={"center"}>
                                <IconButton size={smScreen ? "medium" : "large"} color="error" onClick={endCall}><CallEndIcon /></IconButton>
                                <Typography align="center" variant="caption" color={"text.secondary"}>End Call</Typography>
                            </Stack>
                            {isCamera && <Stack justifyContent={"center"} alignItems={"center"}>
                                <IconButton size={smScreen ? "medium" : "large"} color={isVideo ? "info" : "error"} onClick={toggleVideo}>
                                    {isVideo ? <VideocamIcon /> : <VideocamOffIcon />}
                                </IconButton>
                                <Typography align="center" variant="caption" color={"text.secondary"}>{isVideo ? "Cam On" : "Cam Off"}</Typography>
                            </Stack>}
                            {smScreen && <Stack justifyContent={"center"} alignItems={"center"}>
                                <IconButton size={smScreen ? "medium" : "large"} onClick={() => setShowChat(ps => !ps)}>
                                    <Badge badgeContent={showChat ? 0 : db.entity(messageEntity).query({ from: to, to: from }).count} color="error" max={999}>
                                        <ChatIcon color="secondary" />
                                    </Badge>
                                </IconButton>
                                <Typography align="center" variant="caption" color={"text.secondary"}>{showChat ? "Hide Chat" : "Show Chat"}</Typography>
                            </Stack>}
                        </CardActions>

                        <Grid container gap={1}>
                            {callStates?.calling && <Grid item>
                                <Tooltip title={showMap ? "Hide Map" : "Show Map"} >
                                    <Chip icon={<MapIcon fontSize="small" />} label={showMap ? "Hide map" : "Show in map"} onClick={() => setShowMap(ps => !ps)} sx={{ borderRadius: 5, border: 2 }} />
                                </Tooltip>
                            </Grid>}
                            {callStates?.calling && <Grid item>
                                {location?.latitude && location?.longitude && <ShowAddress label={"Receiver's"} location={location} color="primary" />}
                            </Grid>}
                            <Grid item>
                                {myLocation?.latitude && myLocation?.longitude && <ShowAddress label={"Caller's"} location={myLocation} color="error" />}
                            </Grid>
                        </Grid>

                    </Stack>
                    {
                        <Dialog open={callStates?.calling && showMap} onClose={() => setShowMap(false)} PaperProps={{ sx: { borderRadius: 3 } }} fullWidth>
                            <ListItem>
                                <ListItemText
                                    sx={{ wordBreak: "break-all" }}
                                    primary={nickname && nickname}
                                    secondary={!smScreen && to && <Typography variant="body2" fontFamily={"JetBrains Mono"} color={"text.secondary"} >{to}</Typography>}
                                />
                                <ListItemIcon>
                                    <Chip color="error" label={"close"} onClick={() => setShowMap(false)} />
                                </ListItemIcon>
                            </ListItem>
                            <Card className="shadow" sx={{ borderRadius: 0, position: "relative" }}>
                                <DistanceMap id={`mapOf${to}`} maxHeight={window.innerHeight - 150} data={{ [appState?.did]: myLocation, [to]: location }} />
                            </Card>
                        </Dialog>
                    }
                    {/* <Dialog open={showEmoji} onClose={() => setShowEmoji(false)} PaperProps={{ sx: { borderRadius: 2 } }}>
                        <EmojiPicker theme={theme.palette.mode} onEmojiClick={(emojiObject) => {
                            console.log("emoji:", emojiObject)
                            setMessage(ps => (ps + emojiObject?.emoji))
                        }} />
                    </Dialog> */}
                </Grid>
            </Grid>
            {
                <Dialog Dialog open={openAttachment} onClose={() => { setOpenAttachment(false); acceptedFiles.splice(0, acceptedFiles.length); setRefresh(ps => !ps) }} fullScreen={smScreen} PaperProps={{ sx: { borderRadius: 3 } }} sx={{ mb: 8 }} fullWidth={!smScreen}>
                    <ListItem sx={{ borderRadius: 0 }}>
                        <ListItemText primary={`To: ${nickname && nickname}`} secondary={to && to} />
                    </ListItem>
                    <Divider />
                    <Stack spacing={1} sx={{ p: 1 }}>
                        <Stack spacing={1}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: files.length > 0 ? "primary.main" : "divider", borderStyle: "dashed", minWidth: "sm" }} component={'div'} {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <Typography sx={{ m: 6 }} variant='subtitle2' align='center' >Drag 'n' drop some files here, or click to select files</Typography>
                            </Box>
                            <Divider />
                            <DialogActions>
                                <Button onClick={() => { setOpenAttachment(false); acceptedFiles.splice(0, acceptedFiles.length); setRefresh(ps => !ps) }} sx={{ p: 2 }} color="error" fullWidth>
                                    {acceptedFiles.length === 0 ? "back" : "cancel"}
                                </Button>
                                <Button sx={{ p: 2 }} onClick={() => setSendMessage(true)} disabled={message.length === 0 && attachments.length === 0} fullWidth>
                                    send
                                </Button>
                            </DialogActions>
                            {files && files.length > 0 && <Stack maxHeight={400} spacing={0} sx={{ border: 1, borderRadius: 3, borderColor: "divider", overflow: "auto", scrollbarWidth: "thin" }}>
                                {files}
                                <Button startIcon={<DeleteTwoToneIcon />} onClick={() => { acceptedFiles.splice(0, acceptedFiles.length); setRefresh(ps => !ps) }} color='error' sx={{ p: 2 }}>
                                    CLEAR INPUT FILE(S)
                                </Button>
                            </Stack>}
                            {error && <Alert severity='error'>{error}</Alert>}
                        </Stack>
                        {Array.isArray(attachments) && attachments.length > 0 && attachments[0]?.size > 250 * 1024 && <Alert severity="warning">
                            {`Max file sharing size limit in chat is 250KB, large files and if there are multiple files will be send in P2P fashion and it will not appear on chat. Max large file limit is ${formatBytes(MAX_FILE_SIZE)} `}
                        </Alert>}
                    </Stack>
                </Dialog>
            }
        </Stack >
    )
}

export const MessageBubble = ({ message, keyIndex, index, from, deleteMessage, undoMessage }) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Stack direction={"row"} spacing={2} key={keyIndex}>
            {message?.from === from && <Box sx={{ flexGrow: 1, minWidth: 10 }} ></Box>}
            <Card key={index} color={message?.from === from ? "primary" : "inherit"} sx={{ p: .5, textTransform: "none", borderRadius: message?.attachment ? 2 : 4, wordBreak: "break-all", ...((message?.from === from) ? { borderBottomRightRadius: 1 } : { borderBottomLeftRadius: 1 }), bgcolor: message?.from === from ? "primary.main" : "text.disabled", boxShadow: 4 }}>
                <Stack spacing={1}>
                    {message?.attachment && <Stack sx={{ p: 1 }} direction={"row"} spacing={1} alignItems={"flex-start"} justifyContent={"flex-start"}>
                        {message?.attachment?.dataURI && message?.attachment?.path && <MediaPreview attachment={message?.attachment} />}
                        <Stack>
                            <Typography variant="subtitle2" align={"left"}>
                                {message?.attachment?.path && `${message?.attachment?.path}`}
                            </Typography>
                            <Typography variant="caption" align={"left"}>
                                {message?.attachment?.size && `${formatBytes(message?.attachment?.size)}`}
                            </Typography>
                        </Stack>
                    </Stack>}
                    {message?.attachment?.dataURI && message?.attachment?.path && <Box><Button component={Link} href={message?.attachment?.dataURI} download={message?.attachment?.path} color="inherit" sx={{ textTransform: "none", px: 1 }} size="small" >Download</Button></Box>}
                    {message?.voice?.dataURI && <Stack justifyContent={"flex-start"} sx={{ overflow: "auto" }}>
                        <audio src={message?.voice?.dataURI} controls style={{ borderRadius: 20 }} />
                    </Stack>}
                    {!message?.vc && message?.message && <Box sx={{ px: 1, pt: 1 }}>
                        <Typography sx={{ wordBreak: 'break-all' }} variant="subtitle2" align={"left"}>
                            {message?.message}
                        </Typography>
                    </Box>}
                    {message?.vc && message?.message && message?.message.length === 2 && <Box sx={{ overflow: "hidden", position: "relative", p: 0, maxHeight: 100, borderRadius: 3 }} onClick={() => setOpen(true)}>
                        <img src={message?.message?.[0]} alt="share1" />
                        <Box sx={{ position: "absolute", top: 0, left: 0, p: 0, mixBlendMode: "difference", maxHeight: 100, borderRadius: 3 }}>
                            <img src={message?.message?.[1]} alt="share2" />
                        </Box>
                    </Box>}
                    {message?.vc && message?.message && message?.message.length === 2 && <Stack>
                        <Chip size="small" onClick={() => setOpen(true)} label={"View full message"} />
                        <Dialog open={open} onClose={() => setOpen(false)} sx={{ ...(smScreen && { mb: 8 }) }} fullScreen>
                            <Stack justifyContent={"center"} alignItems={"center"}>
                                <Box sx={{ overflow: "auto", position: "relative", p: 0 }}>
                                    <img src={message?.message?.[0]} alt="share1" />
                                    <Box sx={{ position: "absolute", top: 0, left: 0, p: 0, mixBlendMode: "difference" }}>
                                        <img src={message?.message?.[1]} alt="share2" />
                                    </Box>
                                </Box>
                                <Button sx={{ py: 2 }} color="error" onClick={() => setOpen(false)} fullWidth>close</Button>
                            </Stack>
                        </Dialog>
                    </Stack>}
                    {message?.edited && <Chip icon={<BlockIcon color="inherit" />} sx={{ borderRadius: 3, fontStyle: "italic" }} label={`${message?.from === from ? "You" : "Sender"} undid this message`} />}
                    <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} spacing={1} justifyContent={"flex-start"} alignItems={"center"}>
                            {message?.from === from && !message?.edited && <Tooltip title="Undo message">
                                <IconButton disabled={message?.edited} size="small" onClick={undoMessage}><UndoIcon fontSize=".25rem" /></IconButton>
                            </Tooltip>}
                            <Tooltip title="Delete message">
                                <IconButton color="error" size="small" onClick={deleteMessage}><DeleteTwoToneIcon fontSize=".25rem" /></IconButton>
                            </Tooltip>
                        </Stack>
                        <Typography variant="caption" color={"text.disabled"} align={"right"}>
                            {format(new Date(message?.sendOn), "pp")}
                        </Typography>
                    </Stack>
                </Stack>
            </Card>
            {message?.from !== from && <Box sx={{ flexGrow: 1, minWidth: 10 }} ></Box>}
        </Stack>
    )
}

const MediaPreview = ({ attachment }) => {
    const mimeType = attachment?.dataURI.replace("data:", "").split(";")[0]//.split("/")[0]
    const previewIcon = mimeType.includes("video") ?
        <VideoFileIcon color="primary" /> :
        mimeType.includes("audio") ?
            <AudioFileIcon color="secondary" /> :
            mimeType.includes("pdf") ?
                <PictureAsPdfIcon color="error" /> :
                mimeType.includes("text") ?
                    <TextSnippetIcon color="info" /> :
                    mimeType.includes("json") ?
                        <DataObjectIcon color="warning" /> :
                        <InsertDriveFileIcon />


    const [open, setOpen] = useState(false)

    const media = mimeType.includes("image") ? "img" :
        mimeType.includes("video") ? "video" :
            mimeType.includes("audio") ? "audio" : "object"

    return (
        <Box>
            <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ sx: { borderRadius: 3 } }}>
                <Stack>
                    <CardMedia component={media} controls={true} src={attachment?.dataURI} />
                    <Divider />
                    <Typography variant="subtitle2" fontWeight={"light"} sx={{ p: 1, overflow: "hidden" }} align="center">
                        {attachment?.path} • {formatBytes(attachment?.size)}
                    </Typography>
                    <Divider />
                    <Button onClick={() => setOpen(false)} color="error" size="large" sx={{ py: 2 }}>close</Button>
                </Stack>
            </Dialog>
            {mimeType.includes("image") ?
                <Avatar
                    onClick={() => setOpen(true)}
                    alt={attachment?.path}
                    src={attachment?.dataURI}
                    variant="rounded"
                /> :
                <Avatar
                    onClick={() => setOpen(true)}
                    alt={attachment?.path}
                    variant="rounded"
                >{previewIcon}</Avatar>}
        </Box>
    )
}