import { useState, useContext, useEffect } from 'react';
import { Box, Stack, Grid, Typography } from '@mui/material';
import { Button, Link, Alert, Chip } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { isMobileDevice } from '../utils/common';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import HelpSetterContext from '../components/HelpWindow';

import { BsWindows, BsApple } from 'react-icons/bs'
import { DiLinux } from 'react-icons/di'
import { FcLinux } from "react-icons/fc";
import { SiRaspberrypi } from "react-icons/si";
import { ReactComponent as DecentralizedIcon } from '../DecentralizedIcon.svg'

export default function LandingPage() {
    const [alerts, setAlerts] = useState([]) // [{text:'',type:<'error','warning','info','success'>}]
    const location = useLocation()
    const setHelp = useContext(HelpSetterContext)
    const [isOpenCV, setIsOpenCV] = useState(window?.opencv?.ready)

    // setting page title
    useEffect(() => {
        window.document.title = process.env.REACT_APP_NAME

        if (isMobileDevice()) {
            const checkOpenCV = setInterval(() => {
                console.log("check opencv ready...")
                if (window?.opencv?.ready) {
                    setIsOpenCV(true)
                    clearInterval(checkOpenCV)
                    console.log("clear opencv check...")
                }
            }, 500);

            return () => {
                console.log("clear opencv check...")
                clearInterval(checkOpenCV)
            }
        }
    }, [])

    // url parsing
    useEffect(() => {
        const qp = new URLSearchParams(location.search);
        if (qp.has('requireauth')) {
            setAlerts([{
                text: 'You need to access or create a wallet first',
                type: 'error'
            }])
        }
    }, [])

    return (
        <Box sx={{ bgcolor: 'background.default', display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center', py: '3em', px: '1em' }}>
                <Stack sx={{ mb: 2, ...(alerts.length ? {} : { display: 'none' }) }}>
                    {
                        alerts.map((alert, index) => {
                            return (
                                <Alert key={index} severity={alert.type} sx={{ mx: 'auto', mb: 1 }}>
                                    {alert.text}
                                </Alert>
                            )
                        })
                    }
                </Stack>
                <Typography variant="h4" sx={{ mb: 1, mx: 'auto' }}>
                    Welcome to Knuct Webwallet.
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 1, maxWidth: '26em', mx: 'auto', color: 'text.secondary' }}>
                    This is web based wallet that give you access to
                    your knuct coin anywhere in the world.
                </Typography>
                {/* <Link component='button' onClick={() => { setHelp('knctwallet') }}> Know more </Link> */}
                <Typography variant='subtitle2' component={Link} href="https://knuct.com/index.html" target="_blank">
                    Know more
                </Typography>
                <Stack spacing={3} sx={{ alignItems: 'center', mt: 3 }}>
                    <Button variant="outlined" size="large"
                        component={RouterLink} to='/accesswallet'
                        startIcon={<AutoAwesomeIcon />}
                        sx={{ py: 2, width: '17em' }}
                        disabled={isMobileDevice() && !isOpenCV}
                    >
                        Access Your Wallet
                    </Button>
                    <Button variant="contained" size="large" className='shadow'
                        startIcon={<AddCircleOutlineIcon />}
                        component={RouterLink} to='/createwallet'
                        sx={{ py: 2, width: '17em' }}
                    >
                        Create a New Wallet
                    </Button>
                </Stack>
                <Box sx={{ mt: 5 }}>
                    <Grid container spacing={1} justifyContent={"center"} sx={{ my: 2 }}>
                        <Grid item>
                            <Chip icon={<DecentralizedIcon style={{ width: '1em', height: '1em', verticalAlign: 'bottom', color: "#6BCB77" }} />} sx={{ px: 0.5, borderRadius: 2, border: 1, borderColor: "divider" }} label="Decentralized" variant="filled" />
                        </Grid>
                        <Grid item>
                            <Chip icon={<BsWindows color="#0078D6" />} sx={{ px: 0.5, borderRadius: 2, border: 1, borderColor: "divider" }} label="Windows" variant="filled" />
                        </Grid>
                        <Grid item>
                            <Chip icon={<BsApple color="#6C6C6C" />} sx={{ px: 0.5, borderRadius: 2, border: 1, borderColor: "divider" }} label="Mac" variant="filled" />
                        </Grid>
                        <Grid item>
                            <Chip icon={<FcLinux />} sx={{ px: 0.5, borderRadius: 2, border: 1, borderColor: "divider" }} label="Linux" variant="filled" />
                        </Grid>
                        <Grid item>
                            <Chip icon={<SiRaspberrypi />} sx={{ px: 0.5, borderRadius: 2, border: 1, borderColor: "divider" }} label="Raspberry Pi" variant="filled" />
                        </Grid>
                    </Grid>
                    {/* <Typography variant="subtitle2" color={"text.secondary"} sx={{ mb: 1, mx: 'auto' }}>
                        For better security <Link href='/downloadsPage' target='_blank'> download </Link> our desktop wallet.
                    </Typography> */}
                </Box>
            </Box>
        </Box>
    );
}