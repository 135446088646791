import { createContext , useState , useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box , Drawer , AppBar , Toolbar , Typography, Stack } from "@mui/material";
import { IconButton, Button , Link } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

const HelpSetterContext = createContext();

function HelpSetterProvider (props) {
    const [helpState, setHelpState] = useState(null)

    return (
        <HelpSetterContext.Provider value={setHelpState}>
            {props.children}
            <HelpWindow ctrlState={[helpState,setHelpState]}/>
        </HelpSetterContext.Provider>
    );
}

function HelpWindow(props) {
    const [helpState, setHelpState] = props.ctrlState
    const theme = useTheme()
    const smView = useMediaQuery(theme.breakpoints.down('sm'));

    // adding width transition to body
    useEffect(() => {
        document.getElementById('root').style.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
        return () => {
            document.getElementById('root').style.transition = ""
        }
    }, [])

    if(Boolean(helpState) && !smView)
        document.getElementById('root').style.width='calc(100% - 22em)'
    else
        document.getElementById('root').style.width='100%'
    
    if(smView) {
        var dWidth = '100%' 
        var dHeight = '70%'
        var dBorderRadius = '0.8em 0.8em 0 0'
    }
    else {
        var dWidth = '22em' 
        var dHeight = '100%'
        var dBorderRadius = '0'
    }

    return (
        <Drawer anchor={smView ? 'bottom' : "right"} variant={smView ? 'temporary' : "persistent" }
            open={Boolean(helpState)}
            onClose={smView ? ()=>setHelpState(null) : null}
            PaperProps={{
                className:'text-break',
                sx:{width:dWidth, height: dHeight, borderRadius: dBorderRadius,
                    overflowY:'auto', overflowX:'hidden'},
            }}
        >
            <AppBar position="static" color='inherit' className="shadow">
                <Toolbar>
                    <Typography variant="h6" sx={{flexGrow:1}}> Help </Typography>
                    <IconButton onClick={ ()=>{setHelpState(null)} }><CloseIcon/></IconButton>
                </Toolbar>
            </AppBar>
            <Box className='help-content' sx={{my:3}}>
                <Typography variant="subtitle1" sx={{textAlign:'center',py:5}}>
                    Help contents are currently unavailable
                </Typography>
            </Box>
            <Stack spacing={2} sx={{textAlign:'center',mb:5}}>
                <Button variant='outlined' size="small" sx={{m:'auto',px:2}} onClick={ ()=>{setHelpState(null)} } > Close </Button>
                <Link href="/help" target="_blank"> See all help documents </Link>
            </Stack>
            {/* dummy area to move all contents above bottom navbar */}
            <Box className="dummy" sx={{display:{sm:'none'},flexBasis:'56px',flexShrink:0,bgcolor:'transparent'}}></Box>
        </Drawer>
    )
}

export default HelpSetterContext
export { HelpSetterProvider }