import { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Tooltip, } from '@mui/material';
import { Badge, Dialog, DialogActions, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { db, receivedFileEntity } from "../utils/clientdb";

import FilePreview from "./FilePreview";

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AllInboxIcon from '@mui/icons-material/AllInbox';

export default function P2PReceivedFiles({ filter = { from: "" } }) {
    const [receivedFiles, setReceivedFiles] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [openReceivedFiles, setOpenReceivedFiles] = useState(false)
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const rec = filter?.from ?
            [...db.entity(receivedFileEntity).query({ from: filter?.from }).all].sort((a, b) => (a?.timestamp - b?.timestamp))
            :
            [...db.entity(receivedFileEntity).all].sort((a, b) => (a?.timestamp - b?.timestamp))
        setReceivedFiles(rec)

        const unsubscribe_create_event = db.entity(receivedFileEntity).events.on("created", () => {
            // console.log("/// create event ///")
            const rec = filter?.from ?
                [...db.entity(receivedFileEntity).query({ from: filter?.from }).all].sort((a, b) => (a?.timestamp - b?.timestamp))
                :
                [...db.entity(receivedFileEntity).all].sort((a, b) => (a?.timestamp - b?.timestamp))
            setReceivedFiles(rec)
        })

        const unsubscribe_update_event = db.entity(receivedFileEntity).events.on("updated", () => {
            // console.log("/// update event ///")
            const rec = filter?.from ?
                [...db.entity(receivedFileEntity).query({ from: filter?.from }).all].sort((a, b) => (a?.timestamp - b?.timestamp))
                :
                [...db.entity(receivedFileEntity).all].sort((a, b) => (a?.timestamp - b?.timestamp))
            setReceivedFiles(rec)
        })

        const unsubscribe_remove_event = db.entity(receivedFileEntity).events.on("removed", () => {
            // console.log("/// remove event ///")
            const rec = filter?.from ?
                [...db.entity(receivedFileEntity).query({ from: filter?.from }).all].sort((a, b) => (a?.timestamp - b?.timestamp))
                :
                [...db.entity(receivedFileEntity).all].sort((a, b) => (a?.timestamp - b?.timestamp))
            setReceivedFiles(rec)
        })

        return () => {
            console.log("cleaning up received file listeners")
            unsubscribe_create_event()
            unsubscribe_update_event()
            unsubscribe_remove_event()
        }
    }, [refresh, filter.from])

    const files = receivedFiles
        .filter((item, index) => receivedFiles.indexOf(item) === index)
        .sort((a, b) => a.arrivedAt < b.arrivedAt)
        .map((x, index) => (
            <Stack key={x.file.name + index} spacing={0}>
                <FilePreview file={Object.assign(x.file, { preview: URL.createObjectURL(x.file), from: x?.from, to: x?.to })} />
            </Stack>
        ));

    const deleteFilesFrom = (filter) => {
        if (filter?.from) {
            const items = [...db.entity(receivedFileEntity).query({ from: filter?.from }).all]
            for (const item of items) {
                item.remove()
            }
            setRefresh(ps => !ps)
        } else {
            const items = [...db.entity(receivedFileEntity).all]
            for (const item of items) {
                item.remove()
            }
            setRefresh(ps => !ps)
        }
    }

    return (
        <Box>
            <Tooltip title={`Received files ${filter?.from ? "" : "from all"}`}>
                <IconButton onClick={() => setOpenReceivedFiles(true)} >
                    <Badge badgeContent={files.length} anchorOrigin={{ vertical: "top", horizontal: "right" }} color="error">
                        <AllInboxIcon />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Dialog open={openReceivedFiles} onClose={() => setOpenReceivedFiles(false)} sx={{ mb: { xs: 6, sm: 6, md: 0, lg: 0 } }} PaperProps={{ sx: { borderRadius: 3 } }} fullWidth>
                <Stack spacing={2} sx={{ p: 2 }}>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                        Received file list • {files.length} items
                    </Typography>
                    {receivedFiles && receivedFiles.length > 0 && <Stack maxHeight={500} spacing={0} sx={{ border: 1, borderRadius: 3, borderColor: "divider", overflow: "auto", scrollbarWidth: "thin" }}>
                        {files}
                        <Button startIcon={<DeleteTwoToneIcon />} onClick={() => { deleteFilesFrom(filter); }} color='error' sx={{ p: 2 }}>CLEAR RECEIVED FILE(S)</Button>
                    </Stack>}
                    {receivedFiles && receivedFiles.length === 0 && <Stack minHeight={100} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="subtitle2" fontWeight={"bold"} color={"text.disabled"} align="center">
                            Received file list is empty!
                        </Typography>
                    </Stack>}
                </Stack>
                <DialogActions>
                    <Button onClick={() => setOpenReceivedFiles(false)} color="error" sx={{ p: 2 }} fullWidth>CLOSE</Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}