import * as React from 'react';
import { Box, CircularProgress, IconButton, useMediaQuery } from '@mui/material';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { recordingsStore, historyStore } from './utils/localforageInstances';

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { HelpSetterProvider } from './components/HelpWindow';
import { AuthRequiredComponent, AuthRequiredRoute } from './components/Auth';

import LandingPage from './pages/LandingPage';
import PageNotFound from './pages/PageNotFound'
import AccessWallet from './pages/AccessWallet';
import CreateWallet from './pages/CreateWallet';

import { AppContextProvider } from './AppContext';
import CustomThemeProvider, { baseTheme } from './CustomTheme';

import { PrimeReactProvider } from 'primereact/api';

import './App.css';
import Close from '@mui/icons-material/Close';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Transaction = React.lazy(() => import('./pages/Transaction'));
const Contacts = React.lazy(() => import('./pages/Contacts'));
const DownloadsPage = React.lazy(() => import('./pages/DownloadsPage'));
const StockApp = React.lazy(() => import('./components/StockApp/IndexPage'));

const KnuctTalk = React.lazy(() => import('./pages/KnuctTalk'));
const FileShare = React.lazy(() => import('./components/Communications/FileShare'));

const LiveLocationMap = React.lazy(() => import('./components/LiveLocationMap'));

function App() {
    console.log('🌱 App Repaint!')
    recordingsStore.clear()
    // historyStore.clear()
    const smScreen = useMediaQuery(baseTheme.breakpoints.down('md'));

    // --------- Snackbar dismiss ----------
    const notistackRef = React.createRef();
    const snackbarDismissBtn = (key) => {
        return (
            <IconButton onClick={() => {
                notistackRef.current.closeSnackbar(key);
            }}
                sx={{ color: 'inherit' }}
            >
                <Close />
            </IconButton>
        );
    }

    return (
        <AppContextProvider>
            <CustomThemeProvider>
                <PrimeReactProvider>
                    <SnackbarProvider maxSnack={3} preventDuplicate
                        ref={notistackRef} action={snackbarDismissBtn}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        classes={smScreen ? { containerRoot: 'margin-bottomNav' } : {}}
                    >
                        <HelpSetterProvider>
                            <Router basename={process.env.PUBLIC_URL}>
                                <AuthRequiredComponent
                                    auth={<NavBar />}
                                    noAuth={<NavBar type='nomenu' />}
                                />
                                <Box sx={{ flexGrow: 1 }} className='content-wrapper'>
                                    <React.Suspense fallback={<SuspenseFallback />}>
                                        <MainRoutes />
                                    </React.Suspense>
                                </Box>
                                {!smScreen && <Footer />}
                                {/* dummy area to move all contents above bottom navbar */}
                                <AuthRequiredComponent
                                    auth={<Box className="dummy" sx={{ display: { sm: 'none' }, flexBasis: '56px', flexShrink: 0, bgcolor: 'transparent' }}></Box>}
                                />
                            </Router>
                        </HelpSetterProvider>
                    </SnackbarProvider>
                </PrimeReactProvider>
            </CustomThemeProvider>
        </AppContextProvider>
    );
}

function MainRoutes() {
    return (
        <Switch>
            <Route exact path='/'>
                <AuthRequiredComponent
                    auth={<Redirect to='/dashboard' />}
                    noAuth={<LandingPage />}
                />
            </Route>
            <Route path='/accesswallet'>
                <AccessWallet />
            </Route>
            <Route path='/createwallet'>
                <CreateWallet />
            </Route>
            <Route path='/downloadsPage'>
                <DownloadsPage />
            </Route>

            {/* Logined users */}
            <AuthRequiredRoute path='/dashboard'>
                <Dashboard />
            </AuthRequiredRoute>
            <AuthRequiredRoute path='/transaction'>
                <Transaction />
            </AuthRequiredRoute>
            <AuthRequiredRoute path='/contacts'>
                <Contacts />
            </AuthRequiredRoute>
            <AuthRequiredRoute path='/stockapp'>
                <StockApp />
            </AuthRequiredRoute>
            <AuthRequiredRoute path='/KnuctTalk'>
                <KnuctTalk />
            </AuthRequiredRoute>
            <AuthRequiredRoute path='/FileShare'>
                <FileShare />
            </AuthRequiredRoute>
            <AuthRequiredRoute path='/LiveLocationMap'>
                <LiveLocationMap />
            </AuthRequiredRoute>

            {/* API test pages, only include on dev build */}
            <Route path={['/clientapitest', '/serverapitest', '/liveLocations', 'MapWithMarkers', '/ImageOverlayTest', '/MapDistance']}>
                <APITestPages />
            </Route>

            {/* 404 page */}
            <Route path='*'>
                <PageNotFound />
            </Route>
        </Switch>
    )
}

function APITestPages() {
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
        const ClientAPITest = React.lazy(() => import('./pages/ClientAPITest'));
        const ServerAPITest = React.lazy(() => import('./pages/ServerAPITest'));
        const LiveLocations = React.lazy(() => import('./components/Map'));
        const ImageOverlayTest = React.lazy(() => import('./components/ImageOverlayTest'));
        const MapDistance = React.lazy(() => import('./components/MapDistance'));

        return (
            <>
                <Route path='/clientapitest'>
                    <ClientAPITest />
                </Route>
                <Route path='/serverapitest'>
                    <ServerAPITest />
                </Route>
                <Route path='/liveLocations'>
                    <LiveLocations />
                </Route>
                <Route path='/ImageOverlayTest'>
                    <ImageOverlayTest />
                </Route>
                <Route path='/MapDistance'>
                    <MapDistance />
                </Route>
            </>
        )
    }
    else
        return <PageNotFound />
}

function SuspenseFallback() {
    return (
        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={60} thickness={5}
                sx={{ color: baseTheme.palette.text.disabled }}
            />
        </Box>
    )
}

export default App;