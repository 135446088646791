import { useEffect, useState } from "react";

import { getContactsListStatus } from "../api/ClientAPI";

const useContacts = () => {
    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let cancelRequest = () => { }
        setIsLoading(true)
        // console.log("fetching contacts status...")
        getContactsListStatus((cr) => { cancelRequest = cr })
            .then((response) => {
                // console.log("from useContacts: ", response)
                setIsLoading(false)
                if (response !== undefined) {
                    if (response.data.data.response) {
                        if (response.data.data.response.message) {
                            console.log(response.data.data.response.message)
                        }
                        else if (Array.isArray(response.data.data.response)) {
                            if (response.data.data.response.length > 0) {
                                setContacts(response.data.data.response)
                            }
                        }
                        else if (response.data.data.response.nickname && response.data.data.response.did) {
                            setContacts([{
                                nickname: response.data.data.response.nickname,
                                did: response.data.data.response.did,
                                dp: response.data.data.response.dp,
                                onlineStatus: response.data.data.response.onlineStatus
                            }])
                        }
                        else {
                            if (response.data.data.response.message) {
                                console.log(response.data.data.response.message)
                            }
                        }

                    }
                    else {
                        console.log(response.data.data)
                    }
                }
                else {
                    console.log("Response is Undefined.")
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            }).finally(() => {
                setIsLoading(false)
            })

        return () => {
            cancelRequest()
        }
    }, [])

    useEffect(() => {
        let cancelRequest = () => { }
        const onlineWatch = setInterval(() => {
            // console.log("fetching contacts status...")
            getContactsListStatus((cr) => { cancelRequest = cr })
                .then((response) => {
                    // console.log("from useContacts: ", response)
                    if (response !== undefined) {
                        if (response.data.data.response) {
                            if (response.data.data.response.message) {
                                console.log(response.data.data.response.message)
                            }
                            else if (Array.isArray(response.data.data.response)) {
                                if (response.data.data.response.length > 0) {
                                    setContacts(response.data.data.response)
                                }
                            }
                            else if (response.data.data.response.nickname && response.data.data.response.did) {
                                setContacts([{
                                    nickname: response.data.data.response.nickname,
                                    did: response.data.data.response.did,
                                    dp: response.data.data.response.dp,
                                    onlineStatus: response.data.data.response.onlineStatus
                                }])
                            }
                            else {
                                if (response.data.data.response.message) {
                                    console.log(response.data.data.response.message)
                                }
                            }

                        }
                        else {
                            console.log(response.data.data)
                        }
                    }
                    else {
                        console.log("Response is Undefined.")
                    }
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => { })
        }, 10 * 1000);


        return () => {
            clearInterval(onlineWatch)
            cancelRequest()
        }
    }, [])


    return { contacts, isLoading };
};

export default useContacts;