import React, { useState, useEffect, useRef, useContext } from 'react';
import ReactDOM from 'react-dom';
// import ReactDOM from 'react-dom/client';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/WebGLTile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Overlay } from 'ol';
import { fromLonLat, toLonLat } from 'ol/proj.js';
import { toStringHDMS } from 'ol/coordinate.js';
import Draw from 'ol/interaction/Draw.js';
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import Graticule from 'ol/layer/Graticule.js';
import Link from 'ol/interaction/Link.js';
import { Icon, Style, Text, Fill, Stroke } from 'ol/style';

import { Typography, Alert, Box, Card, Chip, CardMedia, Divider, Dialog, Stack, ListItem, ListItemText, Avatar, ListItemAvatar, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import axios from 'axios';

import { format, formatDistanceToNow } from 'date-fns';

import { getContactsListStatus } from '../api/ClientAPI';
import AppContext from '../AppContext';
import { AppContextProvider } from '../AppContext';
import CustomThemeProvider from '../CustomTheme';

import { useTheme } from '@mui/material/styles';

import MapDistance from './MapDistance';
import ShowAddress from './ShowAddress';

import DevicesIcon from '@mui/icons-material/Devices';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlaceIcon from '@mui/icons-material/Place';
import DirectionsIcon from '@mui/icons-material/Directions';

import { BsWindows, BsApple } from 'react-icons/bs'
import { FcLinux } from "react-icons/fc";
import { ImAndroid } from "react-icons/im";

const DistanceMap = ({ id = "DistanceMapDefault", maxHeight, startLocation = [78.9629, 20.5937], data }) => {
    const theme = useTheme()
    const [contacts, setContacts] = useState([])
    const [nicknames, setNicknames] = useState({})
    const [markerData, setMarkerData] = useState([]);
    const [appState, appStateDispatch] = useContext(AppContext)
    const [showMap, setShowMap] = useState(false)

    // Ref for the vector layer that contains the markers 
    const vectorLayerRef = useRef();

    let draw; // global so we can remove it later 
    function addInteraction(map, drawSource, type = "Circle") {
        const value = type;
        if (value !== 'None') {
            draw = new Draw({
                source: drawSource,
                type: type,
                freehand: true,
            });
            map.addInteraction(draw);
        }
    }

    useEffect(() => {
        if (id) {
            console.log("🗺️ Distance Map Draw")
            // Create a vector layer for the markers 
            const vectorSource = new VectorSource();
            const vectorLayer = new VectorLayer({
                source: vectorSource,
            });
            vectorLayerRef.current = vectorLayer;

            const drawSource = new VectorSource({ wrapX: false });
            const drawVector = new VectorLayer({
                source: drawSource,
            });

            const graticuleLines = new Graticule({
                // the style to use for the lines, optional. 
                strokeStyle: new Stroke({
                    color: '#bdbdbd',
                    width: 1,
                    lineDash: [0.5, 4],
                }),
                showLabels: false,
                wrapX: false,
            })

            // Create a map with the OpenLayers library 
            const map = new Map({
                controls: defaultControls().extend([new FullScreen()]),
                target: id,
                layers: [
                    new TileLayer({
                        source: new OSM(),
                    }),
                    vectorLayer,
                    drawVector,
                    // graticuleLines,
                ],
                view: new View({
                    center: fromLonLat([78.9629, 20.5937]), // [lat, lon]
                    zoom: 3,
                }),
            });

            // map.addInteraction(new Link());

            // dark mode map 
            // map.on('postcompose', function (e) {
            //     document.querySelector('canvas').style.filter = ({ dark: "grayscale(80%) invert(100%) ", light: "" }[theme.palette.mode]);
            // });

            // addInteraction(map, drawSource, ["LineString", "Polygon", "Circle", "None"][3])

            // Add a mouseover (pointermove) event listener to the map 
            map.on('click' || 'pointermove', (event) => {
                const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
                if (feature && feature.get('overlay')) {
                    // console.log(feature?.id_) 
                    const overlay = feature.get('overlay');
                    overlay.setPosition(event.coordinate);
                    map.addOverlay(overlay)
                } else {
                    const overlays = map.getOverlays().getArray();
                    overlays.forEach((overlay) => { overlay.setPosition(undefined); map.removeOverlay(overlay) });
                }
            });

            // map.on("dblclick", (event) => { 
            //     map.removeInteraction(draw) 
            // }) 

            // map.on('click', function (evt) {
            //     const coordinate = evt.coordinate;
            //     const hdms = toStringHDMS(toLonLat(coordinate));
            //     console.log(hdms)
            //     console.table(coordinate);

            // });

            return () => {
                // Clean up the map when the component unmounts 
                map.setTarget(null);
            };
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            let liveLocations = Object.keys(data)
                // .filter(key => data[key]?.timestamp && (new Date().getTime() - new Date(data[key]?.timestamp).getTime()) < 5 * 60 * 1000 && key) // 5 min filter
                .filter(key => data[key]?.did && data[key]?.latitude && data[key]?.longitude && key)
                .filter(key => (Object.keys(nicknames).includes(key) || key === appState?.did) && key)
                .map(key => ({
                    id: data[key]?.did,
                    lat: data[key]?.latitude,
                    lng: data[key]?.longitude,
                    ...data[key]
                }))
            // console.log("liveLocations:", liveLocations)
            setMarkerData(liveLocations)
        }
    }, [data, appState?.did, nicknames])

    useEffect(() => {
        // Update the vector layer with the new marker data 
        if (vectorLayerRef.current && markerData.length) {
            const markers = markerData
                // .filter(x => (x?.id === matchDid || x?.id === appState?.did) && x)
                .map((marker) => {
                    const { id, lat, lng, deviceInfo } = marker;
                    const markerFeature = new Feature({
                        geometry: new Point(fromLonLat([lng, lat])),
                    });

                    markerFeature.setStyle(new Style({
                        image: new Icon({
                            crossOrigin: 'anonymous',
                            src: appState?.did === id ? 'pos_me.svg' : "pos_contacts.svg",
                            scale: 0.025,
                        })
                    }))

                    // Create an overlay for the tooltip 
                    const overlay = new Overlay({
                        element: document.createElement('div'),
                        offset: [5, 0],
                        positioning: 'bottom-left',
                    });
                    overlay.getElement().textContent = id;
                    overlay.getElement().innerHTML = "";

                    ReactDOM.render(<TooltipContent id={id} deviceInfo={deviceInfo} marker={marker} appState={appState} nicknames={nicknames} />, overlay.getElement());
                    // ReactDOM.createRoot(overlay.getElement()).render(tooltipContent);
                    markerFeature.set('overlay', overlay);

                    markerFeature.setId(id);
                    return markerFeature;
                });

            const vectorSource = vectorLayerRef.current.getSource();
            vectorSource.clear();
            vectorSource.addFeatures(markers);
        }
    }, [appState, markerData, nicknames]);

    useEffect(() => {
        let cancelRequest = () => { }

        getContactsListStatus((cr) => { cancelRequest = cr })
            .then((response) => {

                console.log("from contacts: ", response)
                if (response !== undefined) {
                    if (response.data.data.response) {
                        if (response.data.data.response.message) {
                            console.log(response.data.data.response.message)
                        }
                        else if (Array.isArray(response.data.data.response)) {
                            if (response.data.data.response.length > 0) {
                                setContacts(response.data.data.response)
                            }
                        }
                        else if (response.data.data.response.nickname && response.data.data.response.did) {
                            setContacts([{
                                nickname: response.data.data.response.nickname,
                                did: response.data.data.response.did,
                                dp: response.data.data.response.dp,
                                onlineStatus: response.data.data.response.onlineStatus
                            }])
                        }
                        else {
                            if (response.data.data.response.message) {
                                console.log(response.data.data.response.message)
                            }
                        }

                    }
                    else {
                        console.log(response.data.data)
                    }
                }
                else {
                    console.log("Response is Undefined.")
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
            })

        return cancelRequest
    }, [])

    useEffect(() => {
        console.log("contacts: ", contacts)
        const tempNicknames = {}
        contacts
            .filter(x => x?.did && x?.onlineStatus && x)
            .map(x => {
                try {
                    let dpObj = JSON.parse(x?.dp)
                    tempNicknames[x.did] = { nickname: x?.nickname, dp: dpObj }
                } catch (e) {
                    tempNicknames[x.did] = { nickname: x?.nickname, dp: { format: "png", base64: "" } }
                }
                return { nickname: x?.nickname, dp: x?.dp }
            })
        setNicknames(tempNicknames)
        // console.log("tempNicknames:", tempNicknames)
    }, [contacts])

    return (
        <Box sx={{ position: "relative" }}>
            <CardMedia component={"div"} id={id} style={{ width: '100%', height: maxHeight ? maxHeight : `${window.innerHeight}px` }} />
            <Dialog open={showMap} onClose={() => setShowMap(false)} fullScreen>
                <Box sc={{ position: "relative", justifyContent: "center", alignItems: "center" }}>
                    <MapDistance id={`DirectionOf${id}`} data={data} setOpen={setShowMap} />
                </Box>
            </Dialog>
            <Box sx={{ position: "absolute", left: 32, top: 0, p: 1 }}>
                {markerData.length === 2 && <Chip color='primary' icon={<DirectionsIcon color='inherit' />} onClick={() => setShowMap(true)} label={"Directions"} />}
            </Box>
        </Box>
    );
};

const TooltipContent = ({ id, deviceInfo, marker, appState, nicknames }) => {

    return (
        <AppContextProvider >
            <CustomThemeProvider>
                <Card id={id} variant='outlined' sx={{ borderRadius: 3, maxWidth: 500, wordBreak: "break-all" }}>
                    <ListItem variant="outlined" key={id}>
                        <ListItemAvatar>
                            <Avatar src={nicknames?.[id]?.dp && `data:image/${nicknames?.[id]?.dp?.format};base64,${nicknames?.[id]?.dp?.base64}`} variant="rounded" sx={{ background: "transparent" }}>
                                <DevicesIcon color={nicknames?.[id] ? "success" : "secondary"} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={appState?.did === id ? "Me" : nicknames?.[id]?.nickname ? nicknames?.[id]?.nickname : "Anonymous"} secondary={id} />
                    </ListItem>
                    <Divider />
                    {deviceInfo &&
                        <Accordion sx={{ boxShadow: 0 }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                            >
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    {deviceInfo?.operatingSystem && deviceInfo?.operatingSystem + "".toLowerCase() === 'windows' && <BsWindows size={"1rem"} color="#0078D6" />}
                                    {deviceInfo?.operatingSystem && deviceInfo?.operatingSystem + "".toLowerCase() === 'android' && <ImAndroid size={"1rem"} color='#3DDC84' />}
                                    {deviceInfo?.operatingSystem && deviceInfo?.operatingSystem + "".toLowerCase() === 'linux' && <FcLinux size={"1rem"} color='#3DDC84' />}
                                    {deviceInfo?.operatingSystem && ["macos", "ios", "ipados", "visionos"].includes(deviceInfo?.operatingSystem + "".toLowerCase()) && <BsApple size={"1rem"} color="#6C6C6C" />}
                                    <Typography variant='subtitle2'>Device Info {deviceInfo?.operatingSystem && `• ${deviceInfo?.operatingSystem}`}</Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack>
                                    {Object.keys(deviceInfo).map(key => (
                                        <Stack key={key} direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='caption' color={"text.secondary"}>{key}</Typography>
                                            <Typography variant='subtitle2'>{"" + deviceInfo?.[key]}</Typography>
                                        </Stack>
                                    ))}
                                    <Stack alignItems={"center"} sx={{ py: 1 }}>
                                        {marker?.lat && marker?.lng && <ShowAddress location={{ latitude: marker?.lat, longitude: marker?.lng }} color="secondary" />}
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>}
                    {deviceInfo && Object.keys(deviceInfo).length > 0 && <Divider />}
                    <Stack sx={{ p: 1 }}>
                        <Typography align='center' variant="subtitle2" fontWeight={"light"} color={"text.secondary"}>Latitude: {marker?.lat}, Longitude: {marker?.lng}</Typography>
                        {marker?.timestamp && <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Updated at: {marker?.timestamp && format(new Date(marker?.timestamp), "Ppp")} {`${marker?.timestamp && "(" + formatDistanceToNow(new Date(marker?.timestamp)) + " ago)"}`}</Typography>}
                    </Stack>
                </Card>
            </CustomThemeProvider>
        </AppContextProvider>
    )
}

export default DistanceMap;
