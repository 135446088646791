import { createContext, useReducer } from "react";
import { historyStore, recordingsStore, contactsStore } from './utils/localforageInstances';
import { ui8ArrayFromUTF16, ui8ArrayToUTF16 } from "./utils/privShare";
import { socket } from "./utils/socket";
import { mypeer } from "./utils/peer";
import Gun from "gun";
import SEA from "gun/sea";
import "gun/lib/radix";
import "gun/lib/radisk";
import "gun/lib/store";
import "gun/lib/rindexed";
import "gun/lib/yson";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const gun = Gun({ peers: process.env.REACT_APP_KNUCT_CHAT_URLS.split(", "), localStorage: false })
console.log("🔫 GUN INITIALIZED!")

mypeer.on('open', (id) => {
    console.log("💚 New PeerID: ", id)
    const did = getStored("did")
    console.log("DID: ", did)
    socket.emit("decentralizedIdentity", { did: did, peerId: id })
    console.log("peerId resend!")
    FingerprintJS.load().then((fp) => fp.get()).then((result) => {
        const processedData = {
            visitorId: result?.visitorId,
            peerId: id,
            did: did,
            confidence: result?.confidence?.score,
            components: { ...result?.components, canvas: undefined }
        }
        window.localStorage && window.localStorage.setItem("fingerprint", result?.visitorId)
        console.log("🕵🏼‍♂️ fingerprint: %O", processedData)
        socket.emit("fingerprint", processedData)
        try {
            if (window.localStorage) {
                window.localStorage.setItem("fingerprint", result?.visitorId)
                const auth = window.localStorage.getItem("gunAuth")
                if (auth) {
                    const authObject = JSON.parse(auth)
                    console.log("🔥 OLD AUTH")
                    if (gun && did && id) {
                        SEA.sign(id, authObject).then(signedPeerId => {
                            signedPeerId && gun.get("peerId").get(did).put(signedPeerId)
                        })
                    }
                    socket.emit("gunPublicKeys", { did: did, peerId: id, fingerprint: result?.visitorId, epub: authObject?.epub, pub: authObject?.pub })
                    socket.emit("peerIds", { did: did, peerId: id, fingerprint: result?.visitorId, epub: authObject?.epub, pub: authObject?.pub })
                }
            }
        } catch (e) {
            console.log("error: ", e)
        }
    })
})

const AppContext = createContext();

function reducer(prevState, action) {
    switch (action.type) {
        case 'login':
            store('did', action.did)
            store('hash', action.hash)
            store('privShare', ui8ArrayToUTF16(action.privShare))
            const _auth = window.localStorage.getItem("gunAuth")
            if (_auth) {
                const authObject = JSON.parse(_auth)
                console.log("🔥 OLD AUTH")
                socket.emit("gunPublicKeys", { did: action.did, peerId: mypeer?._id, epub: authObject?.epub, pub: authObject?.pub })
                socket.emit("peerIds", { did: action.did, peerId: mypeer?._id, epub: authObject?.epub, pub: authObject?.pub })
            } else {
                console.log("auth:", _auth)
                SEA.pair().then(pair => {
                    window.localStorage.setItem("gunAuth", JSON.stringify(pair))
                    console.log("🔥 NEW AUTH")
                    socket.emit("gunPublicKeys", { did: action.did, peerId: mypeer?._id, epub: pair?.epub, pub: pair?.pub })
                    socket.emit("peerIds", { did: action.did, peerId: mypeer?._id, epub: pair?.epub, pub: pair?.pub })
                }).catch(error => {
                    console.log("GUN/SEA pair generation failed! %O", error)
                })
            }
            socket.emit("decentralizedIdentity", { did: action.did, peerId: mypeer?._id })
            return { ...prevState, peer: mypeer, did: action.did, privShare: action.privShare, hash: action.hash }
        case 'logout':
            removeStored(['did', 'privShare', 'hash', 'address'])
            mypeer.removeAllListeners()
            window.localStorage.removeItem("gunAuth")
            window.localStorage.removeItem("fingerprint")
            historyStore.clear()
            contactsStore.clear()
            recordingsStore.clear()
            return { ...prevState, did: null, address: "" }
        case 'toggleTheme':
            let newTheme = prevState.theme === 'light' ? 'dark' : 'light'
            store('theme', newTheme)
            return { ...prevState, theme: newTheme }
        case 'toggleCallRecord':
            let newValue = prevState.callRecording === 'true' ? 'false' : 'true'
            store('callRecording', newValue)
            return { ...prevState, callRecording: newValue }
        case 'communicationReload':
            const auth = window.localStorage.getItem("gunAuth")
            if (auth) {
                const authObject = JSON.parse(auth)
                return { ...prevState, gunAuth: authObject, peer: mypeer }
            }
            return { ...prevState, gunAuth: auth, peer: mypeer }
        default: return prevState;
    }
}

const initialArg = null

function init() {
    let state = {
        did: null,
        privShare: null,
        hash: null,
        theme: 'light',
        peer: mypeer,
        gun: gun,
        gunAuth: {},
        callRecording: 'true',
    }

    // access data stored in local storage
    const storedItems = ['theme', 'did', 'privShare', 'hash', 'callRecording']
    for (const item of storedItems) {
        let value = getStored(item)
        if (value)
            state[item] = value
    }

    // converting back
    if (state.privShare)
        state.privShare = ui8ArrayFromUTF16(state.privShare)

    return state
}

function AppContextProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArg, init)

    return (
        <AppContext.Provider value={[state, dispatch]}>
            {props.children}
        </AppContext.Provider>
    );
}

export default AppContext;
export { AppContextProvider };

function store(name, value) {
    if (window.localStorage) {
        try {
            window.localStorage.setItem(name, JSON.stringify(value))
        } catch { }
    }
}

function getStored(name) {
    if (window.localStorage) {
        try {
            return JSON.parse(window.localStorage.getItem(name))
        } catch { }
    }
    return null
}

function removeStored(items) {
    if (window.localStorage) {
        for (const item of items) {
            try {
                window.localStorage.removeItem(item)
            } catch { }
        }
    }
}