import * as React from 'react'
import { Redirect , Route} from "react-router-dom";
import { useLocation } from 'react-router-dom';

import AppContext from '../AppContext';

function checkAuth(appState,dispatchAppState,location) {
    // handling of logout when server returns 403 and the 
    // client indicate it through requireauth url query param
    const qp = new URLSearchParams(location.search);
    if(location.pathname === '/' && qp.has('requireauth')) {
        if(appState.did)
            dispatchAppState({type:'logout'})
        return false
    }
    if(appState.did)
        return true
    else
        return false
}


function AuthRequiredComponent({noAuth=null,auth=null,children=null}) {
    const [appState,dispatchAppState] = React.useContext(AppContext)
    const location = useLocation()

    if(!auth && children) {
        auth = children
    }
    if(checkAuth(appState,dispatchAppState,location))
        return auth;
    else {
        return noAuth;
    }
}

function AuthRequiredRoute({ children, ...rProps }) {

    const [appState,dispatchAppState] = React.useContext(AppContext)
    const location = useLocation()

    return (
        <Route {...rProps}
            render={ ({ loc }) => {
                return checkAuth(appState,dispatchAppState,location) ? (children) : 
                    (<Redirect to={ {pathname: "/", search: "?requireauth", state: { from: loc }} }/>)
            }}
        />
    );
}

export { AuthRequiredComponent , AuthRequiredRoute }

